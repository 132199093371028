import {
  AddonInformation,
  BillingPriceInterval,
  BillingProduct,
} from '@tribeplatform/gql-client/types'

export const getAddonPrice = (option: {
  addon?: BillingProduct
  addonInformation?: AddonInformation
  currentInterval?: BillingPriceInterval
}) => {
  const { addon, addonInformation, currentInterval } = option

  return (
    addon?.prices.find(price => price.id === addonInformation?.priceId) ??
    addon?.prices.find(
      price => price.default && price.interval === currentInterval,
    )
  )
}
