import {
  enableActivityTracking,
  flushBuffer,
  newTracker,
  trackPageView,
  trackSelfDescribingEvent,
} from '@snowplow/browser-tracker'

import { RuntimeConfigs } from '../../../configs/runtime.js'
import { StaticConfigs } from '../../../configs/static.js'

/**
 * Each event is occurred in specific context or about specific item.
 * by default all events are occurred in specific organization and network and they should be set.
 * other events can be triggered for specific space or post or topic
 */
export type TargetAnalyticsContext = {
  organizationId: string
  networkId?: string
  groupId?: string
  templateId?: string
  spaceId?: string
  postId?: string
  topicId?: string
  reactionId?: string
  memberId?: string
  appId?: string
  spaceType?: string
  postTypeId?: string
}

/**
 * An Event has an actor which is a user who triggered that.
 * for example a page view event is triggered by a user
 * we need to have the some information about the user.
 * Guest users also have id and role
 * */
export type ActorAnalyticsContext = {
  id: string
  locale?: string
  roleId?: string
  roleType?: string
  spaceRoleId?: string
  spaceRoleType?: string
}

type serviceDataContext = {
  version: string
  build: string
  channel?: string
}

export class SnowplowTracker {
  name: string

  actor: ActorAnalyticsContext

  target: TargetAnalyticsContext

  service: serviceDataContext

  active: boolean

  enablePageView: boolean

  enablePagePing: boolean

  /* You can initialize the snowplowTracker userInfo as actor and target info by Partial of TargetAnalyticsContext
   * */
  constructor(
    trackerName: string,
    actor?: Partial<ActorAnalyticsContext>,
    target?: Partial<TargetAnalyticsContext>,
  ) {
    const snowplowCollectorAddress = RuntimeConfigs.SNOWPLOW_COLLECTOR_ADDRESS
    const isClient = typeof window !== 'undefined'
    const isEnterprise = isClient && window.__BM_DATA__.isEnterprise
    this.active = !!snowplowCollectorAddress && isClient && !isEnterprise

    if (!this.active) {
      if (isClient) {
        logger.warn('Undefined Snowplow variable: Collector Address')
      }
      return
    }
    /**
     * Initialize snowplow tracker based on snowplow documentation
     * https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/quick-start-guide/
     */

    this.name = trackerName
    this.enablePagePing = RuntimeConfigs.SNOWPLOW_ENABLE_PAGE_PING
    this.enablePageView = RuntimeConfigs.SNOWPLOW_ENABLE_PAGE_VIEW
    this.setService(StaticConfigs.APP_VERSION, RuntimeConfigs.RELEASE_CHANNEL)
    if (actor) {
      this.setActor(actor)
    }
    if (target) {
      this.setTarget(target)
    }

    newTracker(this.name, snowplowCollectorAddress, {
      appId: 'tribe-neo',
      platform: 'web',
      cookieDomain: '',
      discoverRootDomain: true,
      cookieName: 'tribe_sp',
      cookieSameSite: 'None',
      cookieSecure: true,
      encodeBase64: false,
      respectDoNotTrack: false,
      eventMethod: 'post',
      bufferSize: 1,
      maxPostBytes: 40000,
    })

    if (this.enablePagePing) {
      enableActivityTracking({
        minimumVisitLength: 10,
        heartbeatDelay: 10,
      })
    }
  }

  /**
   * Set the Actor of the events
   * @param {ActorAnalyticsContext} actor
   * @returns void
   */
  setActor(actor?: Partial<ActorAnalyticsContext>) {
    this.actor = { ...this.actor, ...actor }
  }

  /**
   * Set the Target of the events
   * @param {TargetAnalyticsContext} target
   * @returns void
   */
  setTarget(target: Partial<TargetAnalyticsContext>) {
    this.target = { ...this.target, ...target }
  }

  /**
   * Set the App version of the events
   * @param {string} versionString
   * @param {string} channel
   * @returns void
   */
  setService(versionString: string, channel: string) {
    const [versionNumber, ...buildIds] = versionString.split('-')

    this.service = {
      version: versionNumber,
      build: buildIds.join('-'),
      channel,
    }
  }

  flush() {
    flushBuffer({}, [this.name])
  }

  track(name: string, eventBody: Record<string, unknown> = {}): void {
    if (!this.active) {
      return
    }
    trackSelfDescribingEvent(
      {
        event: {
          schema: 'iglu:so.tribe/self_described/jsonschema/1-0-0',
          data: {
            name,
            ...eventBody,
          },
        },
        context: [
          {
            schema: 'iglu:so.tribe/service/jsonschema/1-0-0',
            data: this.service,
          },
          {
            schema: 'iglu:so.tribe/actor/jsonschema/1-0-0',
            data: this.actor,
          },
          {
            schema: 'iglu:so.tribe/target/jsonschema/1-0-0',
            data: this.target,
          },
        ],
      },
      [this.name],
    )
  }

  /**
   * Set the Target of the Event
   * @param {string} pageTitle : each page should have a title, if it is a space page or post page or admin dashboard
   *                 there is no need to set the specific name and event the uri parameter of "space" or "post" is enough
   *                            the page view event target is changed so it can be updated by this parameter
   * @returns void
   */
  trackPageView(pageTitle: string): void {
    if (!this.active || !this.enablePageView) {
      return
    }

    // Todo: this condition should be deleted later as the organizationId set for all networks
    if (typeof this.target.organizationId === 'undefined') {
      this.setTarget({ organizationId: 'NA' })
    }

    trackPageView(
      {
        title: pageTitle,
        context: [
          {
            schema: 'iglu:so.tribe/service/jsonschema/1-0-0',
            data: this.service,
          },
          {
            schema: 'iglu:so.tribe/actor/jsonschema/1-0-0',
            data: this.actor,
          },
          {
            schema: 'iglu:so.tribe/target/jsonschema/1-0-0',
            data: this.target,
          },
        ],
      },
      [this.name],
    )
  }
}

const trackers: Record<string, SnowplowTracker> = {}

export const SnowplowTrackerFactory = (
  name: string,
  {
    actor,
    target,
  }: {
    actor?: ActorAnalyticsContext
    target?: TargetAnalyticsContext
  },
): SnowplowTracker => {
  let tracker: SnowplowTracker

  if (name in trackers) {
    tracker = trackers[name]
    tracker.setActor(actor)
    tracker.setTarget(target)
  } else {
    tracker = new SnowplowTracker(name, actor, target)
    trackers[name] = tracker
  }
  return tracker
}
