import { fileSize } from '@bettermode/common/filesize'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { useI18n } from '../i18n/providers/I18nProvider.js'
import type { FileUploadProps } from './FileUpload.js'
import { FileUpload } from './FileUpload.js'

export type ImageUploadProps = Omit<FileUploadProps, 'accept'>

export const ImageUpload = ({
  label: _label,
  helper: _helper,
  ...restOfProps
}: ImageUploadProps) => {
  const { $t } = useI18n()
  const { data: network } = useNetwork()
  const accept = network?.settings?.imageTypesLimit?.join(',') ?? 'image/*'
  const label =
    _label ??
    $t({
      id: 'Generics.SelectAnImage',
      defaultMessage: 'Select an image',
    })
  const helper =
    _helper ??
    $t(
      {
        id: 'Generics.ImageSizeLimit',
        defaultMessage: 'The image size limit is {size_limit}.',
      },
      {
        size_limit: fileSize(network?.settings?.imageSizeLimit || 10_000),
      },
    )
  return (
    <FileUpload
      label={label}
      helper={helper}
      accept={accept}
      {...restOfProps}
    />
  )
}
