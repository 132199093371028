import { useCallback, useMemo } from 'react'

import { BillingPriceInterval } from '@tribeplatform/gql-client/types'
import { useSubscriptionInformation } from '@tribeplatform/react-sdk/hooks'

export const useCurrentProductInformation = () => {
  const {
    data: subscriptionInformation,
    isLoading: subscriptionInformationLoading,
  } = useSubscriptionInformation()

  const currentInterval = useMemo(
    () =>
      subscriptionInformation?.planPriceInterval ?? BillingPriceInterval.Month,
    [subscriptionInformation?.planPriceInterval],
  )

  const addons = subscriptionInformation?.addons
  const getAddonInformation = useCallback(
    (addonId: string) =>
      addons?.find(purchasedAddon => purchasedAddon.productId === addonId),
    [addons],
  )

  return {
    currentProduct: subscriptionInformation?.currentProduct,
    currentProductOrder: subscriptionInformation?.currentProduct?.order,
    currentInterval,
    addons,
    hasPlan: !!subscriptionInformation?.planPriceId,
    isLoading: subscriptionInformationLoading,
    isManual: subscriptionInformation?.isManual,
    planName: subscriptionInformation?.planName,
    getAddonInformation,
  }
}
