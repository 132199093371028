import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'
import { useClipboard } from 'use-clipboard-copy'

import { Space } from '@tribeplatform/gql-client/types'
import { useDeleteSpace } from '@tribeplatform/react-sdk/hooks'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'
import { SvgIcon, Icon } from '@tribeplatform/react-ui-kit/Icon'
import { SectionHeader } from '@tribeplatform/react-ui-kit/SectionHeader'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { Form } from '../../Form/index.js'
import { T } from '../../i18n/components/T.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { SpaceSettingsProps } from './types.js'

const DeleteButton = ({
  isLoading,
  space,
}: {
  isLoading: boolean
  space: Space
}) => {
  const { watch } = useFormContext()
  const name = watch('name')

  return (
    <Button
      type="submit"
      loading={isLoading}
      disabled={name !== space?.name}
      variant="primary"
      destructive
    >
      <T defaultMessage="Delete" id="Generics.Delete" />
    </Button>
  )
}

export const SpaceSettingsDelete = ({
  space,
  onClose,
  setDirty,
  isActive,
}: SpaceSettingsProps) => {
  const { $t } = useI18n()
  const { mutateAsync: deleteSpace, isLoading } = useDeleteSpace()
  const clipboard = useClipboard()

  const copySpaceName = () => {
    clipboard.copy(space?.name)
    toast({
      title: $t({ defaultMessage: 'Copied!', id: 'Generics.CopiedBang' }),
      status: 'neutral',
    })
  }

  useEffect(() => {
    if (isActive) {
      setDirty(false)
    }
  }, [isActive, setDirty])

  return (
    <Form<{ name: string }>
      onSubmit={async (data, methods) => {
        const { name } = data
        const { setError } = methods
        if (name !== space?.name) {
          setError('name', {
            message: $t({
              defaultMessage: 'The {SPACE} name is incorrect.',
              id: 'Admin.Space.Delete.IncorrectName',
            }),
            type: 'validate',
          })
          return
        }
        try {
          await deleteSpace({
            id: space?.id,
          })
          toast({
            title: $t({
              defaultMessage: '{SPACE_CC} deleted',
              id: 'Admin.Space.Deleted',
            }),
            status: 'success',
          })
          onClose()
        } catch (e) {
          toast({
            title: $t({
              defaultMessage: 'Unable to delete {SPACE}',
              id: 'Admin.Space.FailedToDelete',
            }),
            status: 'error',
          })
        }
      }}
    >
      <div className="sm:h-132 flex flex-col">
        <div className="flex flex-col flex-grow overflow-auto p-5">
          <SectionHeader
            title={$t({
              defaultMessage: 'Danger zone',
              id: 'Generics.DangerZone',
            })}
            className="pb-5"
          />
          <div className="space-y-5">
            <div className="space-y-2">
              <FormControl.Label>
                <T
                  defaultMessage="Delete {space_name}"
                  id="Admin.Space.Delete"
                  values={{
                    space_name: space?.name,
                  }}
                />
              </FormControl.Label>
              <FormControl.HelperText
                status={space.isHomepage ? 'error' : 'neutral'}
                highlighted={space.isHomepage}
              >
                {space.isHomepage ? (
                  <>
                    <div className="flex items-center space-x-2">
                      <Icon className="w-5 h-5">
                        <SvgIcon name="status-warning" />
                      </Icon>
                      <div>
                        <T
                          defaultMessage="This space is the homepage and cannot be deleted."
                          id="SpaceSettings.HomeDeleteWarning"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <T
                    defaultMessage="You will lose posts, comments and all content related to the {SPACE}."
                    id="Admin.Space.Delete.HelperText"
                  />
                )}
              </FormControl.HelperText>
            </div>
            {!space.isHomepage && (
              <Form.Input
                name="name"
                label={
                  <T
                    defaultMessage="Enter the name of the {SPACE} to confirm"
                    id="Admin.Space.Delete.Confirm"
                  />
                }
                secondaryLabel={
                  <Badge
                    trailingIcon={<SvgIcon name="copy" />}
                    className="cursor-pointer"
                    onClick={() => copySpaceName()}
                  >
                    {space?.name}
                  </Badge>
                }
              />
            )}
          </div>
        </div>
        <div className="flex justify-end px-5 py-5 border-t space-s-2">
          <Button
            type="button"
            variant="secondaryNeutral"
            onClick={() => onClose()}
          >
            <T defaultMessage="Cancel" id="Generics.Cancel" />
          </Button>
          <DeleteButton isLoading={isLoading} space={space} />
        </div>
      </div>
    </Form>
  )
}
