import { clsx } from 'clsx'

import { fileSize } from '@bettermode/common/filesize'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link as UILink } from '@tribeplatform/react-ui-kit/Link'

import { T, useI18n } from '../../../i18n/index.js'
import { PopoverImagePicker } from '../../../Picker/PopoverImagePicker.js'

interface Props {
  thumbnailUrl: string
  onUpload: (files: Array<File>) => void
  isLoading: boolean
}

export const ImagePicker = ({ thumbnailUrl, onUpload, isLoading }: Props) => {
  const intl = useI18n()

  const { data: network } = useNetwork()
  return (
    <div
      className="text-center mx-auto relative max-w-full border flex h-48 bg-cover rounded-base"
      style={{ backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : '' }}
    >
      <div className="absolute top-0 bottom-0 start-0 end-0 cursor-pointer flex justify-center items-center">
        <PopoverImagePicker
          tabs={['upload', 'link', 'unsplash']}
          sizeConfig={{
            recommendedWidth: 1200,
            recommendedHeight: 630,
          }}
          onRemove={() => {
            onUpload(undefined)
          }}
          onImageChange={onUpload}
        >
          <div
            className={clsx(
              thumbnailUrl && 'bg-black opacity-70 rounded-base px-2 py-1',
            )}
          >
            {isLoading ? (
              <SvgIcon className="w-9 h-9 animate-spin" name="spinner" />
            ) : (
              <>
                <div className="mb-3 text-content-subdued flex justify-center">
                  <SvgIcon className="w-9 h-9" name="image-add" />
                </div>
                <UILink>
                  <T
                    defaultMessage="Select an image"
                    id="Generics.SelectAnImage"
                  />
                </UILink>
                <div className="text-content-subdued">
                  <T
                    defaultMessage="{formats} up to {size_limit}"
                    id="Generics.FormatAndSizeLimit"
                    values={{
                      size_limit: fileSize(
                        network?.settings?.imageSizeLimit || 10000,
                      ),
                      formats: intl.formatList(['PNG', 'JPG', 'GIF']),
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </PopoverImagePicker>
      </div>
    </div>
  )
}
