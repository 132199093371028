import { FC, useState } from 'react'

import { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SearchableSelect } from '@tribeplatform/react-ui-kit/SearchableSelect'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/index.js'

export const SpaceTagSelector: FC<{
  spaceId: string
  selectedTag: Tag | undefined
  onSelect: (tag: Tag) => void
}> = ({ spaceId, onSelect, selectedTag }) => {
  const { $t } = useI18n()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 300)
  const { data, isFetching } = useSpaceTags({
    variables: {
      spaceId,
      limit: 10,
      query: debouncedQuery,
    },
    useInfiniteQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { nodes: spaceTags } = simplifyPaginatedResult<Tag>(data)

  return (
    <SearchableSelect
      value={selectedTag}
      options={spaceTags}
      onChange={onSelect}
      onInputChange={setQuery}
    >
      <SearchableSelect.Button
        placeholder={$t({
          defaultMessage: 'Select {TAG}',
          id: 'Space.Tags.Select',
        })}
      >
        {selectedTag?.title}
      </SearchableSelect.Button>
      <SearchableSelect.Items>
        {spaceTags.map((tag, index) => (
          <SearchableSelect.Item key={tag.id} value={tag} index={index}>
            {tag.title}
          </SearchableSelect.Item>
        ))}
        {isFetching && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
          </SearchableSelect.ItemsEmpty>
        )}
        {!isFetching && query && spaceTags.length === 0 && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.NoResults" defaultMessage="No results" />
          </SearchableSelect.ItemsEmpty>
        )}
      </SearchableSelect.Items>
    </SearchableSelect>
  )
}
