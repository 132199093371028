import { Suspense } from 'react'

import {
  MemberAnalyticsModal,
  MemberAnalyticsModalProps,
} from '../../Analytics/MemberAnalytics/index.js'
import { AuditLogsModal, AuditLogsModalProps } from '../../AuditLog/index.js'
import {
  ManageBadgesModal,
  ManageBadgesModalProps,
} from '../../MemberBadge/index.js'
import {
  ProfileSettingsModal,
  ProfileSettingsModalProps,
} from '../../ProfileSettingsModal/index.js'
import { ReportModal, ReportModalProps } from '../../ReportModal/index.js'
import { ShareModal } from '../../ShareModal/index.js'
import { ShareModalProps } from '../../ShareModal/ShareModal.js'
import { useActionItems } from '../ActionItemsContext.js'

export const MemberActionItemsModals = () => {
  const { modalKey, entityProps, closeModal } = useActionItems()

  return (
    <Suspense>
      {modalKey === 'shareModal' && (
        <ShareModal
          {...(entityProps as ShareModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'editProfileModal' && (
        <ProfileSettingsModal
          {...(entityProps as ProfileSettingsModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'auditLogsModal' && (
        <AuditLogsModal
          {...(entityProps as AuditLogsModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'manageBadgesModal' && (
        <ManageBadgesModal
          {...(entityProps as ManageBadgesModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'memberAnalyticsModal' && (
        <MemberAnalyticsModal
          {...(entityProps as MemberAnalyticsModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'reportModal' && (
        <ReportModal
          {...(entityProps as ReportModalProps)}
          open
          onClose={closeModal}
        />
      )}
    </Suspense>
  )
}
