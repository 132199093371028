import { ReactNode } from 'react'

import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState } from '@tribeplatform/react-ui-kit/EmptyState'
import { IconComponentType } from '@tribeplatform/react-ui-kit/Icon'

interface Props extends React.ComponentProps<typeof Card> {
  title: ReactNode
  description: ReactNode
  icon?: IconComponentType
}

export const NotFound = ({
  title,
  description,
  icon,
  children,
  ...cardProps
}: Props) => {
  return (
    <Card {...cardProps}>
      <Card.Content>
        <div className="py-12">
          <EmptyState icon={icon} title={title} description={description}>
            {children}
          </EmptyState>
        </div>
      </Card.Content>
    </Card>
  )
}
