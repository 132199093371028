import { useState } from 'react'

import { usePost } from '@tribeplatform/react-sdk/hooks'
import { Divider } from '@tribeplatform/react-ui-kit/Divider'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InputCopy } from '@tribeplatform/react-ui-kit/Input'
import { Select } from '@tribeplatform/react-ui-kit/Select'
import { Slider } from '@tribeplatform/react-ui-kit/Slider'
import { Toggle } from '@tribeplatform/react-ui-kit/Toggle'

import { ErrorBoundary } from '../common/components/ErrorBoundary/ErrorBoundary.js'
import { useDomain } from '../common/hooks/useDomain.js'
import { useStaticIntl, T } from '../i18n/index.js'
import { I18nShape } from '../i18n/types.js'

const toQueryString = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&')

const getIframe = (iframeSrc, { height, title }) => {
  return `<iframe frameborder="no" src="${iframeSrc}" height="${height}px" width="100%" title="${title}" />`
}

const viewOptionsFactory = ({ $t }: I18nShape) => ({
  all: $t({
    defaultMessage: 'Content and Replies',
    id: 'EmbedViewOptions.ContentAndReplies',
  }),
  'content-only': $t({
    defaultMessage: 'Content Only',
    id: 'EmbedViewOptions.Content',
  }),
  'replies-only': $t({
    defaultMessage: 'Replies Only',
    id: 'EmbedViewOptions.Replies',
  }),
})

export const PostEmbedCreator = ({ postId }) => {
  const viewOptions = useStaticIntl(viewOptionsFactory)
  const { data: post, isFetching: fetchingPost } = usePost({
    variables: { id: postId },
  })
  const { getResourceUrl } = useDomain()

  const loading = fetchingPost
  const baseUrl = getResourceUrl(`/embed/post/${postId}`)
  const [iframeHeight, setIframeHeight] = useState(360)
  const [iframeSrcParams, setIframeSrcParams] = useState({
    view: 'all',
    readonly: true,
    show_full_content: true,
  })

  const iframeSrc = `${baseUrl}?${toQueryString(iframeSrcParams)}`
  const set = (key, value) => {
    setIframeSrcParams({
      ...iframeSrcParams,
      [key]: value,
    })
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-96">
        <SvgIcon className="animate-spin absolute h-9 w-9" name="spinner" />
      </div>
    )
  }

  const iframeToCopy = getIframe(iframeSrc, {
    height: iframeHeight,
    title: post?.title,
  })
  return (
    <>
      <InputCopy value={iframeToCopy} readOnly />
      <Divider />
      <div className="mb-4 space-y-5">
        <div className="flex justify-between items-center">
          <h5 className="text-sm font-medium">
            <T defaultMessage="View" id="ShareModal.PostEmbedCreator.View" />
          </h5>
          <Select
            value={iframeSrcParams.view}
            onChange={newValue => {
              set('view', newValue)
            }}
          >
            <Select.Button>{viewOptions[iframeSrcParams.view]}</Select.Button>
            <Select.Items>
              {Object.entries(viewOptions).map(([key, label]) => (
                <Select.Item key={key} value={key}>
                  {label}
                </Select.Item>
              ))}
            </Select.Items>
          </Select>
        </div>
        {/* <div className="flex justify-between items-center">
          <h5>Show full content</h5>
          <Toggle
            checked={iframeSrcParams.show_full_content}
            onChange={() => {
              set('show_full_content', !iframeSrcParams.show_full_content)
            }}
          />
        </div> */}
        <div className="flex justify-between items-center">
          <h5 className="text-sm font-medium">
            <T
              defaultMessage="Read only"
              id="ShareModal.PostEmbedCreator.ReadOnly"
            />
          </h5>
          <Toggle
            checked={iframeSrcParams.readonly}
            onChange={() => {
              set('readonly', !iframeSrcParams.readonly)
            }}
          />
        </div>
      </div>
      <h5 className="text-sm font-medium">
        <T
          defaultMessage="Embed height: {iframe_height}px"
          id="Share.EmbedHeight"
          values={{
            iframe_height: iframeHeight,
          }}
        />
      </h5>
      <div className="mb-2">
        <Slider
          value={iframeHeight}
          step={10}
          min={200}
          max={1000}
          onChange={setIframeHeight}
        />
      </div>
      <ErrorBoundary>
        <h5 className="text-sm font-medium mb-1">
          <T defaultMessage="Embed preview" id="Share.EmbedPreview" />
        </h5>
        <div className="p-3 bg-skeleton overflow-auto h-96 rounded-base">
          <iframe
            src={iframeSrc}
            height={`${iframeHeight}px`}
            width="100%"
            title={post?.title}
          />
        </div>
      </ErrorBoundary>
    </>
  )
}
