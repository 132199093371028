import { clsx } from 'clsx'
import { useClipboard } from 'use-clipboard-copy'

import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { EditorSlateExtraContext } from '../../types.js'
import { getBlockToCopy, getBlockToPaste, getClipboardText } from '../utils.js'
import { BlockActionsProps } from './BlockActions.types.js'

export const BlockActions = ({
  id,
  removable,
  editable,
  copyDisabled,
  acceptsChildren,
  hide = false,
  className,
  size = 'sm',
  asDropdown = false,
  onRename,
}: BlockActionsProps) => {
  const { $t } = useI18n()
  const {
    removeBlock,
    addBlock,
    compiledSlate: { blocks },
    extraContext: {
      slate: { activateBlock, openAddBlock },
    },
    updateBlockById,
  } = useSlate<EditorSlateExtraContext>()

  const block = blocks[id]

  const { copy } = useClipboard()

  const onRemove = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    const confirmed = await confirm({
      title: $t({
        defaultMessage: 'Are you sure you want to delete this block?',
        id: 'Admin.Editor.Block.Delete.Confirmation',
      }),
      proceedLabel: $t({
        defaultMessage: 'Delete',
        id: 'Generics.Delete',
      }),
      cancelLabel: $t({
        defaultMessage: 'Cancel',
        id: 'Generics.Cancel',
      }),
      danger: true,
    })
    if (!confirmed) {
      return
    }
    removeBlock(id)
  }

  const onActivate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    activateBlock(id)
  }

  const onAddBlock = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    openAddBlock(id)
  }

  const onCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    const blockToCopy = getBlockToCopy(id, blocks)
    if (!blockToCopy) {
      return
    }

    copy(JSON.stringify(blockToCopy))
  }

  const onPaste = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    // The error is handled inside getClipboardText
    const clipboardText = await getClipboardText()
    if (clipboardText === null) {
      toast({
        title: 'Unable to paste block',
        status: 'error',
        description:
          'Please make sure your browser supports clipboard and you have allowed proper access.',
      })
      return
    }

    const newBlock = getBlockToPaste(clipboardText, blocks)
    if (newBlock === null) {
      toast({
        title: 'Unable to paste block',
        status: 'error',
        description: 'Please copy a block before using the paste block action.',
      })
      return
    }

    addBlock(id, 0, {
      blocks: newBlock.blocks,
      rootBlock: newBlock.rootId,
    })
  }

  const toggleHide = (isHidden: boolean) => {
    updateBlockById(id, {
      extraProps: { ...block.extraProps, hide: isHidden },
    })
  }

  const isHidden = block.extraProps.hide
  const showHiddenToggler = !!block.parentId // to avoid showing the hidden toggler for the root block

  return (
    <div
      className={clsx(
        'flex flex-row items-center transition-all',
        size === 'sm' && 'gap-0.5',
        size === 'md' && 'gap-1',
        size === 'lg' && 'gap-2',
        hide || (!removable && !editable && !acceptsChildren)
          ? 'opacity-0'
          : 'opacity-100',
        className,
      )}
    >
      {acceptsChildren && (
        <IconButton
          size="sm"
          variant="tertiaryNeutral"
          rounded
          onClick={onAddBlock}
          icon={<SvgIcon name="plus" />}
        />
      )}
      {!acceptsChildren && asDropdown && editable && (
        <IconButton
          size="sm"
          variant="tertiaryNeutral"
          rounded
          onClick={onActivate}
          icon={<SvgIcon name="settings" />}
        />
      )}
      {showHiddenToggler && (
        <IconButton
          size={size}
          variant="tertiaryNeutral"
          rounded
          icon={<SvgIcon name={isHidden ? 'eye' : 'eye-off'} />}
          onClick={() => toggleHide(!isHidden)}
        />
      )}
      {asDropdown ? (
        <Dropdown placement="bottom-end">
          <Dropdown.ButtonMinimal as="div">
            <IconButton
              size="sm"
              variant="tertiaryNeutral"
              rounded
              icon={<SvgIcon name="dots-horizontal" />}
            />
          </Dropdown.ButtonMinimal>
          <Dropdown.Items>
            {acceptsChildren && (
              <Dropdown.Item
                onClick={onAddBlock}
                leadingIcon={<SvgIcon name="plus" />}
              >
                <T defaultMessage="Add block" id="BlockActions.AddBlock" />
              </Dropdown.Item>
            )}
            {editable && (
              <Dropdown.Item
                onClick={onActivate}
                leadingIcon={<SvgIcon name="settings" />}
              >
                <T defaultMessage="Settings" id="BlockActions.Settings" />
              </Dropdown.Item>
            )}
            {editable && typeof onRename === 'function' && (
              <Dropdown.Item
                onClick={onRename}
                leadingIcon={<SvgIcon name="edit" />}
              >
                <T defaultMessage="Rename" id="BlockActions.Rename" />
              </Dropdown.Item>
            )}
            {!copyDisabled && (
              <Dropdown.Item
                onClick={onCopy}
                leadingIcon={<SvgIcon name="copy" />}
              >
                <T defaultMessage="Copy block" id="BlockActions.CopyBlock" />
              </Dropdown.Item>
            )}
            {acceptsChildren && (
              <Dropdown.Item
                onClick={onPaste}
                leadingIcon={<SvgIcon name="clipboard" />}
              >
                <T defaultMessage="Paste block" id="BlockActions.PasteBlock" />
              </Dropdown.Item>
            )}
            {removable && (
              <Dropdown.Item
                onClick={onRemove}
                leadingIcon={<SvgIcon name="trash" />}
              >
                <T defaultMessage="Remove" id="Generics.Remove" />
              </Dropdown.Item>
            )}

            {showHiddenToggler && (
              <Dropdown.Item
                onClick={() => toggleHide(!isHidden)}
                leadingIcon={<SvgIcon name={isHidden ? 'eye' : 'eye-off'} />}
              >
                <T
                  defaultMessage="{isHidden, select, true {Show} other {Hide}}"
                  id="BlockActions.ToggleHidden"
                  values={{
                    isHidden,
                    Show: $t({
                      id: 'Generics.Show.Verb',
                      defaultMessage: 'Show',
                    }),
                    Hide: $t({
                      id: 'Generics.Hide.Verb',
                      defaultMessage: 'Hide',
                    }),
                  }}
                />
              </Dropdown.Item>
            )}
          </Dropdown.Items>
        </Dropdown>
      ) : (
        <>
          {removable && (
            <IconButton
              icon={<SvgIcon name="trash" />}
              rounded
              variant="tertiaryNeutral"
              size="md"
              onClick={onRemove}
            />
          )}
          {editable && (
            <IconButton
              icon={<SvgIcon name="settings" />}
              rounded
              variant="tertiaryNeutral"
              size="md"
              onClick={onActivate}
            />
          )}
          {acceptsChildren && (
            <IconButton
              icon={<SvgIcon name="plus" />}
              rounded
              variant="tertiaryNeutral"
              size="md"
              onClick={onAddBlock}
            />
          )}
        </>
      )}
    </div>
  )
}
