import { lazy, Suspense } from 'react'

import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { FallbackLoading } from '../common/components/FallbackLoading.js'
import { PlanBadge } from '../common/components/PlanBadge.js'
import { T } from '../i18n/components/T.js'

const AuditLogList = lazy(() =>
  import('./AuditLogList.js').then(m => ({ default: m.AuditLogList })),
)

export type AuditLogsModalProps = {
  open: boolean
  onClose: () => void
  spaceId?: string
  actorId?: string
  postId?: string
  memberId?: string
  noun?: string
  from?: number
}

export const AuditLogsModal = ({
  open,
  onClose,
  spaceId,
  actorId,
  postId,
  memberId,
  noun,
  from,
}: AuditLogsModalProps) => {
  const { data: network } = useNetwork({
    fields: {
      limits: 'all',
    },
  })
  const authorized = !!network?.limits?.usageLimits.auditLogAccess

  return (
    <Modal open={open} onClose={onClose} size="2xl">
      <Modal.Header
        title={
          <div className="flex items-center space-s-2">
            <div>
              <T id="Generics.AuditLogs" defaultMessage="Audit logs" />
            </div>
            <PlanBadge authorized={authorized} />
          </div>
        }
      />
      <Modal.Content>
        <Suspense fallback={<FallbackLoading />}>
          <AuditLogList
            authorized={authorized}
            spaceId={spaceId}
            actorId={actorId}
            postId={postId}
            memberId={memberId}
            noun={noun}
            from={from}
          />
        </Suspense>
      </Modal.Content>
    </Modal>
  )
}
