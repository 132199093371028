import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationModifySubscriptionAddonArgs,
  SubscriptionInformation,
} from '@tribeplatform/gql-client/types'

import { useQueryClient } from '../../index.js'
import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSubscriptionInformationKey } from '../../utils/keys/billing.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'

export const useModifySubscriptionAddon = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationModifySubscriptionAddonArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const queryClient = useQueryClient()
  const { client } = useTribeClient()

  return useMutation<Action, ClientError, MutationModifySubscriptionAddonArgs>(
    input =>
      client.mutation({
        name: 'modifySubscriptionAddon',
        args: {
          fields: 'basic',
          variables: input,
        },
      }),
    {
      onMutate: input => {
        queryClient.setQueriesData<SubscriptionInformation>(
          getSubscriptionInformationKey(),
          old => ({
            ...old,
            addons: old?.addons?.map(addon =>
              addon.priceId === input.priceId
                ? { ...addon, quantity: input.quantity }
                : addon,
            ),
          }),
        )
      },
      onSettled: () => {
        // setTimeout is used because it takes some time for network limits to be updated
        // from the back-end after the addon is modified
        setTimeout(() => {
          queryClient.invalidateQueries(getSubscriptionInformationKey())
          queryClient.invalidateQueries(getNetworkKey())
        }, 1000)
      },
      ...useMutationOptions,
    },
  )
}
