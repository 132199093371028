import { IsTypingEvent, StoppedTypingEvent } from '../Socket/constants.js'

export const RevalidateIsTypingStates = Symbol('revalidate')

export type IsTypingReducerAction =
  | {
      type: typeof IsTypingEvent
      payload: {
        chatId: string
        memberId: string
        memberName: string
      }
    }
  | {
      type: typeof StoppedTypingEvent
      payload: {
        chatId: string
        memberId: string
      }
    }
  | {
      type: typeof RevalidateIsTypingStates
    }

export type TIsTypingEntry = {
  memberId: string
  timestamp: number
  memberName: string
}
export type TIsTypingStateEntry = Array<TIsTypingEntry>
export type TIsTypingState = Record<string, TIsTypingStateEntry>
