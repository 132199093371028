import { useCallback } from 'react'

import { InfiniteData } from '@tanstack/react-query'

import {
  ChatList,
  type QueryChatsArgs,
  QueryDirection,
  BiDirectionalPageInfo,
  Chat,
  QueryChatsByCursorArgs,
} from '@tribeplatform/gql-client/types'

import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getChatKey, getChatsKey } from '../../utils/keys/messaging.key.js'

type QueryVariables = Pick<QueryChatsArgs, 'limit' | 'archived'>
type RefreshChatsParams = {
  variables: QueryVariables
  skipUnreadCounts?: boolean
}
type FetchChatsVariables = Required<
  Pick<QueryChatsArgs, 'messageId' | 'limit' | 'timestamp'>
>
type FetchChatsParams = {
  variables: FetchChatsVariables
  cursor?: string
  skipUnreadCounts?: boolean
}

export const useRefreshChats = () => {
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  const fetchChats = useCallback(
    async ({ variables, cursor }: FetchChatsParams) => {
      if (cursor) {
        return client.messaging.chatsByCursor({
          cursor,
          limit: variables.limit,
        })
      }
      return client.messaging.chats({
        ...variables,
        direction: QueryDirection.After,
      })
    },
    [client.messaging],
  )

  const reconcileCache = useCallback(
    (recentChatLists: ChatList[], variables: QueryVariables) => {
      const chatsQueryKey = getChatsKey({
        variables,
      })
      const newToOldChatLists = recentChatLists
        .reverse()
        .map<ChatList>(({ data, pageInfo }, index) => {
          const isFirst = index === 0
          const reversedPageInfo: BiDirectionalPageInfo = {
            endCursor: pageInfo.startCursor,
            hasNextPage: pageInfo.hasPreviousPage,
            startCursor: isFirst ? null : pageInfo.endCursor,
            hasPreviousPage: isFirst ? false : pageInfo.hasNextPage,
          }
          return {
            data,
            pageInfo: reversedPageInfo,
          }
        })

      queryClient.setQueryData<InfiniteData<ChatList>>(chatsQueryKey, data => {
        const deDupedCache = (data?.pages ?? [])
          .map(({ data: cachedData, pageInfo }) => {
            const data = cachedData.filter(
              cachedChat =>
                !newToOldChatLists.some(chatList =>
                  chatList.data.some(newChat => newChat._id === cachedChat._id),
                ),
            )

            return {
              data,
              pageInfo,
            }
          })
          .filter(cachedPage => {
            return cachedPage.data.length > 0
          })

        const normalizedChatList = [...newToOldChatLists, ...deDupedCache]

        const reconcilePageParams: Array<QueryChatsByCursorArgs> = [
          // The cursor for the first page is always null.
          null,
          ...normalizedChatList.map(chatList => ({
            cursor: chatList.pageInfo.endCursor,
            limit: variables.limit,
          })),
        ]
        return {
          pages: normalizedChatList,
          pageParams: reconcilePageParams,
        }
      })

      newToOldChatLists.forEach(chatList => {
        chatList.data.forEach(chat => {
          const chatKey = getChatKey({ variables: { id: chat._id } })
          if (!chat.lastMessage) {
            return
          }

          queryClient.setQueryData<Chat>(chatKey, existingChat => {
            if (!existingChat) {
              return existingChat
            }

            return {
              ...existingChat,
              lastMessage: {
                ...chat.lastMessage,
              },
            }
          })
        })
      })
    },
    [queryClient],
  )
  const refreshChats = useCallback(
    async ({ variables }: RefreshChatsParams) => {
      const chatsQueryKey = getChatsKey({
        variables,
      })

      const cachedChats =
        queryClient.getQueryData<InfiniteData<ChatList>>(chatsQueryKey)
          ?.pages ?? []

      const mostRecentMessageId = cachedChats.at(0)?.data.at(0)
        ?.lastMessage?.messageId
      // When there are no messages yet in the chat list, there is no messsageId to
      // use to fetch the most recent chat list items. So We use the current timestamp
      // as a fallback. Timestamp is ignored if messageId is provided (refere to Docs)
      const fallbackTimestamp = Math.floor(Date.now() / 1000)

      const maxAttempts = 10
      let attempt = 0
      let lastEndCursor = null
      const chatLists: ChatList[] = []

      while (attempt < maxAttempts) {
        attempt += 1
        /**
         * Each iteration relies on pagination info of the previous page.
         * As such, request parallelization (e.g Promise.all(...)) in not possible and
         * the await becomes necessary in the while-loop.
         */
        // eslint-disable-next-line no-await-in-loop
        const chatList = await fetchChats({
          variables: {
            ...variables,
            messageId: mostRecentMessageId,
            timestamp: fallbackTimestamp,
          },
          cursor: lastEndCursor,
        })
        const { pageInfo } = chatList

        if (!pageInfo.hasNextPage) {
          break
        }
        chatLists.push(chatList)

        lastEndCursor = pageInfo.endCursor
      }

      reconcileCache(chatLists, variables)

      return chatLists.flatMap(chatList => chatList.data.map(chat => chat._id))
    },
    [fetchChats, queryClient, reconcileCache],
  )

  const refreshChat = useCallback(
    async (chatId: string) => {
      const chatKey = getChatKey({ variables: { id: chatId } })
      await queryClient.invalidateQueries(chatKey)
    },
    [queryClient],
  )

  return {
    refreshChats,
    refreshChat,
  }
}
