import type { ClientError } from '@tribeplatform/gql-client/lib'
import {
  Action,
  MutationUpdatePublisherSettingsArgs,
  Publisher,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPublisherSettingsKey } from '../../utils/keys/notification.key.js'
import { invalidateSpaceQueries } from '../../utils/space.utils.js'
import { useSpaceUtil } from '../utils/useSpaceUtil.js'

type UpdatePublisherSettingsArgs = MutationUpdatePublisherSettingsArgs & {
  publisherType: 'space'
}

export const useUpdatePublisherSettings = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    UpdatePublisherSettingsArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const spaceUtils = useSpaceUtil()

  return useMutation<Action, ClientError, UpdatePublisherSettingsArgs>(
    variables =>
      client.mutation({
        name: 'updatePublisherSettings',
        args: { fields: 'all', variables },
      }),
    {
      onMutate: async ({ publisherId, input }) => {
        const publisherKey = getPublisherSettingsKey({
          variables: { publisherId },
        })
        await queryClient.cancelQueries(publisherKey)

        const publisherUpdater = (old: Publisher): Publisher => ({
          ...old,
          ...input,
        })
        queryClient.setQueriesData<Publisher>(publisherKey, publisherUpdater)

        return { queryKey: publisherKey }
      },
      onSettled: async (data, error, { publisherType, publisherId }) => {
        const publisherKey = getPublisherSettingsKey({
          variables: { publisherId },
        })
        queryClient.invalidateQueries(publisherKey)

        switch (publisherType) {
          case 'space': {
            invalidateSpaceQueries({
              queryClient,
              spaceId: publisherId,
              spaceUtils,
            })
            return undefined
          }

          default: {
            const _exhaustiveCheck: never = publisherType
            return _exhaustiveCheck
          }
        }
      },
      ...useMutationOptions,
    },
  )
}
