import { useTribeClient } from '@tribeplatform/react-sdk'

import { LegacySearchBar } from './LegacySearchBar.js'
import { NewSearchBar } from './NewSearchBar.js'
import { SearchBarProps } from './SearchBar.types.js'

export const SearchBar = (props: SearchBarProps) => {
  const {
    config: { enableVectorSearch: isVectorSearchEnabled },
  } = useTribeClient()

  if (isVectorSearchEnabled) {
    return <NewSearchBar {...props} />
  }

  return <LegacySearchBar {...props} />
}
