import { lazy, Suspense } from 'react'

import { Space } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { FallbackLoading } from '../../common/components/FallbackLoading.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { MemberSegment, TimeFrame } from '../types/index.js'

const SpaceAnalytics = lazy(() =>
  import('./SpaceAnalytics.js').then(m => ({ default: m.SpaceAnalytics })),
)

export interface SpaceAnalyticsModalProps {
  open: boolean
  onClose: () => void
  space: Space
  timeFrame?: TimeFrame
  segment?: MemberSegment
  linkToContent?: boolean
}

export const SpaceAnalyticsModal = ({
  open,
  onClose,
  space,
  timeFrame,
  segment,
  linkToContent = true,
}: SpaceAnalyticsModalProps) => {
  const { Link } = useRouter()
  const { $t } = useI18n()

  return (
    <Modal open={open} onClose={onClose} size="2xl">
      <div className="bg-background">
        <Modal.Header
          title={$t({
            id: 'Analytics.SpaceAnalyticsModal.HeaderTitle',
            defaultMessage: 'Space analytics',
            description: 'Header title for space analytics modal',
          })}
          description={
            <>
              {linkToContent ? (
                <Link
                  href={space?.relativeUrl}
                  variant="accent"
                  target="_blank"
                >
                  {space?.name ?? ''}
                </Link>
              ) : (
                <>{space?.name ?? ''}</>
              )}
            </>
          }
        />
        <Modal.Content>
          <Suspense fallback={<FallbackLoading />}>
            <SpaceAnalytics
              space={space}
              timeFrame={timeFrame}
              segment={segment}
            />
          </Suspense>
        </Modal.Content>
      </div>
    </Modal>
  )
}
