import type { SpacePostTypeFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationUpdateSpacePostTypesArgs,
  SpacePostType,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'
import { getSpacesKey, getSpaceKey } from '../../utils/keys/space.key.js'
import {
  getSpacePostTypesKey,
  getSpacePostTypeKey,
} from '../../utils/keys/spacePostType.key.js'

export const useUpdateSpacePostTypes = (options?: {
  fields?: SpacePostTypeFields
  useMutationOptions?: UseMutationOptions<
    Array<SpacePostType>,
    ClientError,
    MutationUpdateSpacePostTypesArgs,
    { snapshot: Array<SpacePostType> }
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<
    Array<SpacePostType>,
    ClientError,
    MutationUpdateSpacePostTypesArgs,
    { snapshot: Array<SpacePostType> }
  >(
    (input: MutationUpdateSpacePostTypesArgs) =>
      client.spacePostType.update(input, fields),
    {
      onSettled: (data, error, variables) => {
        const spacePostTypesKey = getSpacePostTypesKey({
          variables: {
            spaceId: variables?.spaceId,
          },
        })
        queryClient.invalidateQueries(spacePostTypesKey)

        const spacePostTypeKey = getSpacePostTypeKey({
          variables: {
            spaceId: variables?.spaceId,
          },
        })
        queryClient.invalidateQueries(spacePostTypeKey)

        queryClient.invalidateQueries(getSpacesKey())

        queryClient.invalidateQueries(getSpaceKey())

        queryClient.invalidateQueries(getNetworkKey())
      },
      ...useMutationOptions,
    },
  )
}
