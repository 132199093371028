import { FC } from 'react'

import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { Tabs } from '@tribeplatform/react-ui-kit/Tabs'

import { T } from '../i18n/components/T.js'
import { PostEmbedCreator } from './PostEmbedCreator.js'
import { ShareLink, ShareLinkType } from './ShareLink.js'
import { ShareRss } from './ShareRss.js'
import { SpaceEmbedCreator } from './SpaceEmbedCreator.js'

export type ShareModalProps = ShareLinkType & {
  open: boolean
  onClose: () => void
  postId?: string
  spaceId?: string
  memberId?: string
}

export const ShareModal: FC<ShareModalProps> = ({
  open,
  onClose,
  isPrivate,
  url,
  title,
  postId,
  spaceId,
  memberId,
}) => {
  const hasRSS = postId || spaceId || memberId
  const hasEmbed = spaceId || postId
  return (
    <Modal
      showZenModeBtn
      open={open}
      onClose={onClose}
      className="inline-flex flex-col sm:m-0"
    >
      <Tabs.Group>
        <Modal.Header>
          <Tabs.List variant="filled">
            <Tabs.Tab>
              <T defaultMessage="Share" id="Generics.Share" />
            </Tabs.Tab>
            {hasEmbed && (
              <Tabs.Tab>
                <T defaultMessage="Embed" id="Generics.Embed" />
              </Tabs.Tab>
            )}
            {hasRSS && (
              <Tabs.Tab>
                <T defaultMessage="RSS" id="Generics.RSS" />
              </Tabs.Tab>
            )}
          </Tabs.List>
        </Modal.Header>

        <Modal.Content
          padding={false}
          className="px-4 sm:px-6 pb-6 pt-1 w-full mx-auto overflow-auto"
        >
          <Tabs.Panels>
            <Tabs.Panel>
              <ShareLink url={url} title={title} isPrivate={isPrivate} />
            </Tabs.Panel>
            {hasEmbed && (
              <Tabs.Panel>
                {postId ? (
                  <PostEmbedCreator postId={postId} />
                ) : (
                  <SpaceEmbedCreator spaceId={spaceId} />
                )}
              </Tabs.Panel>
            )}
            {hasRSS && (
              <Tabs.Panel>
                <ShareRss
                  postId={postId}
                  memberId={memberId}
                  spaceId={spaceId}
                  isPrivate={isPrivate}
                />
              </Tabs.Panel>
            )}
          </Tabs.Panels>
        </Modal.Content>
      </Tabs.Group>
    </Modal>
  )
}
