import { Member, Post, Space } from '@tribeplatform/gql-client/types'

import { SearchBarProps } from '../Search/SearchBar/SearchBar.types.js'
import {
  ExtendedSmartSearchEntity,
  ExtendedSmartSearchEntityType,
  SmartSearchEntityType,
} from '../SmartSearch/SmartSearch.types.js'

export type CommandBarContextType = {
  entity?: Post | Space | Member
  entityType?: 'post' | 'space' | 'member' | 'network'
}

export type ActionType =
  | 'entity'
  | 'posts'
  | 'navigations'
  | 'adminSettings'
  | 'search'

export type ActionOptions = {
  context?: CommandBarContextType[]
  excludeActions?: ActionType[]
}

export const CommandBarMode = {
  COMMANDS: 'commands',
  SEARCH: 'search',
} as const

export type CommandBarModeType =
  (typeof CommandBarMode)[keyof typeof CommandBarMode]

export interface CommandBarContextProps {
  context: CommandBarContextType[]
  open: (options?: OpenMethodOptions) => void
  close: () => void
  isOpen: boolean
  options: ActionOptions
  searchOptions: CommandBarSearchOptions
}

export interface OpenMethodOptions extends ActionOptions {
  searchOptions: CommandBarSearchOptions
}

export interface CommandBarSearchOptions {
  mode: CommandBarModeType
  entityType?: SmartSearchEntityType
  spaceIds?: SearchBarProps['spaceIds']
  placeholder?: SearchBarProps['placeholder']
}

export type ActionItem = {
  url?: string
  action?: () => void
  preventClose?: boolean
  item?: ExtendedSmartSearchEntity
  entityType?: ExtendedSmartSearchEntityType
}
