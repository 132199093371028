import { FC } from 'react'

import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InputCopy } from '@tribeplatform/react-ui-kit/Input'

import { useDomain } from '../common/hooks/useDomain.js'
import { T } from '../i18n/components/T.js'

export type ShareRssType = {
  postId?: string
  memberId?: string
  spaceId?: string
  isPrivate?: boolean
}

export const ShareRss: FC<ShareRssType> = ({
  postId,
  memberId,
  spaceId,
  isPrivate,
}) => {
  const { getResourceUrl } = useDomain()

  let url = ''
  if (postId) {
    url = getResourceUrl(`/rss/post/${postId}/replies`)
  } else if (spaceId) {
    url = getResourceUrl(`/rss/${spaceId}/posts`)
  } else if (memberId) {
    url = getResourceUrl(`/rss/member/${memberId}/posts`)
  } else {
    return null
  }

  return (
    <div className="flex flex-col space-y-5">
      {!isPrivate && <InputCopy value={url} readOnly />}
      <p className="flex space-s-3 items-center">
        <SvgIcon className="text-[#ee802f]" name="rss" size="4x" />
        <span>
          <T
            defaultMessage="Add the feed of this page to your blog or favorite RSS reader such as Feedly or Mailbrew to stay up to date!"
            id="ShareRss.Description"
          />
        </span>
      </p>
      {isPrivate && (
        <div className="bg-surface-subdued rounded-base p-3 flex text-sm">
          <SvgIcon
            className="flex-shrink-0 w-5 h-5 me-2 inline-block"
            name="lock"
          />
          <span>
            <T
              defaultMessage="<strong>This page is private</strong>. RSS feed only works with public pages."
              id="ShareRss.PrivatePageNotice"
            />
          </span>
        </div>
      )}
    </div>
  )
}
