import { FC } from 'react'

import { clsx } from 'clsx'

import { HTMLTribeProps } from '../types/index.js'
import { HOTKEY_MAPPING } from './Hotkey.constants.js'

export type HotkeyProps = HTMLTribeProps<'span'> & {
  variant?: 'default' | 'key'
  size?: 'sm' | 'md' | 'lg'
}

export const Hotkey: FC<HotkeyProps> = props => {
  const { children, className, variant, size = 'md', ...rest } = props

  if (!children) {
    return null
  }

  const keys = children?.toString()?.toLowerCase()?.split(',')?.[0]?.split('+')
  if (!keys || !keys.length) {
    return null
  }

  return (
    <span
      {...rest}
      className={clsx(
        'hotkey inline-flex flex-nowrap text-content-subdued',
        size !== 'sm' && 'space-s-0.5',
        className,
      )}
    >
      {keys.map(key => (
        <kbd
          className={clsx(
            'font-sans justify-center inline-flex',
            size === 'sm' && 'text-xs',
            size === 'md' && 'text-sm',
            size === 'lg' && 'text-lg',
            !HOTKEY_MAPPING[key] && 'uppercase',
            variant === 'key' &&
              'text-xs items-center rounded-base-sm bg-surface-subdued h-5 w-5 border border-line-subdued shadow-sm',
          )}
          key={key}
        >
          {HOTKEY_MAPPING[key] || key}
        </kbd>
      ))}
    </span>
  )
}
