import { useCallback } from 'react'

import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'

export const useInvalidateAllPostsQuery = () => {
  const queryClient = useQueryClient()

  const invalidate = useCallback(() => {
    const postKey = getPostKey()
    queryClient.invalidateQueries(postKey)

    const postsKey = getPostsKey()
    queryClient.invalidateQueries(postsKey)

    const feedKey = getFeedKey()
    queryClient.invalidateQueries(feedKey)
  }, [queryClient])

  return invalidate
}
