import { embedGQLFields, MediaFields, mediaGQLFields } from '../media/index.js'
import {
  MemberFields,
  memberGQLFields,
  PluralNetworkFields,
  pluralNetworkGQLFields,
} from '../network/index.js'

export type GlobalAppFields =
  | 'default'
  | 'basic'
  | 'all'
  | CustomGlobalAppFields

export interface CustomGlobalAppFields {
  banner?: MediaFields
  createdBy?: MemberFields
  customCodes?: 'basic'
  embeds?: 'default'
  favicon?: MediaFields
  image?: MediaFields
  images?: MediaFields
  network?: PluralNetworkFields
  updatedBy?: MemberFields
}

const BASIC_GLOBAL_APP_FIELDS: CustomGlobalAppFields = {}
const DEFAULT_GLOBAL_APP_FIELDS: CustomGlobalAppFields = {
  banner: 'basic',
  createdBy: 'basic',
  customCodes: 'basic',
  embeds: 'default',
  favicon: 'basic',
  image: 'basic',
  images: 'basic',
  network: 'basic',
  updatedBy: 'basic',
}
const ALL_GLOBAL_APP_FIELDS: CustomGlobalAppFields = {
  banner: 'basic',
  createdBy: 'basic',
  customCodes: 'basic',
  embeds: 'default',
  favicon: 'basic',
  image: 'basic',
  images: 'basic',
  network: 'basic',
  updatedBy: 'basic',
}

export const globalAppGQLFields = (fields: GlobalAppFields) => {
  if (!fields || fields === 'default') {
    fields = DEFAULT_GLOBAL_APP_FIELDS
  }
  if (fields === 'basic') {
    fields = BASIC_GLOBAL_APP_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_GLOBAL_APP_FIELDS
  }
  return `
    about
    authorName
    authorUrl
    clientId
    clientSecret
    comingSoon
    createdAt
    description
    docsUrl
    id
    imageId
    installed
    locked
    name
    networkId
    privacyPolicyUrl
    requiredPermissions
    secretToken
    slug
    standing
    status
    termsOfServiceUrl
    updatedAt
    webhookSignSecret
    webhookSubscriptions
    webhookUrl
    ${
      fields.banner
        ? `
      globalBanner {
        ${mediaGQLFields(fields.banner)}
      }
    `
        : ``
    }
    ${
      fields.createdBy
        ? `
      createdBy {
        ${memberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }
    ${
      fields.customCodes
        ? `
      customCodes {
        body
        head
      }
    `
        : ``
    }
    ${
      fields.embeds
        ? `
      globalEmbeds {
        ${embedGQLFields()}
      }
    `
        : ``
    }
    ${
      fields.favicon
        ? `
      globalFavicon {
        ${mediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }
    ${
      fields.image
        ? `
      globalImage {
        ${mediaGQLFields(fields.image)}
      }
    `
        : ``
    }
    ${
      fields.images
        ? `
      globalImages {
        ${mediaGQLFields(fields.images)}
      }
    `
        : ``
    }
    ${
      fields.network
        ? `
      globalNetwork {
        ${pluralNetworkGQLFields(fields.network)}
      }
    `
        : ``
    }
    ${
      fields.updatedBy
        ? `
      updatedBy {
        ${memberGQLFields(fields.updatedBy)}
      }
    `
        : ``
    }
  `
}
