import { FC } from 'react'

import { clsx } from 'clsx'

import { Image } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

export const OpenGraphPreviewCard: FC<{
  title: string
  description: string
  thumbnailUrl?: string
  type?: 'summary_large_image' | 'summary'
}> = ({ thumbnailUrl, title, description, type = 'summary_large_image' }) => {
  const { data: network } = useNetwork()
  const { domain, logo, favicon } = network || {}
  const logoUrl = (logo as Image)?.url
  const faviconUrl = (favicon as Image)?.url
  const _thumbnailUrl = thumbnailUrl || logoUrl || faviconUrl

  const fallbackIcon = '/images/icons/news-stroke.svg'

  let forceType = type
  if (_thumbnailUrl === logoUrl || _thumbnailUrl === faviconUrl) {
    forceType = 'summary'
  }

  if (forceType === 'summary' || !_thumbnailUrl) {
    return (
      <div className="flex flex-col border border-gray-300 rounded-lg bg-surface w-full overflow-hidden">
        <div className="flex flex-row items-center">
          <div
            className={clsx(
              'w-32 h-32 bg-center border-r bg-[#e1e8ed]',
              thumbnailUrl ? 'bg-cover' : 'bg-no-repeat bg-[length:3em]', // styles for the fallbackIcon
            )}
            style={{ backgroundImage: `url(${_thumbnailUrl ?? fallbackIcon})` }}
          />
          <div className="flex-1 py-3 px-4">
            <h2 className="text-lg font-bold truncate">{title}</h2>
            <p className="text-sm mt-2 line-clamp-2">{description}</p>
            <span className="text-xs text-content-muted mt-2 truncate">
              {domain}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="border border-gray-300 rounded-lg bg-surface w-full cursor-default">
      <div
        className="aspect-[1200/630] bg-cover bg-center rounded-t-lg"
        style={{ backgroundImage: `url(${_thumbnailUrl})` }}
      />
      <div className="p-4">
        <h2 className="text-lg font-bold truncate">{title}</h2>
        <p className="text-sm mt-2 line-clamp-2">{description}</p>
        <span className="text-xs text-content-muted mt-2 truncate">
          {domain}
        </span>
      </div>
    </div>
  )
}
