import { Link } from 'react-router-dom'

import {
  AskAiAvailability,
  BillingProduct,
} from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'
import { LoadingOverlay } from '@tribeplatform/react-ui-kit/LoadingOverlay'

interface Props {
  addon: BillingProduct
  askAiAvailability: AskAiAvailability
  addonIsInstalled: boolean
  canInstallAddon: boolean
  isUpdating: boolean
  onTrialSettingChange: (value: boolean) => Promise<void>
  onInstallAddon: () => Promise<void>
}

export const AskAISettingsContent = ({
  addon,
  askAiAvailability,
  addonIsInstalled,
  isUpdating,
  canInstallAddon,
  onTrialSettingChange,
  onInstallAddon,
}: Props) => {
  return (
    <Card.Content>
      <LoadingOverlay isLoading={isUpdating}>
        <div className="flex flex-col gap-y-3">
          <Card className="bg-surface-subdued">
            <Card.Content>
              <div className="flex items-center gap-x-3 justify-between">
                <Avatar
                  src={addon?.imageUrls?.at(0)}
                  size="4x"
                  rounded="base"
                />
                <div className="flex flex-col gap-y-2 flex-grow">
                  <h3 className="text-heading-xs font-medium text-content">
                    {addon?.name}
                  </h3>
                  <p className="text-content-subdued text-sm">
                    {addon?.description}
                  </p>
                </div>
                <div className="flex items-center gap-x-2">
                  {!addonIsInstalled ? (
                    <>
                      {canInstallAddon ? (
                        <Button
                          variant="primary"
                          onClick={onInstallAddon}
                          loading={isUpdating}
                        >
                          <T defaultMessage="Install" id="Generics.Install" />
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          as={Link}
                          to={`/manage/app-store/addons/${addon?.id}`}
                        >
                          <T defaultMessage="Upgrade" id="Generics.Upgrade" />
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      variant="primary"
                      as={Link}
                      to={`/manage/app-store/addons/${addon?.id}`}
                    >
                      <T defaultMessage="Settings" id="Generics.Settings" />
                    </Button>
                  )}
                </div>
              </div>
            </Card.Content>
          </Card>

          {!addonIsInstalled && (
            <Alert>
              <FormControl.Toggle
                name="ask-ai-preview"
                checked={askAiAvailability !== AskAiAvailability.disabled}
                label={
                  <>
                    <T
                      defaultMessage="Try for Free"
                      id="Admin.AskAISettings.Try"
                    />
                  </>
                }
                helperText={
                  <T
                    defaultMessage="Try Ask AI without installing the add-on. The feature will only be available to you and other staff."
                    id="Admin.AskAISettings.Try.Description"
                  />
                }
                disabled={isUpdating}
                onChange={value => {
                  onTrialSettingChange(value)
                }}
              />
            </Alert>
          )}
        </div>
      </LoadingOverlay>
    </Card.Content>
  )
}
