import { ReactNode } from 'react'

import { clsx } from 'clsx'

import { fileSize } from '@bettermode/common/filesize'
import { Image } from '@tribeplatform/gql-client/types'
import { useNetwork, useUploadFile } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { T, useI18n } from '../../../i18n/index.js'
import { PopoverImagePicker } from '../../../Picker/PopoverImagePicker.js'
import {
  DEFAULT_IMAGE_SIZE_LIMIT,
  RECOMMENDED_HEIGHT,
  RECOMMENDED_WIDTH,
} from './constants.js'

interface Props {
  image?: Image
  label?: ReactNode
  removable?: boolean
  onChange?: (image?: Image) => void
}

export const SeoImagePicker = ({
  image,
  onChange,
  label,
  removable = true,
}: Props) => {
  const { data: network } = useNetwork()
  const intl = useI18n()

  const { loadingFile, uploadFile } = useUploadFile()

  const onImageChange = (files: File[]) => {
    if (!files) {
      onChange?.(undefined)
      return
    }

    uploadFile(files[0], async uploadedImage => {
      onChange?.(uploadedImage)
    })
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between">
        {label}
        {removable && (
          <div className="text-sm font-normal ml-auto">
            <Link variant="accent" onClick={() => onImageChange(undefined)}>
              <T defaultMessage="Clear" id="Generics.Clear.Verb" />
            </Link>
          </div>
        )}
      </div>
      <div
        className="text-center border relative max-w-full flex h-48 bg-cover sm:rounded-md"
        style={{ backgroundImage: image?.url ? `url(${image.url})` : '' }}
      >
        <div className="absolute top-0 bottom-0 start-0 end-0 cursor-pointer flex justify-center items-center">
          <PopoverImagePicker
            tabs={['upload', 'link', 'unsplash']}
            sizeConfig={{
              recommendedWidth: RECOMMENDED_WIDTH,
              recommendedHeight: RECOMMENDED_HEIGHT,
            }}
            onRemove={() => onImageChange(undefined)}
            removable={removable && !!image}
            onImageChange={onImageChange}
          >
            <div
              className={clsx(
                !!image && 'bg-black opacity-70 rounded-base px-2 py-1',
              )}
            >
              {!!loadingFile && (
                <SvgIcon className="w-9 h-9 animate-spin" name="spinner" />
              )}
              {!loadingFile && (
                <>
                  <div className="mb-3 text-content-subdued flex justify-center">
                    <SvgIcon className="w-9 h-9" name="image-add" />
                  </div>
                  <Link>
                    <T
                      defaultMessage="Select an image"
                      id="Generics.SelectAnImage"
                    />
                  </Link>
                  <div className="text-content-subdued">
                    <T
                      defaultMessage="{formats} up to {size_limit}"
                      id="Generics.FormatAndSizeLimit"
                      values={{
                        size_limit: fileSize(
                          network?.settings?.imageSizeLimit ||
                            DEFAULT_IMAGE_SIZE_LIMIT,
                        ),
                        formats: intl.formatList(['PNG', 'JPG', 'GIF']),
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </PopoverImagePicker>
        </div>
      </div>
    </div>
  )
}
