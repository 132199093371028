import {
  ComponentPropsWithoutRef,
  ReactNode,
  startTransition,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

import {
  arrow as arrowMiddleware,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  Placement,
  shift,
  useDelayGroupContext,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Slot } from '@radix-ui/react-slot'

import { AnimatePortal } from '../animation/AnimatePortal.js'
import {
  FloatingFullContext,
  useFloatingFullContext,
} from '../utils/floating/index.js'
import { TOOLTIP_HOVER_DELAY_MS } from './constants.js'
import { tooltipStyles } from './Tooltip.styles.js'
import { TooltipContextProvider, useTooltipContext } from './TooltipContext.js'

export type TooltipDelay =
  | number
  | {
      open?: number
      close?: number
    }

export type TooltipProps = {
  children: ReactNode
  placement?: Placement
  defaultOpen?: boolean
  disabled?: boolean
  inverted?: boolean
  arrow?: boolean
  delay?: TooltipDelay
  offsetValue?: number
}

const DEFAULT_DELAY = {
  open: TOOLTIP_HOVER_DELAY_MS,
  close: TOOLTIP_HOVER_DELAY_MS,
}

/**
 * Tooltips display informative text when users hover, focus on, or tap an element.
 *
 * @see WAI-ARIA https://www.w3.org/TR/wai-aria-practices/#tooltip
 */
export const Tooltip = ({
  children,
  placement = 'top',
  defaultOpen = false,
  disabled = false,
  inverted = true,
  arrow = true,
  delay: customDelay = DEFAULT_DELAY,
  offsetValue = 4,
}: TooltipProps) => {
  const [open, setOpen] = useState(defaultOpen)

  const { delay } = useDelayGroupContext()
  const id = useId()

  const arrowRef = useRef(null)
  const { context } = useFloating<HTMLElement>({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(offsetValue),
      flip(),
      shift({ padding: 8 }),
      arrowMiddleware({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: delay || customDelay,
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
    useDelayGroup(context, { id }),
  ])

  return (
    <TooltipContextProvider
      arrow={arrow}
      disabled={disabled}
      inverted={inverted}
    >
      <FloatingFullContext.Provider
        value={{
          context,
          getReferenceProps,
          getFloatingProps,
          arrowRef,
        }}
      >
        {children}
      </FloatingFullContext.Provider>
    </TooltipContextProvider>
  )
}

export type TooltipTriggerProps = ComponentPropsWithoutRef<'div'> & {
  asChild?: boolean
}

const TooltipTrigger = ({
  children,
  asChild,
  ...props
}: TooltipTriggerProps) => {
  const { context, getReferenceProps } = useFloatingFullContext()
  const Component = asChild ? Slot : 'div'

  return (
    <Component
      ref={context.refs.setReference}
      {...getReferenceProps({ ...props })}
    >
      {children}
    </Component>
  )
}

export type TooltipPanelProps = ComponentPropsWithoutRef<'div'>

const TooltipPanel = ({ children, className }: TooltipPanelProps) => {
  const { getFloatingProps, context, arrowRef } = useFloatingFullContext()
  const { open, refs, floatingStyles } = context
  const { arrow, inverted, disabled } = useTooltipContext()

  /**
   * @tag Vite migration
   * produces hydration error. As a workaround, component is initialized client-side only
   */
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    startTransition(() => {
      setIsFirstRender(false)
    })
  }, [])

  if (isFirstRender || disabled || !children) {
    return null
  }

  return (
    <AnimatePortal idPrefix="tooltip" open={open}>
      <div
        ref={refs.setFloating}
        {...getFloatingProps({
          style: floatingStyles,
          className: tooltipStyles({ inverted, className }),
        })}
      >
        {children}
        {arrow && (
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className={inverted ? 'fill-surface-inverse' : 'fill-surface'}
          />
        )}
      </div>
    </AnimatePortal>
  )
}

Tooltip.Trigger = TooltipTrigger
Tooltip.Panel = TooltipPanel
