import { UnreadChatsCount } from '@tribeplatform/gql-client/types'

import { UseQueryOptions, useQuery, useQueryClient } from '../../index.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getUnreadChatsCountKey } from '../../utils/keys/messaging.key.js'

export type TUnreadChatsCount = {
  archived: UnreadChatsCount
  active: UnreadChatsCount
}

export const useUnreadChatsCount = (options?: {
  useQueryOptions?: UseQueryOptions<TUnreadChatsCount>
}) => {
  const { client } = useTribeClient()
  const key = getUnreadChatsCountKey()
  const queryClient = useQueryClient()

  return useQuery<TUnreadChatsCount>(
    key,
    async () => {
      const cachedUnreadChatsCount =
        queryClient.getQueryData<TUnreadChatsCount>(key)

      if (cachedUnreadChatsCount) {
        return cachedUnreadChatsCount
      }

      const [active, archived] = await Promise.all([
        client.messaging.getUnreadChatsCount(),
        client.messaging.getUnreadChatsCount({ archived: true }),
      ])

      return { archived, active }
    },
    {
      ...(options?.useQueryOptions ?? {}),
    },
  )
}
