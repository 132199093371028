import { useEffect, useState } from 'react'

import { useMatch } from 'react-router'

import { SearchEntity, SearchEntityType } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useSearch } from '@tribeplatform/react-sdk/hooks'
import { getSearchKey } from '@tribeplatform/react-sdk/utils/keys'
import { Autocomplete } from '@tribeplatform/react-ui-kit/Autocomplete'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { TopBar } from '@tribeplatform/react-ui-kit/TopBar'

import { useDebounce } from '../../common/hooks/useDebounce.js'
import { useFirstRenderContext } from '../../common/ssr/FirstRenderProvider.js'
import { T } from '../../i18n/index.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { cleanSearchQuery } from '../../utils/search.js'
import { SearchAutocompleteItems } from '../SearchAutocompleteItems.js'
import { SearchRowPage } from '../SearchRow.js'
import { getSearchItemLink, getSearchPageLink } from '../utils.js'
import { SearchBarProps } from './SearchBar.types.js'
import { getSearchInQuery, getSearchForQuery } from './SearchBar.utils.js'

const PAGE_RESULT = 'search-page-shortcut'

export const LegacySearchBar = ({
  autoFocus = false,
  query: inputQuery = '',
  type = null,
  entityType = null,
  spaceIds = [],
  placeholder: maybePlaceholder,
}: SearchBarProps) => {
  const { $t } = useI18n()
  const [dirtyQuery, setDirtyQuery] = useState(inputQuery)
  const query = cleanSearchQuery(dirtyQuery)

  const { push, replace } = useRouter()
  const debouncedQuery = useDebounce(query, 300)
  const match = useMatch('/search')
  const isNotSearchPage = match === null

  useEffect(() => {
    setDirtyQuery(inputQuery || '')
  }, [inputQuery, type])

  const { data, isInitialLoading, isFetching } = useSearch({
    variables: {
      input: {
        query: debouncedQuery
          ? debouncedQuery +
            getSearchForQuery(entityType) +
            getSearchInQuery(spaceIds)
          : '',
        includeExternal: true,
      },
    },
    fields: 'all',
    useQueryOptions: {
      queryKey: getSearchKey({
        variables: {
          input: { query: debouncedQuery },
        },
      }),
      keepPreviousData: true,
      enabled: isNotSearchPage,
    },
  })

  const onSelect = (value: SearchEntity, isEnterKey: boolean) => {
    if (!isEnterKey) {
      return
    }

    if (value && value.entityType !== SearchEntityType.External) {
      if ((value as unknown as string) === PAGE_RESULT) {
        push(getSearchPageLink({ search: dirtyQuery, type }))
      } else {
        push(getSearchItemLink(value))
      }
    }
  }

  const placeholder =
    maybePlaceholder ??
    $t({
      id: 'Generics.SearchDotDotDot',
      defaultMessage: 'Search...',
    })

  const { isFirstRender } = useFirstRenderContext()
  if (isFirstRender) {
    /**
     * @tag Vite migration
     * Autocomplete produces hydration error because of prop `id` did not match.
     * As a workaround, Autocomplete is initialized client-side only
     */
    return (
      <div className="flex items-center flex-1">
        <TopBar.Input
          value={dirtyQuery}
          onChange={e => setDirtyQuery(e.target.value)}
          placeholder={placeholder}
        />
      </div>
    )
  }

  return (
    <div className="flex items-center flex-1">
      <Autocomplete
        id="searchBar"
        itemToString={value => (value as SearchEntity)?.title || ''}
        value={dirtyQuery}
        onChange={onSelect}
        options={[
          PAGE_RESULT,
          ...(data?.hits.flatMap(category => category.hits) || []),
        ]}
        onInputChange={query => {
          setDirtyQuery(query)
          if (!isNotSearchPage) {
            replace(getSearchPageLink({ search: query, type }))
          }
        }}
        className="w-full relative"
        loading={isInitialLoading}
        defaultHighlightedIndex={isNotSearchPage ? 0 : undefined}
        clearInputOnSelect
        clearInputOnBlur={isNotSearchPage}
      >
        <Autocomplete.Input
          autoFocus={autoFocus}
          leadingIcon={
            isNotSearchPage && isFetching ? (
              <SvgIcon className="animate-spin" name="spinner" />
            ) : (
              <SvgIcon name="search" />
            )
          }
          placeholder={placeholder}
          showClearButton={false}
          inputMode="search"
        />
        <Autocomplete.Items className="h-full overflow-y-auto isolate">
          {isNotSearchPage && (
            <>
              <Autocomplete.Item index={0} value="search-page-shortcut-value">
                <SearchRowPage
                  search={dirtyQuery}
                  link={getSearchPageLink({ search: dirtyQuery, type })}
                  spacing="sm"
                />
              </Autocomplete.Item>
              <SearchAutocompleteItems data={data} />
              {isInitialLoading && (
                <Autocomplete.ItemsEmpty>
                  <T
                    id="Generics.SearchingDotDotDto"
                    defaultMessage="Searching..."
                  />
                </Autocomplete.ItemsEmpty>
              )}
            </>
          )}
        </Autocomplete.Items>
      </Autocomplete>
    </div>
  )
}
