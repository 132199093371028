import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { clsx } from 'clsx'

import { useIsomorphicLayoutEffect } from '../hooks/useIsomorphicLayoutEffect.js'
import { Link } from '../Link/Link.js'

type TruncateProps = {
  children: ReactNode
  size: 'sm' | 'base' | 'md' | 'lg'
  expandable?: boolean
  seeMoreText: string
  seeLessText: string
  scrollOffset?: string
  gradientTo?: string
}

const TRUNCATE_SIZE_MAPPER: Record<TruncateProps['size'], string> = {
  sm: 'max-h-[6rem]', // 96px
  base: 'max-h-[16rem]', // 256px
  md: 'max-h-[27rem]', // 432px
  lg: 'max-h-[50rem]', // 800px
}

export const Truncate = ({
  children,
  size = 'sm',
  expandable = true,
  seeMoreText,
  seeLessText,
  scrollOffset = '0',
  gradientTo = 'to-surface',
}: TruncateProps) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const [showingMore, setShowingMore] = useState(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const childRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const isFirstRender = useRef(true)

  const scrollParentIntoView = () => {
    const scrollElement = scrollRef.current
    scrollElement.scrollIntoView({
      behavior: 'smooth',
    })
  }

  useIsomorphicLayoutEffect(() => {
    const childElement = childRef.current
    const resizeObserver = new ResizeObserver(entries => {
      const parentElement = parentRef.current
      const parentHeight = parentElement.getBoundingClientRect().height
      const childHeight = entries[0].contentRect.height

      if (childHeight > parentHeight) {
        setIsTruncated(true)
      } else {
        setIsTruncated(false)
      }
    })

    resizeObserver.observe(childElement)

    return () => {
      resizeObserver.unobserve(childElement)
    }
  }, [])

  // This effect is only for the block to show correct truncate in customizer
  // When we change size and expandable props in the customizer, the ResizeObserver does not detect
  // any changes because the content has not changed. In that case, this effect will do the changes.
  // In order to prevent multiple renders, this will only fire if props change after the initial load
  // which only happens in the customizer
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const childElement = childRef.current
    const parentElement = parentRef.current
    const parentHeight = parentElement.getBoundingClientRect().height
    const childHeight = childElement.getBoundingClientRect().height

    if (childHeight > parentHeight) {
      setIsTruncated(true)
    } else {
      setIsTruncated(false)
    }
  }, [size, expandable])

  // To fix truncated videos jumping to screen in chrome
  useIsomorphicLayoutEffect(() => {
    const parentElement = parentRef.current
    const resetScroll = () => {
      const { scrollTop } = parentElement

      if (scrollTop !== 0) {
        parentElement.scrollTo(0, 0)
      }
    }
    parentElement.addEventListener('scroll', resetScroll)

    return () => {
      parentElement.removeEventListener('scroll', resetScroll)
    }
  }, [])

  return (
    <>
      <div
        ref={parentRef}
        className={clsx(
          'overflow-hidden relative',
          !showingMore &&
            (TRUNCATE_SIZE_MAPPER?.[size] ?? TRUNCATE_SIZE_MAPPER.sm),
        )}
      >
        <div
          ref={scrollRef}
          className="absolute"
          style={{ top: scrollOffset }}
        ></div>
        <div ref={childRef}>{children}</div>
        {isTruncated && !showingMore && (
          <div
            className={clsx(
              'absolute left-0 bottom-0 w-full h-12 bg-gradient-to-b from-transparent',
              gradientTo,
            )}
          ></div>
        )}
      </div>
      {isTruncated && expandable && (
        <div className="mt-2">
          {showingMore ? (
            <Link
              as="button"
              onClick={() => {
                setShowingMore(false)
                scrollParentIntoView()
              }}
            >
              {seeLessText}
            </Link>
          ) : (
            <Link as="button" onClick={() => setShowingMore(true)}>
              {seeMoreText}
            </Link>
          )}
        </div>
      )}
    </>
  )
}
