import React, { createContext, useContext, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import {
  ActionItemsContextType,
  ActionItemsEntityNameType,
  ActionItemsEntityProps,
  ActionItemsModalKeysType,
} from './ActionItems.types.js'
import { ActionItemsModals } from './ActionItemsModals/ActionItemsModals.js'

const ActionItemsContext = createContext<ActionItemsContextType | undefined>(
  undefined,
)

/**
 * This context is used to manage Modals for Action Items
 * These Modals are used to display the action items in the context menu and they can also be opened from the action items in the command bar
 */
export const ActionItemsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation()

  const [entityProps, setEntityProps] = useState<ActionItemsEntityProps | null>(
    null,
  )
  const [modalKey, setModalKey] = useState<ActionItemsModalKeysType | null>(
    null,
  )
  const [entityName, setEntityName] =
    useState<ActionItemsEntityNameType | null>(null)

  const openModal = (
    entityName: ActionItemsEntityNameType,
    modalKey: ActionItemsModalKeysType,
    entityProps: ActionItemsEntityProps,
  ) => {
    setEntityName(entityName)
    setEntityProps(entityProps)
    setModalKey(modalKey)
  }

  const closeModal = () => {
    setEntityName(null)
    setEntityProps(null)
    setModalKey(null)
  }

  useEffect(() => {
    closeModal()
  }, [pathname])

  return (
    <ActionItemsContext.Provider
      value={{ openModal, closeModal, entityName, modalKey, entityProps }}
    >
      <ActionItemsModals />
      {children}
    </ActionItemsContext.Provider>
  )
}

export const useActionItems = () => {
  const context = useContext(ActionItemsContext)

  if (!context) {
    throw new Error('useActionItems must be used within an ActionItemsProvider')
  }

  return context
}
