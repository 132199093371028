import { useEffect, useMemo, useRef, useState } from 'react'

import { MESSAGING_POST_TYPE_SLUG } from '@bettermode/common/utils'
import { PostTypeContext, SpacePostType } from '@tribeplatform/gql-client/types'
import {
  useDeleteSpacePostTypes,
  useNetwork,
  useSpacePostTypes,
  useUpdateSpacePostTypes,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Divider } from '@tribeplatform/react-ui-kit/Divider'
import { SectionHeader } from '@tribeplatform/react-ui-kit/SectionHeader'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { Form } from '../../Form/index.js'
import type { FormRef } from '../../Form/typings.js'
import { T } from '../../i18n/components/T.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import {
  PostTypePermission,
  useSpacePostTypeSettings,
} from './hooks/useSpacePostTypeSettings.js'
import { FormDirtyUpdater } from './SpaceSettingsGeneral.js'
import { SpaceSettingsProps } from './types.js'

export const SpaceSettingsPostTypes = ({
  space,
  onClose,
  isChangingTab,
  confirmTabChange,
  rejectTabChange,
  setDirty,
  setSubmit,
  isActive,
}: SpaceSettingsProps) => {
  const { $t } = useI18n()
  const { permissionItems, getWhoCan, mapToPostTypePermissions } =
    useSpacePostTypeSettings({ spaceId: space.id, isPrivate: space.private })
  const buttonRef = useRef<HTMLButtonElement>()
  const formRef = useRef<FormRef>()
  const {
    data: { postTypes },
    isLoading: isLoadingPostTypes,
  } = useNetwork()
  const {
    data: spacePostTypesData,
    isInitialLoading: isLoadingSpacePostTypes,
  } = useSpacePostTypes({
    variables: {
      limit: 30,
      spaceId: space.id,
    },
  })
  const spacePostTypes = useMemo(
    () => simplifyPaginatedResult<SpacePostType>(spacePostTypesData)?.nodes,
    [spacePostTypesData],
  )
  const [postTypePermissions, setPostTypePermissions] = useState(
    mapToPostTypePermissions(postTypes, spacePostTypes),
  )
  const { mutateAsync: updateSpacePostTypes, isLoading: isUpdateLoading } =
    useUpdateSpacePostTypes()
  const { mutateAsync: deleteSpacePostTypes, isLoading: isDeleteLoading } =
    useDeleteSpacePostTypes()

  useEffect(() => {
    const permissions = mapToPostTypePermissions(postTypes, spacePostTypes)
    setPostTypePermissions(permissions)

    if (formRef?.current?.methods) {
      formRef.current.methods.reset({ ...permissions })
    }
  }, [postTypes, spacePostTypes, formRef, mapToPostTypePermissions])

  useEffect(() => {
    if (isActive) {
      setSubmit(() => () => buttonRef?.current?.click())
    }
  }, [isActive, setSubmit, buttonRef])

  const onSubmit = async (data, methods) => {
    try {
      const updates: PostTypePermission[] = []
      const deletes: PostTypePermission[] = []
      Object.values(data).forEach((postTypePermission: PostTypePermission) => {
        const existingSpacePostType = spacePostTypes.find(
          it => it.postTypeId === postTypePermission.postType?.id,
        )

        if (!postTypePermission.enabled && existingSpacePostType) {
          // item removed
          deletes.push(postTypePermission)
        } else if (!postTypePermission.enabled && !existingSpacePostType) {
          // skip update
        } else if (postTypePermission.enabled && !existingSpacePostType) {
          // item added
          updates.push(postTypePermission)
        } else if (postTypePermission.enabled) {
          // assuming item was updated
          updates.push(postTypePermission)
        }
      })

      const promises = []
      if (updates.length > 0) {
        promises.push(
          updateSpacePostTypes({
            spaceId: space.id,
            input: updates.map(update => ({
              postTypeId: update.postType.id,
              whoCanPost: getWhoCan(update.whoCanPost),
              whoCanReact: getWhoCan(update.whoCanReact),
              whoCanReply: getWhoCan(update.whoCanReply),
            })),
          }),
        )
      }

      if (deletes.length > 0) {
        promises.push(
          deleteSpacePostTypes({
            spaceId: space.id,
            postTypeIds: deletes.map(update => update.postType.id),
          }),
        )
      }

      await Promise.all(promises)

      methods.reset({ ...data })
      toast({
        title: $t({
          defaultMessage: '{SPACE_CC} post settings updated',
          id: 'Admin.Space.PostSettings.Updated',
        }),
        status: 'success',
      })
      if (isChangingTab) {
        confirmTabChange()
      } else {
        onClose()
      }
    } catch (e) {
      toast({
        title: $t({
          defaultMessage: 'Unable to update {SPACE} post settings',
          id: 'Admin.Space.PostSettings.FailedToUpdate',
        }),
        status: 'error',
      })
      if (isChangingTab) {
        rejectTabChange()
      }
    }
  }

  if (isLoadingPostTypes || isLoadingSpacePostTypes) {
    return (
      <div className="bg-surface px-4 py-6 sm:p-6">
        <div className="animate-pulse flex space-s-4 items-center">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-skeleton rounded-full w-3/4" />
            <div className="h-4 bg-skeleton rounded-full w-3/4" />
            <div className="h-4 bg-skeleton rounded-full w-3/4" />
            <div className="h-4 bg-skeleton rounded-full w-3/4" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Form ref={formRef} defaultValues={postTypePermissions} onSubmit={onSubmit}>
      {({ watch }) => (
        <>
          <FormDirtyUpdater isActive={isActive} setDirty={setDirty} />
          <div className="sm:h-132 flex flex-col">
            <div className="flex flex-col flex-grow overflow-auto p-5">
              <SectionHeader
                title={$t({
                  defaultMessage: 'Post types',
                  id: 'Admin.Space.PostSettings.Types',
                })}
                description={$t({
                  defaultMessage:
                    'Manage what type of content is available in this {SPACE} and related permissions.',
                  id: 'Admin.Space.PostSettings.Types.Description',
                })}
                className="pb-5"
              />
              <div className="space-y-6">
                <Divider padding="none" />
                {postTypes
                  .filter(it => it.context === PostTypeContext.post)
                  .filter(it => it.slug !== MESSAGING_POST_TYPE_SLUG)
                  .map(postType => {
                    const [isEnabled] = watch([`${postType.id}.enabled`])
                    return (
                      <div key={postType.id} className="space-y-5">
                        <Form.Toggle
                          name={`${postType.id}.enabled`}
                          label={postType.name}
                        />
                        {isEnabled && (
                          <div className="flex flex-col space-y-5 px-4 py-5 sm:p-6 bg-surface-subdued rounded-base">
                            <Form.Select
                              label={$t({
                                defaultMessage: 'Who can post?',
                                id: 'Admin.Space.PostSettings.WhoCanPost',
                              })}
                              name={`${postType.id}.whoCanPost`}
                              items={permissionItems}
                            />

                            {postType?.validReplyTypes?.length > 0 && (
                              <Form.Select
                                key={postType.id}
                                label={$t({
                                  defaultMessage: 'Who can reply?',
                                  id: 'Admin.Space.PostSettings.WhoCanReply',
                                })}
                                name={`${postType.id}.whoCanReply`}
                                items={permissionItems}
                              />
                            )}

                            <Form.Select
                              label={$t({
                                defaultMessage: 'Who can react?',
                                id: 'Admin.Space.PostSettings.WhoCanReact',
                              })}
                              name={`${postType.id}.whoCanReact`}
                              items={permissionItems}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="flex justify-end px-5 py-5 border-t space-s-2">
              <Button
                type="button"
                variant="secondaryNeutral"
                onClick={() => onClose()}
              >
                <T defaultMessage="Cancel" id="Generics.Cancel" />
              </Button>
              <Button
                type="submit"
                loading={isUpdateLoading || isDeleteLoading}
                ref={buttonRef}
              >
                <T defaultMessage="Update" id="Generics.Update" />
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  )
}
