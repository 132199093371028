import { ReactNode } from 'react'

import { clsx } from 'clsx'
import { useCombobox, useMultipleSelection } from 'downshift'

import { FloatingContextProvider } from '../utils/floating/index.js'
import {
  MultiselectButton,
  MultiselectSelectedItem,
} from './MultiselectButton.js'
import { MultiselectContext } from './MultiselectContext.js'
import { MultiselectItem } from './MultiselectItem.js'
import { MultiselectItems, MultiselectItemsEmpty } from './MultiselectItems.js'
import { hasId } from './util.js'

export type MultiselectProps<Item> = {
  value: Item[]
  options: Item[]
  onChange?: (newValue: Item[]) => void
  className?: string
  children: ReactNode
  disabled?: boolean
  searchable?: boolean
  onInputChange?: (newValue: string) => void
  invalid?: boolean
  name?: string
  id?: string
}

/**
 * Component that displays a list of options and allows for multiple selections from this list
 */
export const Multiselect = <Item,>(props: MultiselectProps<Item>) => {
  const {
    children,
    value = [],
    options,
    onChange,
    className,
    disabled = false,
    searchable = false,
    onInputChange,
    invalid = false,
    ...rest
  } = props

  const dsMultiple = useMultipleSelection<Item>({
    selectedItems: value,
    onSelectedItemsChange: changes => {
      if (typeof onChange === 'function') {
        onChange(changes.selectedItems)
      }
    },
  })

  const dsCombobox = useCombobox<Item>({
    items: options,
    selectedItem: null,
    onSelectedItemChange: changes => {
      const { selectedItem } = changes
      if (
        !selectedItem ||
        changes.type === useCombobox.stateChangeTypes.InputBlur
      ) {
        return
      }

      let index
      if (hasId(selectedItem)) {
        index = dsMultiple.selectedItems.findIndex(
          it => hasId(it) && it.id === selectedItem.id,
        )
      } else {
        index = dsMultiple.selectedItems.indexOf(selectedItem)
      }

      if (index > 0) {
        dsMultiple.setSelectedItems([
          ...dsMultiple.selectedItems.slice(0, index),
          ...dsMultiple.selectedItems.slice(index + 1),
        ])
      } else if (index === 0) {
        dsMultiple.setSelectedItems([...dsMultiple.selectedItems.slice(1)])
      } else {
        dsMultiple.setSelectedItems([...dsMultiple.selectedItems, selectedItem])
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: state.inputValue,
          }
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
            isOpen: false,
          }
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return {
            ...changes,
            inputValue: state.inputValue,
          }
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            // To avoid closing the menu when there is an input value.
            isOpen: true,
          }
        default:
          return {
            ...changes,
            isOpen: state.isOpen,
          }
      }
    },
    onInputValueChange: changes => {
      if (searchable) {
        onInputChange?.(changes.inputValue)
      }
    },
  })

  return (
    <div className={clsx('relative isolate', className)} {...rest}>
      <FloatingContextProvider placement="bottom-start">
        <MultiselectContext.Provider
          value={{
            ...dsMultiple,
            ...dsCombobox,
            searchable,
            disabled,
            creating: false,
            invalid,
          }}
        >
          {children}
        </MultiselectContext.Provider>
      </FloatingContextProvider>
    </div>
  )
}

Multiselect.Button = MultiselectButton
Multiselect.SelectedItem = MultiselectSelectedItem
Multiselect.Items = MultiselectItems
Multiselect.ItemsEmpty = MultiselectItemsEmpty
Multiselect.Item = MultiselectItem
