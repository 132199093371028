import { useEffect, useMemo, useState } from 'react'

import {
  Post,
  PostListFilterByEnum,
  PostListFilterByOperator,
  PostListOrderByEnum,
} from '@tribeplatform/gql-client/types'
import { useAuthMember, useFeed } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { useQuery } from '../common/hooks/useQuery.js'
import { GenericPostList } from '../PostList/GenericPostList.js'
import {
  PostListFilter,
  PostListFilterLoading,
} from '../PostList/PostListFilter.js'
import { getUserSettings, setUserSetting } from '../utils/userSettings/index.js'
import { EmptyState } from './EmptyState.js'
import { TruncateSettingsContext } from './TruncateSettingsProvider.js'
import { FeedProps, TruncateSettings } from './types.js'

export const Feed = ({
  view = 'card',
  orderBy: defaultOrderBy = PostListOrderByEnum.publishedAt,
  truncateSize = 'md',
  displayStyle = 'expandable',
  ...rest
}: FeedProps) => {
  const { isGuest } = useAuthMember()

  const [orderBy, setOrderBy] = useState<PostListOrderByEnum>()

  useEffect(() => {
    if (!orderBy) {
      const userSettingOrderBy = getUserSettings()?.postListOrderBy?.Feed
      setOrderBy(userSettingOrderBy || defaultOrderBy)
    }
  }, [defaultOrderBy, orderBy])

  const { tag_id: tagId } = useQuery()
  const filterBy = []
  if (tagId) {
    filterBy.push({
      key: PostListFilterByEnum.publishedAt,
      keyString: 'tagIds',
      operator: PostListFilterByOperator.contains,
      value: JSON.stringify(tagId),
    })
  }

  useEffect(() => {
    if (orderBy) {
      setUserSetting('postListOrderBy', {
        Feed: orderBy,
      })
    }
  }, [orderBy])

  const {
    data,
    fetchNextPage = false,
    hasNextPage,
    isLoading,
    isFetched,
    isFetchingNextPage,
  } = useFeed({
    fields: {
      tags: 'basic',
      owner: {
        member: { profilePicture: 'basic', badges: 'all' },
      },
      embeds: 'default',
      mentions: 'basic',
      space: 'basic',
      attachments: 'default',
      authMemberProps: 'all',
      reactions: { fields: 'all', variables: { limit: 10 } },
      replies: {
        fields: {
          authMemberProps: 'all',
          embeds: 'default',
          mentions: 'basic',
          attachments: 'default',
          owner: {
            member: { profilePicture: 'basic', badges: 'all' },
          },
          reactions: { fields: 'all', variables: { limit: 10 } },
        },
        variables: {
          limit: 2,
          reverse: true,
        },
      },
    },
    variables: {
      limit: view === 'card' ? 5 : 10,
      onlyMemberSpaces: !isGuest,
      orderBy,
      filterBy,
    },
    useInfiniteQueryOptions: {
      refetchOnMount: 'always',
      enabled: !!orderBy,
    },
  })
  const { nodes: posts } = simplifyPaginatedResult<Post>(data)

  const feedTruncateSettings = useMemo<TruncateSettings>(() => {
    return { displayStyle, truncateSize }
  }, [displayStyle, truncateSize])

  return (
    <TruncateSettingsContext.Provider value={feedTruncateSettings}>
      <div {...rest}>
        {orderBy ? (
          <PostListFilter orderBy={orderBy} setOrderBy={setOrderBy} />
        ) : (
          <PostListFilterLoading />
        )}
        <GenericPostList
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          posts={posts}
          isFetched={isFetched}
          view={view}
          emptyState={<EmptyState />}
          context="network"
        />
      </div>
    </TruncateSettingsContext.Provider>
  )
}
