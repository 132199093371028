import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  Member,
  MutationRevokeBadgeArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { getMemberKey } from '../../utils/keys/member.key.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'

export const useRevokeBadge = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationRevokeBadgeArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationRevokeBadgeArgs>(
    input => client.badge.revoke(input),
    {
      onMutate: variables => {
        const memberKey = getMemberKey({
          variables: { id: variables?.input?.memberId },
        })

        queryClient.cancelQueries(memberKey)

        queryClient.setQueriesData<Member>(memberKey, old => ({
          ...old,
          badges: old?.badges.filter(badge => badge?.badgeId !== variables?.id),
        }))
      },
      onSettled: (data, error, variables) => {
        const memberKey = getMemberKey({
          variables: { id: variables?.input?.memberId },
        })
        const networkKey = getNetworkKey()
        const authTokenKey = getAuthTokenKey()
        queryClient.invalidateQueries(memberKey)
        queryClient.invalidateQueries(authTokenKey)
        queryClient.invalidateQueries(networkKey)
      },
      ...useMutationOptions,
    },
  )
}
