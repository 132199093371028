import { useMemo } from 'react'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationUpdateNetworkSearchSettingsArgs,
  Network,
  SearchNetworkSettings,
} from '@tribeplatform/gql-client/types'
import { AskAiAvailability } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'
import { useBillingProducts } from '../billing/useBillingProducts.js'
import { useCachedNetwork } from '../cache/useCachedNetwork.js'
import { useNetwork } from '../network/useNetwork.js'
import { ASK_AI_ACCESS_FLAG_KEY } from './AskAI.constants.js'

export const useAskAIAccess = (options?: {
  useMutationOptions?: UseMutationOptions<
    SearchNetworkSettings,
    ClientError,
    MutationUpdateNetworkSearchSettingsArgs,
    { snapshot: Network }
  >
}) => {
  const { isAdmin, isModerator } = useAuthMember()
  const { data: network } = useNetwork({
    fields: {
      ...(typeof window === 'undefined' ? {} : { searchSettings: 'all' }), // avoid fetching searchSettings on server
      limits: 'all',
    },
  })
  const { data: billingProducts, isLoading: isFetchingAddon } =
    useBillingProducts({
      fields: undefined,
      useQueryOptions: { enabled: isAdmin },
    })
  const addon = useMemo(
    () =>
      billingProducts?.addons.find(product =>
        product?.categories.includes(ASK_AI_ACCESS_FLAG_KEY),
      ),
    [billingProducts?.addons],
  )

  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const networkKey = getNetworkKey()
  const networkSnapshot = useCachedNetwork()

  // method to toggle the AskAiAvailability
  const updateNetworkAskAISettings = useMutation<
    SearchNetworkSettings,
    ClientError,
    MutationUpdateNetworkSearchSettingsArgs,
    { snapshot: Network }
  >(variables => client.search.updateNetworkSearchSettings(variables), {
    onMutate: async newNetworkSettings => {
      await queryClient.cancelQueries(networkKey)
      const snapshot = networkSnapshot
      queryClient.setQueriesData<Network>(networkKey, old => {
        return {
          ...old,
          searchSettings: {
            ...old.searchSettings,
            ...newNetworkSettings,
          },
        }
      })

      return { snapshot }
    },
    onError: (err, newNetworkSettings, context) => {
      // revert back the optimistic response
      queryClient.setQueriesData<Network>(networkKey, context.snapshot)
    },
    onSettled: () => {
      // invalidate to refetch again
      queryClient.invalidateQueries(networkKey)
    },
    ...useMutationOptions,
  })

  const askAiAvailability = network?.searchSettings?.askAiAvailability
  const askAiAccess = network?.limits?.usageLimits?.askAiAccess
  const isStaff = isAdmin || isModerator

  const canAskAi =
    askAiAvailability === AskAiAvailability.everyone ||
    (isStaff && askAiAvailability === AskAiAvailability.staffOnly)

  return {
    addon,
    canAskAi,
    askAiAccess,
    askAiAvailability,
    isFetchingAddon,
    updateNetworkAskAISettings,
  }
}
