import { UpdatePublisherInput } from '@tribeplatform/gql-client/types'
import {
  usePublisherSettings,
  useUpdatePublisherSettings,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SectionHeader } from '@tribeplatform/react-ui-kit/SectionHeader'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { Form } from '../../Form/index.js'
import { T } from '../../i18n/components/T.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { SpaceSettingsProps } from './types.js'

export const SpaceSettingsNotifications = ({
  space,
  onClose,
  isChangingTab,
  confirmTabChange,
  rejectTabChange,
}: SpaceSettingsProps) => {
  const { $t } = useI18n()

  const { data: publisherSettings } = usePublisherSettings({
    variables: { publisherId: space?.id },
  })
  const { mutateAsync: updatePublisherSettings, isLoading } =
    useUpdatePublisherSettings()
  const onSave = async (input: UpdatePublisherInput) => {
    try {
      await updatePublisherSettings({
        publisherType: 'space',
        publisherId: space?.id,
        input,
      })
      toast({
        title: $t({
          defaultMessage: 'Space default notification settings updated',
          id: 'Admin.NotificationSettings.SpaceDefaults.Updated',
        }),
        status: 'success',
      })
      if (isChangingTab) {
        confirmTabChange()
      } else {
        onClose()
      }
    } catch (e) {
      toast({
        title: $t({
          defaultMessage:
            'Unable to update space default notification settings',
          id: 'Admin.NotificationSettings.SpaceDefaults.FailedToUpdate',
        }),
        status: 'error',
      })
      if (isChangingTab) {
        rejectTabChange()
      }
    }
  }

  if (!publisherSettings) {
    return null
  }

  return (
    <Form
      defaultValues={{
        notifyImplicitSubscriptions:
          publisherSettings?.notifyImplicitSubscriptions,
      }}
      onSubmit={async input => {
        await onSave(input)
      }}
    >
      <div className="sm:h-132 flex flex-col">
        <div className="flex flex-col flex-grow overflow-auto p-5">
          <SectionHeader
            title={$t({
              defaultMessage: 'Notifications',
              id: 'Generics.Notifications',
            })}
            className="pb-5"
          />
          <div className="space-y-5">
            <Form.Toggle
              name="notifyImplicitSubscriptions"
              label={$t({
                defaultMessage: 'Auto-subscribe new and existing members',
                id: 'Admin.Space.Notifications.SubscribeByDefault',
              })}
              helperText={$t({
                defaultMessage:
                  'Enable this setting to automatically subscribe new and existing members to notifications for updates and posts, unless they have already adjusted their notification settings.',
                id: 'Admin.Space.Notifications.SubscribeByDefaultDescription',
              })}
            />
          </div>
        </div>
        <div className="flex justify-end px-5 py-5 border-t space-s-2">
          <Button
            type="button"
            variant="secondaryNeutral"
            onClick={() => onClose()}
          >
            <T defaultMessage="Cancel" id="Generics.Cancel" />
          </Button>
          <Button type="submit" loading={isLoading}>
            <T defaultMessage="Update" id="Generics.Update" />
          </Button>
        </div>
      </div>
    </Form>
  )
}
