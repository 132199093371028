import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  Member,
  MutationAssignBadgeArgs,
} from '@tribeplatform/gql-client/types'
import { BadgeType } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { getMemberKey } from '../../utils/keys/member.key.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'
import { useCachedNetwork } from '../cache/useCachedNetwork.js'

export const useAssignBadge = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationAssignBadgeArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const network = useCachedNetwork()

  return useMutation<Action, ClientError, MutationAssignBadgeArgs>(
    input => client.badge.assign(input),
    {
      onMutate: variables => {
        const memberKey = getMemberKey({
          variables: { id: variables?.input?.memberId },
        })

        queryClient.cancelQueries(memberKey)

        const badge = network?.badges?.find(
          badge => badge?.id === variables?.id,
        )

        queryClient.setQueriesData<Member>(memberKey, old => ({
          ...old,
          badges: [
            ...(old?.badges ?? []),
            {
              badge,
              badgeId: badge?.id,
              type: BadgeType.Manual,
              shortDescription: badge?.shortDescription,
            },
          ],
        }))
      },
      onSettled: (data, error, variables) => {
        const memberKey = getMemberKey({
          variables: { id: variables?.input?.memberId },
        })
        const networkKey = getNetworkKey()
        const authTokenKey = getAuthTokenKey()
        queryClient.invalidateQueries(memberKey)
        queryClient.invalidateQueries(authTokenKey)
        queryClient.invalidateQueries(networkKey)
      },
      ...useMutationOptions,
    },
  )
}
