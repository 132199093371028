import { gql } from 'graphql-tag'

import {
  embedGQLFields,
  imageGQLFields,
  MESSAGE_META_FIELDS,
} from '../index.js'
import { fileGQLFields } from '../post/file.fields.js'

const MESSAGE_FIELDS = `
  _id
  pageId
  addressSignature
  authorId
  authorParticipantId
  authorName
  sentAt
  content
  preview
  attachmentIds
  attachments {
    ${fileGQLFields()}
  }
  embedIds
  embeds {
    ${embedGQLFields()}
  }
  imageIds
  images {
    ${imageGQLFields()}
  }
  contentType
  meta {
    ${MESSAGE_META_FIELDS}
  }
  pos
  nonce
  legacyId
`

const MESSAGES_FIELDS = `
  pageInfo {
    hasNextPage
    endCursor
  }
  data {
    ${MESSAGE_FIELDS}
  }
`

export const messagesGQLQuery = () => gql`
  query messages(
    $chatId: String!
    $pageId: String!
    $messageId: String!
    $addressSignature: String!
    $limit: Int!
    $direction: QueryDirection!
    $inclusive: Boolean
  ) {
    messages(
      chatId: $chatId
      pageId: $pageId
      messageId: $messageId
      addressSignature: $addressSignature
      limit: $limit
      direction: $direction
      inclusive: $inclusive
    ) {
      ${MESSAGES_FIELDS}
    }
  }
`

export const messagesByCursorGQLQuery = () => gql`
  query messagesByCursor(
    $chatId: String!
    $cursor: String!
    $limit: Int!
  ) {
    messagesByCursor(
      chatId: $chatId
      cursor: $cursor
      limit: $limit
    ) {
      ${MESSAGES_FIELDS}
    }
  }
`

export const sendMessageToChatGQLMutation = () => gql`
  mutation sendMessageToChat(
    $chatId: String!
    $input: SendMessageToChatInput!
  ) {
    sendMessageToChat(chatId: $chatId, input: $input) {
      chatId
      pageId
      message {
        ${MESSAGE_FIELDS}
      }
    }
  }
`

export const sendMessageToMemberGQLMutation = () => gql`
  mutation sendMessageToMember(
    $input: SendMessageToMemberInput!
  ) {
    sendMessageToMember(input: $input) {
      chatId
      pageId
      message {
        ${MESSAGE_FIELDS}
      }
    }
  }
`

export const unreadMessageCountsGQLQuery = () => gql`
  query unreadMessageCounts($chatIds: [String!]!) {
    unreadMessageCounts(chatIds: $chatIds) {
      counts {
        chatId
        count
      }
    }
  }
`

export const advanceReadMessageOffsetGQLMutation = () => gql`
  mutation advanceReadMessageOffset(
    $chatId: String!
    $returnNewCount: Boolean
    $input: AdvanceReadMessageOffsetInput!
  ) {
    advanceReadMessageOffset(
      chatId: $chatId
      returnNewCount: $returnNewCount
      input: $input
    ) {
      chatId
      changed
      count
    }
  }
`
