import { useEffect, useState } from 'react'

import { clsx } from 'clsx'

import { Badge, BadgeType } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useAssignBadge,
  useInvalidateAllPostsQuery,
  useMember,
  useNetwork,
  useRevokeBadge,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Checkbox } from '@tribeplatform/react-ui-kit/Checkbox'
import { List } from '@tribeplatform/react-ui-kit/Layout'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { MemberBadge } from './MemberBadge.js'

export type ManageBadgesModalProps = {
  memberId: string
  open: boolean
  onClose: () => void
}

export const ManageBadgesModal = ({
  memberId,
  onClose,
  open,
}: ManageBadgesModalProps) => {
  const { Link } = useRouter()
  const { $t } = useI18n()
  const {
    data: { badges },
  } = useNetwork()
  const { data: member } = useMember({
    variables: {
      id: memberId,
    },
    fields: 'default',
  })
  const { mutateAsync: assignBadge } = useAssignBadge()
  const invalidatePostsQuery = useInvalidateAllPostsQuery()
  const { mutateAsync: revokeBadge } = useRevokeBadge()
  const assignableBadges = badges?.filter(
    (b: Badge) => b.active && b.type === BadgeType.Manual,
  )
  const initialBadgeIds = member?.badges?.map(b => b.badgeId) || []
  const [badgeIds, setBadgeIds] = useState<string[]>(initialBadgeIds)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setBadgeIds(member?.badges?.map(b => b.badgeId) || [])
  }, [member?.badges])

  const onSubmit = async () => {
    const addedIds = badgeIds.filter(id => !initialBadgeIds.find(i => id === i))
    const revokedIds = initialBadgeIds.filter(
      id => !badgeIds.find(i => id === i),
    )
    const promises = []
    addedIds.forEach(id =>
      promises.push(
        assignBadge({
          id,
          input: {
            memberId: member?.id,
          },
        }),
      ),
    )
    revokedIds.forEach(id =>
      promises.push(
        revokeBadge({
          id,
          input: {
            memberId: member?.id,
          },
        }),
      ),
    )
    if (promises.length) {
      setIsLoading(true)
      await Promise.all(promises)
      invalidatePostsQuery()
    }
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        title={$t({
          defaultMessage: 'Manage badges',
          id: 'Generics.ManageBadges',
        })}
      />
      <Modal.Content>
        <List spacing="none">
          {assignableBadges?.map(badge => {
            const isAssigned = badgeIds.find(badgeId => badgeId === badge.id)
            const onClick = () => {
              if (isAssigned) {
                setBadgeIds(badgeIds.filter(badgeId => badgeId !== badge.id))
              } else {
                setBadgeIds([...badgeIds, badge.id])
              }
            }

            return (
              <List.Item key={badge.id} className="group">
                <Link
                  onClick={onClick}
                  href="#"
                  className={clsx(
                    'flex-1 border border-line-subdued rounded-base p-2 px-3 mb-2 bg-surface hover:bg-surface-hovered flex space-s-2 items-center',
                  )}
                >
                  <div className="pointer-events-none cursor-pointer">
                    <Checkbox checked={!!isAssigned} />
                  </div>
                  <span className="flex-grow">{badge.name}</span>
                  <MemberBadge badge={badge} />
                </Link>
              </List.Item>
            )
          })}
          {!assignableBadges?.length && (
            <List.Item className="pb-3">
              <p>
                <T
                  defaultMessage="No active badge is available to assign."
                  id="Badges.NoActives"
                />
              </p>
              <p>
                <T
                  defaultMessage="Please create or activate a badge in your community."
                  id="Badges.NoActives.CTA"
                />
              </p>
            </List.Item>
          )}
        </List>
      </Modal.Content>
      <Modal.Footer className="space-s-2 justify-end flex">
        <Button variant="secondaryNeutral" onClick={onClose}>
          <T defaultMessage="Cancel" id="Generics.Cancel" />
        </Button>
        {assignableBadges?.length ? (
          <Button variant="primary" onClick={onSubmit} loading={isLoading}>
            <T defaultMessage="Update badges" id="Generics.UpdateBadges" />
          </Button>
        ) : (
          <Button
            variant="primary"
            as={Link}
            href="/manage/people/badges"
            loading={isLoading}
          >
            <T defaultMessage="Manage badges" id="Generics.ManageBadges" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
