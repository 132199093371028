import { Suspense, lazy, useState } from 'react'

import { generateNewChatPath } from '@bettermode/common/utils'
import { T } from '@tribeplatform/react-components/i18n'
import { NotificationDropdownLoading } from '@tribeplatform/react-components/Notification'
import { useRouter } from '@tribeplatform/react-sdk'
import { useUnreadChatsCount } from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import {
  IndicatorBadge,
  IndicatorBadgeWrapper,
} from '@tribeplatform/react-ui-kit/IndicatorBadge'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

const MessagingDropdownList = lazy(() =>
  import('@tribeplatform/react-components/Messaging').then(m => ({
    default: m.MessagingDropdownList,
  })),
)

interface MessagesButtonProps {
  label: string
}
export const MessagesButton = ({ label }: MessagesButtonProps) => {
  const { Link } = useRouter()
  const [popoverActive, setPopoverActive] = useState(false)

  const { data: unreadChatsCount } = useUnreadChatsCount()
  const unreadMessagesCount = unreadChatsCount?.active.unreadChatsCount ?? 0

  return (
    <div className="h-full flex justify-center items-center shrink-0">
      <Popover
        placement="bottom-end"
        open={popoverActive}
        onClose={() => setPopoverActive(false)}
        activator={
          <IndicatorBadgeWrapper
            indicatorBadge={
              <IndicatorBadge count={unreadMessagesCount} size="sm" />
            }
          >
            <TopBarButton
              onClick={() => setPopoverActive(prev => !prev)}
              icon={<SvgIcon name="message" />}
              aria-label={label}
            />
          </IndicatorBadgeWrapper>
        }
        fluidContent={false}
        fixed
      >
        <div className="flex flex-col">
          <div className="border-b border-line-subdued px-3 py-2 flex items-center gap-2">
            <Link
              className="flex-grow text-content-subdued font-semibold hover:text-content-hovered"
              href="/messaging"
              onClick={() => setPopoverActive(false)}
            >
              <T defaultMessage="Messages" id="Generics.Messages" />
            </Link>
            <IconButton
              as={Link}
              href={generateNewChatPath()}
              icon={<SvgIcon name="plus" className="text-content-subdued" />}
              variant="secondaryNeutral"
              size="lg"
              className="shrink-0"
            />
          </div>
          <div className="grow">
            <Suspense fallback={<NotificationDropdownLoading />}>
              <MessagingDropdownList
                closeDropdown={() => setPopoverActive(false)}
              />
            </Suspense>
          </div>
          <div className="border-t border-line-subdued flex align-middle items-center space-s-3">
            <Link
              className="flex-grow p-3 text-content-subdued text-center text-sm hover:text-content-hovered"
              href="/messaging"
              onClick={() => setPopoverActive(false)}
            >
              <T
                defaultMessage="See all messages"
                id="Generics.SeeAllMessages"
              />
            </Link>
          </div>
        </div>
      </Popover>
    </div>
  )
}
