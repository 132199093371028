import { clsx } from 'clsx'

import { Image } from '@tribeplatform/gql-client/types'
import { useTribeClient } from '@tribeplatform/react-sdk'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { iconSizeStyles, iconStyles } from '@tribeplatform/react-ui-kit/Icon'
import { useColorSchemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { Image as ImageComponent } from '../common/components/Image.js'

interface Props {
  image?: Image
  size?: IconSize
}
export const SquareLogo = ({ image: imageProp, size = '2x' }: Props) => {
  const {
    config: { getAssetPath },
  } = useTribeClient()
  const { data: authToken } = useAuthToken()
  const { networkPublicInfo } = authToken || {}
  const { colorSchemeMode } = useColorSchemeContext()
  const { images } = networkPublicInfo || {}
  const fallbackLogo = images?.lightMobileLogo ?? networkPublicInfo?.favicon
  const lightImage =
    imageProp ?? ((images?.lightMobileLogo ?? fallbackLogo) as Image)
  const darkImage =
    imageProp ?? ((images?.darkMobileLogo ?? fallbackLogo) as Image)
  const lightImageUrl = lightImage?.urls?.large ?? lightImage?.url
  const darkImageUrl = darkImage?.urls?.large ?? darkImage?.url

  if (colorSchemeMode === null) {
    // on SSR we don't have access to the colorSchemeMode, that's why we need to render both logos and let browser decide which one to show
    return (
      <>
        <ImageComponent
          className={clsx(
            'inline-block dark:hidden object-contain square-logo-image',
            iconStyles({ size }),
          )}
          width={iconSizeStyles({ size })}
          height={iconSizeStyles({ size })}
          src={lightImageUrl}
          alt={networkPublicInfo.name}
        />
        <ImageComponent
          className={clsx(
            'hidden dark:inline-block object-contain square-logo-image',
            iconStyles({ size }),
          )}
          width={iconSizeStyles({ size })}
          height={iconSizeStyles({ size })}
          src={darkImageUrl}
          alt={networkPublicInfo.name}
        />
      </>
    )
  }

  const imageUrl = colorSchemeMode === 'dark' ? darkImageUrl : lightImageUrl

  return (
    <ImageComponent
      className={clsx(
        'inline-block object-contain square-logo-image',
        !imageUrl && 'rounded-base',
        iconStyles({ size }),
      )}
      width={iconSizeStyles({ size })}
      height={iconSizeStyles({ size })}
      src={imageUrl ?? getAssetPath('images/default-icon.svg')}
      alt={networkPublicInfo.name}
    />
  )
}
