import { useCallback } from 'react'

import { InfiniteData, useQueryClient } from '@tanstack/react-query'

import { useDebouncedCallback } from '@bettermode/common/hooks'
import { Chat, ChatList } from '@tribeplatform/gql-client/types'

import { useTribeClient } from '../../useTribeClient.js'
import { getChatsKey, getHasChatsKey } from '../../utils/keys/messaging.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'
import {
  ARCHIVED_CHATS_QUERY,
  CHATS_QUERY,
  useUnreadMessagesCount,
} from '../index.js'
import { useRefreshChats } from './useRefreshChats.js'

const RECONNECT_DEBOUNCE_TIME = 1000

interface MsgReceivedParams {
  pageId: string
  messageId: string
  addressSignature: string
  offset: number
  authorId: string
  chatId: string
  isArchived?: boolean
}

export const useHandleMessageReceived = () => {
  const { data: authMember } = useAuthMember()
  const { refreshChats } = useRefreshChats()
  const {
    incrementUnreadMessagesCount,
    maybeFetchUnreadMessagesCount,
    fetchUnreadMessagesCount,
  } = useUnreadMessagesCount()
  const queryClient = useQueryClient()
  const { client } = useTribeClient()

  const handleUnreadMessages = useCallback(
    ({ authorId, chatId, isArchived = false }: MsgReceivedParams) => {
      if (authorId !== authMember?.id) {
        incrementUnreadMessagesCount(chatId, isArchived)
      }
    },
    [authMember?.id, incrementUnreadMessagesCount],
  )

  const updateHasChats = useCallback(() => {
    const hasChats = queryClient.getQueryData<boolean>(getHasChatsKey())
    if (!hasChats) {
      queryClient.setQueryData(getHasChatsKey(), true)
    }
  }, [queryClient])

  const moveChatToTop = useCallback(
    async (chat: Chat, isArchived: boolean) => {
      const updater = (
        data?: InfiniteData<ChatList>,
      ): InfiniteData<ChatList> => {
        if (!data) {
          return data
        }

        const firstPage = data.pages.at(0)
        const otherPages = data.pages.slice(1)
        const firstPageDataWithoutChat = firstPage.data.filter(
          c => c._id !== chat._id,
        )
        const otherPagesDataWithoutChat = otherPages.map(page => ({
          ...page,
          data: page.data.filter(c => c._id !== chat._id),
        }))

        return {
          ...data,
          pages: [
            {
              ...firstPage,
              data: [chat, ...firstPageDataWithoutChat],
            },
            ...otherPagesDataWithoutChat,
          ],
        }
      }

      if (isArchived) {
        queryClient.setQueryData(
          getChatsKey({ variables: { ...ARCHIVED_CHATS_QUERY } }),
          updater,
        )
      } else {
        queryClient.setQueryData(
          getChatsKey({ variables: { ...CHATS_QUERY } }),
          updater,
        )
      }
    },
    [queryClient],
  )

  const reorderChatsList = useCallback(
    async (payload: MsgReceivedParams) => {
      const fetchedChat = await client.messaging.chat({ id: payload.chatId })
      moveChatToTop(fetchedChat, payload.isArchived)

      return [fetchedChat._id]
    },
    [moveChatToTop, client],
  )

  const handleMsgReceived = useCallback(
    async (payload: MsgReceivedParams) => {
      handleUnreadMessages(payload)
      await reorderChatsList(payload)

      if (payload.authorId !== authMember?.id) {
        await maybeFetchUnreadMessagesCount(
          [payload.chatId],
          payload.isArchived,
        )
        updateHasChats()
      }
    },
    [
      handleUnreadMessages,
      reorderChatsList,
      authMember?.id,
      maybeFetchUnreadMessagesCount,
      updateHasChats,
    ],
  )

  const handleReconnect = useDebouncedCallback(async () => {
    const [chatsIds, archivedChatsIds] = await Promise.all([
      refreshChats({ variables: { ...CHATS_QUERY } }),
      refreshChats({ variables: { ...ARCHIVED_CHATS_QUERY } }),
    ])
    fetchUnreadMessagesCount(chatsIds, false)
    fetchUnreadMessagesCount(archivedChatsIds, true)
  }, RECONNECT_DEBOUNCE_TIME)

  return { handleMsgReceived, handleReconnect }
}
