import { Suspense } from 'react'

import { ModerationEntityType } from '@tribeplatform/gql-client/types'

import {
  PostAnalyticsModal,
  PostAnalyticsModalProps,
} from '../../Analytics/PostAnalytics/PostAnalyticsModal.js'
import {
  AuditLogsModal,
  AuditLogsModalProps,
} from '../../AuditLog/AuditLogsModal.js'
import { MovePostModal, MovePostModalProps } from '../../Post/MovePostModal.js'
import { ReportModal, ReportModalProps } from '../../ReportModal/ReportModal.js'
import { useActionItems } from '../ActionItemsContext.js'

export const PostActionItemsModals = () => {
  const { modalKey, entityProps, closeModal } = useActionItems()

  return (
    <Suspense>
      {modalKey === 'auditLogsModal' && (
        <AuditLogsModal
          {...(entityProps as AuditLogsModalProps)}
          onClose={closeModal}
          open
        />
      )}
      {modalKey === 'movePostModal' && (
        <MovePostModal
          {...(entityProps as MovePostModalProps)}
          onClose={closeModal}
          open
        />
      )}
      {modalKey === 'postAnalyticsModal' && (
        <PostAnalyticsModal
          {...(entityProps as PostAnalyticsModalProps)}
          onClose={closeModal}
          linkToContent={false}
          open
        />
      )}
      {modalKey === 'reportModal' && (
        <ReportModal
          {...(entityProps as ReportModalProps)}
          entityType={ModerationEntityType.POST}
          onClose={closeModal}
          open
        />
      )}
    </Suspense>
  )
}
