import { SearchEntityFields, searchGQLQuery } from '../../graphql/index.js'
import { MutationFunctions } from '../../types/fields.generated.js'
import {
  MutationUpdateNetworkSearchSettingsArgs,
  SearchNetworkSettings,
  QuerySearchArgs,
  SearchResult,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class SearchClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async legacySearch(
    variables: QuerySearchArgs,
    fields: SearchEntityFields = 'basic',
    accessToken?: string,
  ): Promise<SearchResult> {
    type QueryResult = { search: SearchResult }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: searchGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.search
  }

  async updateNetworkSearchSettings(
    variables: MutationUpdateNetworkSearchSettingsArgs,
    accessToken?: string,
  ): Promise<SearchNetworkSettings> {
    type QueryResult = {
      updateNetworkSearchSettings: SearchNetworkSettings
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: MutationFunctions.updateNetworkSearchSettings('basic'),
      variables,
      customToken: accessToken,
    })
    return result.updateNetworkSearchSettings
  }
}
