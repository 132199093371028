import {
  SearchConfigInput,
  SearchFusionKind,
} from '@tribeplatform/gql-client/types'
import { PaginatedPostFields } from '@tribeplatform/gql-client/types/generated'
import { SearchFor } from '@tribeplatform/react-slate-kit/blocks'

import { SmartSearchEntityType } from './SmartSearch.types.js'

export const SMART_SEARCH_QUERY_LIMIT = 5

export const SMART_SEARCH_RESULTS_LIMIT: Record<SmartSearchEntityType, number> =
  {
    [SmartSearchEntityType.POSTS]: 5,
    [SmartSearchEntityType.SPACES]: 5,
    [SmartSearchEntityType.MEMBERS]: 5,
    [SmartSearchEntityType.EXTERNALS]: 5,
  }

export const SMART_SEARCH_RESULTS_ALL_TAB_LIMIT: Record<
  SmartSearchEntityType,
  number
> = {
  [SmartSearchEntityType.POSTS]: 3,
  [SmartSearchEntityType.SPACES]: 3,
  [SmartSearchEntityType.MEMBERS]: 3,
  [SmartSearchEntityType.EXTERNALS]: 3,
}

export const POSTS_FIELDS: PaginatedPostFields = {
  nodes: {
    images: 'all',
    owner: {
      member: {
        profilePicture: {
          onGlyph: 'all',
          onFile: 'all',
          onImage: {
            urls: 'all',
          },
        },
      },
    },
    space: {
      address: 'basic',
      image: {
        onImage: 'all',
        onGlyph: 'all',
      },
    },
  },
  pageInfo: 'all',
}

export const SMART_SEARCH_FILTERS_INITIAL_STATE = {
  query: '',
  type: null,
  tagsId: [],
  date: null,
  askAIQuery: '',
}

export const MAX_AI_PROMPT_LENGTH = 500
export const LENGTH_TO_SHOW_TITLE_IN_RECENT_SEARCH_ITEM = 20

export const SMART_SEARCH_RESULTS_FETCH_MORE_PARAM = 'fetchMore'

export const COMMON_SEARCH_CONFIG: SearchConfigInput = {
  fusionKind: SearchFusionKind.rrf,
  fusionScoreThreshold: 0.2,
  fullTextEnabled: true,
  vectorTextEnabled: true,
  vectorEmbedEnabled: true,
  vectorTextScoreThreshold: 0.45,
  vectorEmbedScoreThreshold: 0.45,
}

export const SEARCH_CONFIG: Partial<
  Record<SmartSearchEntityType, SearchConfigInput>
> = {
  [SmartSearchEntityType.POSTS]: COMMON_SEARCH_CONFIG,
  [SmartSearchEntityType.SPACES]: COMMON_SEARCH_CONFIG,
  [SmartSearchEntityType.MEMBERS]: {
    fusionKind: SearchFusionKind.rrf,
    fusionScoreThreshold: 0,
    fullTextEnabled: true,
    vectorTextEnabled: true,
    vectorEmbedEnabled: true,
    vectorTextScoreThreshold: 0.5,
    vectorEmbedScoreThreshold: 0.5,
  },
}

export const SMART_SEARCH_HEADER_INPUT_DEFAULT_HEIGHT = 36

export const BLUR_TIMEOUT = 100

export const LEGACY_SEARCH_ENTITY_TYPE_MAP: Record<
  SearchFor | '',
  SmartSearchEntityType | null
> = {
  [SearchFor.post]: SmartSearchEntityType.POSTS,
  [SearchFor.space]: SmartSearchEntityType.SPACES,
  [SearchFor.member]: SmartSearchEntityType.MEMBERS,
  [SearchFor.all]: null,
  '': null,
}

export const TRENDING_POSTS_FILTERS = {
  publishedAt: {
    daysFrom: 30,
  },
}
