import { useEffect, useState } from 'react'

import { clsx } from 'clsx'

import { COMMAND_BAR_HOTKEYS } from '@tribeplatform/react-components/CommandBar'
import { useI18n } from '@tribeplatform/react-components/i18n'

import { useSlate } from '../../../hooks/slate.hook.js'
import { SearchBarRenderer } from '../Components/SearchBarRenderer.js'
import { useSearchBar } from '../providers/SearchBarProvider.js'
import {
  ItemAlignment,
  type SearchSection as TSearchSection,
} from '../types.js'

interface Props extends TSearchSection {
  active: boolean
  fixedWidth?: boolean
}
export const SearchSection = ({
  active,
  stretch,
  alignment,
  fixedWidth,
}: Props) => {
  const { $t } = useI18n()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const {
    settings: { searchFor, placeholder, searchSource, spaceIds },
  } = useSearchBar()

  const baseSearchPlaceholder = $t({
    id: 'SmartSearch.Search.Placeholder',
    defaultMessage: 'Search or ask a question...',
  })

  const [defaultPlaceholder, setDefaultPlaceholder] = useState(
    baseSearchPlaceholder,
  )

  useEffect(() => {
    setDefaultPlaceholder(
      `${baseSearchPlaceholder} (${COMMAND_BAR_HOTKEYS.SEARCH.label})`.replace(
        '...',
        '',
      ),
    )
  }, [baseSearchPlaceholder])

  return (
    <div
      className={clsx(
        'h-full lg:items-center',
        fixedWidth && 'w-1/2',
        stretch ? 'flex' : 'hidden lg:flex',
        isPreviewMode && 'px-1',
        active &&
          'rounded-base ring ring-customizer-blue ring-inset ring-offset-0',
        stretch && 'flex-1',
        !stretch && !fixedWidth && 'shrink-0',
        stretch && [
          alignment === ItemAlignment.start && 'justify-start',
          alignment === ItemAlignment.center && 'justify-center',
          alignment === ItemAlignment.end && 'justify-end',
        ],
      )}
    >
      <div
        className={clsx(
          'max-w-[40rem]',
          !fixedWidth && 'w-full',
          stretch && 'hidden lg:block',
        )}
      >
        <SearchBarRenderer
          searchFor={searchFor}
          placeholder={placeholder || defaultPlaceholder}
          searchSource={searchSource}
          spaceIds={spaceIds}
        />
      </div>
    </div>
  )
}
