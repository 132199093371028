import { clsx } from 'clsx'

import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { useColorSchemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

export const SearchEngineResultPreviewCard = ({ title, description }) => {
  const { data: network } = useNetwork()
  const { domain } = network || {}
  const domainWithProtocol = `https://${domain}/`

  const { colorSchemeMode } = useColorSchemeContext()

  return (
    <div className="text-base font-sans cursor-default">
      <div
        className={clsx(
          'text-lg truncate',
          colorSchemeMode === 'dark'
            ? 'text-[#99c3ff]'
            : 'text-[rgb(26,13,171)]',
        )}
      >
        {title}
      </div>
      <div className="text-sm text-[rgb(0,102,33)]">{domainWithProtocol}</div>
      <div className="text-sm break-words mt-1">{description}</div>
    </div>
  )
}
