import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useRef,
  useCallback,
  MutableRefObject,
  useState,
} from 'react'

import { Slide } from './@types.js'

type MediaModalContextType = {
  /**
   * Using reference type for context value if slides list  prevent premature re-rendering when compiling the meta data
   */
  slidesRef: MutableRefObject<Slide[]>
  pushSlide: (newSlide: Slide | undefined) => number | undefined
  initialSlideIndex: number
  isVisible: boolean
  allowDownload: boolean
  closeMediaModal: () => void
  openSlideWithIndex: (slideIndex?: number) => void
  removeSlide: (slideId: string) => void
  title: ReactNode
}

const MediaModalContext = createContext<MediaModalContextType>(null)

export const useMediaModal = (): MediaModalContextType => {
  const ctx = useContext(MediaModalContext)
  const slidesRef = useRef<Slide[]>([])
  if (ctx === null) {
    return {
      slidesRef,
      pushSlide: _ => undefined,
      initialSlideIndex: 0,
      isVisible: false,
      allowDownload: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      closeMediaModal: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      openSlideWithIndex: () => {},
      removeSlide: () => {},
      title: null,
    }
  }
  return ctx
}

type MediaModalProviderProps = {
  title: ReactNode
  children: ReactNode
  allowDownload?: boolean
}

export const MediaModalProvider = ({
  title,
  children,
  allowDownload,
}: MediaModalProviderProps) => {
  const slidesRef = useRef<Slide[]>([])
  const pushSlide = useCallback(
    (newSlide: Slide | undefined): number | undefined => {
      if (newSlide === undefined) {
        return undefined
      }
      const currentIndex = slidesRef.current.findIndex(
        slide => slide.id === newSlide.id,
      )
      if (currentIndex > -1) {
        // Preserve media index between re-renders
        return currentIndex
      }
      slidesRef.current = [...slidesRef.current, newSlide]
      return slidesRef.current.length - 1
    },
    [],
  )
  const removeSlide = useCallback((slideId: string) => {
    slidesRef.current = slidesRef.current.filter(slide => slide.id !== slideId)
  }, [])

  const [isVisible, setVisibility] = useState(false)
  const [initialSlideIndex, setInitialSlideIndex] = useState(0)
  const openSlideWithIndex = useCallback((slideIndex = 0) => {
    setInitialSlideIndex(slideIndex)
    setVisibility(true)
  }, [])

  const closeMediaModal = useCallback(() => {
    setVisibility(false)
  }, [])

  const context: MediaModalContextType = useMemo(
    () => ({
      slidesRef,
      pushSlide,
      isVisible,
      allowDownload,
      initialSlideIndex,
      openSlideWithIndex,
      closeMediaModal,
      removeSlide,
      title,
    }),
    [
      slidesRef,
      pushSlide,
      isVisible,
      allowDownload,
      initialSlideIndex,
      openSlideWithIndex,
      closeMediaModal,
      title,
      removeSlide,
    ],
  )

  return (
    <MediaModalContext.Provider value={context}>
      {children}
    </MediaModalContext.Provider>
  )
}
