import { QueryChatParticipantsArgs } from '@bettermode/common/gql/generated'
import type { SpaceFields } from '@tribeplatform/gql-client/graphql'
import {
  QueryChatArgs,
  type QueryPrivateMessageArgs,
} from '@tribeplatform/gql-client/types'
import type {
  QueryChatIdFromSlugArgs,
  QueryChatParticipantsByIdsArgs,
  QueryChatsArgs,
  QueryCheckMembersContactabilityArgs,
  QueryFindChatWithMemberArgs,
  QueryMessagesArgs,
  UnreadMessagesCount,
} from '@tribeplatform/gql-client/types'
import {
  ChatFields,
  ChatListFields,
  ChatParticipantListFields,
  MessageListFields,
} from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const CHAT_KEY = 'chat'
export const getChatKey = KeyConstructor<QueryChatArgs, ChatFields>(CHAT_KEY)

const HAS_CHATS_KEY = 'hasChats'
export const getHasChatsKey = KeyConstructor(HAS_CHATS_KEY)

const CHAT_WITH_MEMBER_KEY = 'chatWithMember'
export const getChatWithMemberKey = KeyConstructor<
  QueryFindChatWithMemberArgs,
  ChatFields
>(CHAT_WITH_MEMBER_KEY)

const CHECK_MEMBERS_CONTACTABILITY_KEY = 'checkMembersContactability'
export const getCheckMembersContactabilityKey = KeyConstructor<
  QueryCheckMembersContactabilityArgs,
  ChatFields
>(CHECK_MEMBERS_CONTACTABILITY_KEY)

const CHATS_KEY = 'chats'
export const getChatsKey = KeyConstructor<QueryChatsArgs, ChatListFields>(
  CHATS_KEY,
)

const CHATS_READ_OFFSETS_KEY = 'chatsReadOffsets'
export const getChatsReadOffsetsKey = KeyConstructor(CHATS_READ_OFFSETS_KEY)

const UNREAD_MESSAGES_COUNT_KEY = 'unreadMessagesCount'
export const getUnreadMessagesCountKey = KeyConstructor<
  UnreadMessagesCount['chatId']
>(UNREAD_MESSAGES_COUNT_KEY)

const CACHED_UNREAD_CHATS_COUNT_KEY = 'cachedUnreadChatsCount'
export const getCachedUnreadChatsCountKey = KeyConstructor(
  CACHED_UNREAD_CHATS_COUNT_KEY,
)

const MESSAGES_KEY = 'messages'
export const getMessagesKey = KeyConstructor<
  QueryMessagesArgs,
  MessageListFields
>(MESSAGES_KEY)

const PENDING_MESSAGES_KEY = 'pendingMessages'
export const getPendingMessagesKey = KeyConstructor<QueryChatArgs, ChatFields>(
  PENDING_MESSAGES_KEY,
)

const UNREAD_CHATS_COUNT_KEY = 'unreadChatsCount'
export const getUnreadChatsCountKey = KeyConstructor(UNREAD_CHATS_COUNT_KEY)

const ARCHIVED_UNREAD_CHATS_COUNT_KEY = 'archivedUnreadChatsCount'
export const getArchivedUnreadChatsCountKey = KeyConstructor(
  ARCHIVED_UNREAD_CHATS_COUNT_KEY,
)

const CHAT_PARTICIPANTS_KEY = 'chatParticipants'
export const getChatParticipantsKey = KeyConstructor<
  QueryChatParticipantsArgs,
  ChatParticipantListFields
>(CHAT_PARTICIPANTS_KEY)

export const CHAT_PARTICIPANTS_BY_ID_KEY = 'chatParticipantsById'
export const getChatParticipantsByIdKey = KeyConstructor<
  QueryChatParticipantsByIdsArgs,
  ChatParticipantListFields
>(CHAT_PARTICIPANTS_BY_ID_KEY)

export const CACHED_CHAT_PARTICIPANTS_KEY = 'cachedChatParticipants'
export const getCachedChatParticipantsKey = KeyConstructor<
  QueryChatParticipantsArgs,
  ChatParticipantListFields
>(CACHED_CHAT_PARTICIPANTS_KEY)

export const CHAT_ID_FROM_SLUG_KEY = 'chatIdFromSlug'
export const getChatIdFromSlugKey = KeyConstructor<
  QueryChatIdFromSlugArgs,
  ChatFields
>(CHAT_ID_FROM_SLUG_KEY)

/**
 * @deprecated
 */
const PRIVATE_MESSAGE_KEY = 'privateMessage'
/**
 * @deprecated
 */
export const getPrivateMessageKey = KeyConstructor<
  QueryPrivateMessageArgs,
  SpaceFields
>(PRIVATE_MESSAGE_KEY)
