import React from 'react'

import { useActionItems } from '../ActionItemsContext.js'
import { MemberActionItemsModals } from './MemberActionItemsModals.js'
import { PostActionItemsModals } from './PostActionItemsModals.js'
import { SpaceActionItemsModals } from './SpaceActionItemsModals.js'

export const ActionItemsModals = () => {
  const { entityName } = useActionItems()

  switch (entityName) {
    case 'space':
      return <SpaceActionItemsModals />
    case 'post':
      return <PostActionItemsModals />
    case 'member':
      return <MemberActionItemsModals />
    default:
      return null
  }
}
