export const getSearchForQuery = (entityType: string): string => {
  if (!entityType) {
    return ''
  }

  return ` +for:${entityType}`
}
export const getSearchInQuery = (spaceIds: string[]): string => {
  if (!spaceIds?.length) {
    return ''
  }

  if (spaceIds.length === 1) {
    const [spaceId] = spaceIds
    return ` +in:${spaceId}`
  }

  const spaceIdsJoined = spaceIds.join(',')
  return ` +in~${spaceIdsJoined}`
}
