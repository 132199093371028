import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'

export const AskAISettingsSkeleton = () => {
  return (
    <Card.Content>
      <div className="flex flex-col gap-y-3 animate-pulse">
        <Card className="bg-surface-subdued">
          <Card.Content>
            <div className="flex items-center gap-x-3 justify-between">
              <div className="rounded-base bg-skeleton h-[4rem] w-[4rem]" />
              <div className="flex flex-col gap-y-2 flex-grow">
                <h3 className="text-heading-xs font-medium text-content">
                  <div className="h-8 bg-skeleton rounded-base w-1/2" />
                </h3>
              </div>
              <div className="flex items-center gap-x-2">
                <Button variant="primary">
                  <div className="h-[1rem] bg-transparent rounded-base w-16" />
                </Button>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    </Card.Content>
  )
}
