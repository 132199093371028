import { useState } from 'react'

import { Tag } from '@tribeplatform/gql-client/types'
import { useNetwork, useSpace } from '@tribeplatform/react-sdk/hooks'
import { Divider } from '@tribeplatform/react-ui-kit/Divider'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InputCopy } from '@tribeplatform/react-ui-kit/Input'
import { Slider } from '@tribeplatform/react-ui-kit/Slider'
import { Toggle } from '@tribeplatform/react-ui-kit/Toggle'

import { ErrorBoundary } from '../common/components/ErrorBoundary/ErrorBoundary.js'
import { useDomain } from '../common/hooks/useDomain.js'
import { T } from '../i18n/components/T.js'
import { SpaceTagSelector } from '../Space/SpaceTagSelector.js'

type IframeSrcParams = {
  readonly: true
  header: true
  composer: true
  tag?: string
  // eslint-disable-next-line camelcase
  tag_id?: string
}
const toQueryString = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&')

const getIframe = (iframeSrc, { height, title }) => {
  return `<iframe frameborder="no" src="${iframeSrc}" height="${height}px" width="100%" title="${title}" />`
}

export const SpaceEmbedCreator = ({ spaceId }) => {
  const [iframeHeight, setIframeHeight] = useState(360)
  const [iframeSrcParams, setIframeSrcParams] = useState<IframeSrcParams>({
    readonly: true,
    header: true,
    composer: true,
  })
  const [tag, setTag] = useState<Tag>()

  const { getResourceUrl } = useDomain()
  const { isFetching: fetchingNetwork } = useNetwork()
  const { data: space, isFetching: fetchingSpace } = useSpace({
    variables: { id: spaceId },
  })

  const loading = fetchingNetwork || fetchingSpace
  const baseUrl = getResourceUrl(`/embed/space/${space?.slug}`)
  const iframeSrc = `${baseUrl}?${toQueryString(iframeSrcParams)}`
  const set = (key, value) => {
    setIframeSrcParams({
      ...iframeSrcParams,
      [key]: value,
    })
  }
  const unset = (key: keyof IframeSrcParams) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { [key]: _deletedKey, ...rest } = iframeSrcParams
    setIframeSrcParams(rest as IframeSrcParams)
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-96">
        <SvgIcon className="animate-spin absolute h-9 w-9" name="spinner" />
      </div>
    )
  }

  const iframeToCopy = getIframe(iframeSrc, {
    height: iframeHeight,
    title: space?.name,
  })
  return (
    <>
      <InputCopy value={iframeToCopy} readOnly />
      <Divider />
      <div className="mb-3 space-y-5">
        <div className="flex justify-between items-center">
          <h5 className="text-sm font-medium">
            <T
              defaultMessage="Show header"
              id="ShareModal.SpaceEmbedCreator.ShowHeader"
            />
          </h5>
          <Toggle
            checked={iframeSrcParams.header}
            onChange={() => {
              set('header', !iframeSrcParams.header)
            }}
          />
        </div>
        <div className="flex justify-between items-center">
          <h5 className="text-sm font-medium">
            <T
              defaultMessage="Show composer"
              id="ShareModal.SpaceEmbedCreator.ShowComposer"
            />
          </h5>
          <Toggle
            checked={iframeSrcParams.composer}
            onChange={() => {
              set('composer', !iframeSrcParams.composer)
            }}
          />
        </div>
        <div className="flex justify-between items-center">
          <h5 className="text-sm font-medium">
            <T defaultMessage="Read-only" id="Generics.ReadOnly" />
          </h5>
          <Toggle
            checked={iframeSrcParams.readonly}
            onChange={() => {
              set('readonly', !iframeSrcParams.readonly)
            }}
          />
        </div>
        <div className="flex justify-between items-center">
          <h5 className="text-sm font-medium">
            <T defaultMessage="{TAG_CC}" id="Generics.Tag" />
          </h5>
          <div className="w-52">
            <SpaceTagSelector
              spaceId={space?.id}
              selectedTag={tag}
              onSelect={tag => {
                setTag(tag)
                if (!tag) {
                  unset('tag')
                  unset('tag_id')
                } else {
                  set('tag', tag.slug)
                }
              }}
            />
          </div>
        </div>
      </div>
      <h5 className="text-sm font-medium">
        <T
          defaultMessage="Embed height: {iframe_height}px"
          id="Share.EmbedHeight"
          values={{
            iframe_height: iframeHeight,
          }}
        />
      </h5>
      <div className="mb-2">
        <Slider
          value={iframeHeight}
          step={10}
          min={200}
          max={1000}
          onChange={setIframeHeight}
        />
      </div>
      <ErrorBoundary>
        <T defaultMessage="Embed preview" id="Share.EmbedPreview" />
        <h5 className="text-sm font-medium mb-1"></h5>
        <div className="p-3 bg-skeleton overflow-auto h-96 rounded-base">
          <iframe
            src={iframeSrc}
            height={`${iframeHeight}px`}
            width="100%"
            title={space?.name}
          />
        </div>
      </ErrorBoundary>
    </>
  )
}
