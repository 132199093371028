import { clsx } from 'clsx'
import { useFormContext } from 'react-hook-form'

import {
  MAX_SEO_DESCRIPTION_LENGTH,
  MAX_SEO_DESCRIPTION_LENGTH_SUGGESTION,
  MAX_SEO_TITLE_LENGTH,
  MIN_SEO_DESCRIPTION_LENGTH_SUGGESTION,
} from '@bettermode/common/meta-tags'
import { NetworkVisibility, type Space } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import {
  RECOMMENDED_HEIGHT,
  RECOMMENDED_WIDTH,
  SeoImagePicker,
} from '../common/components/SeoImagePicker/index.js'
import { Form } from '../Form/index.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { SitemapWarnings } from './Components/SitemapWarnings.js'

interface Props {
  space: Space
}
export const PostSeoOptions = ({ space }: Props) => {
  const { $t } = useI18n()
  const { data: network } = useNetwork()
  const isPrivateNetwork = network?.visibility === NetworkVisibility.private
  const isNetworkNoIndex = !!network?.customSeoDetail?.noIndex
  const isPrivateSpace = !!space?.private
  const isSpaceNoindex = !!space?.customSeoDetail?.noIndex
  const { watch, setValue } = useFormContext()
  const title = watch('customSeoDetail.title')
  const description = watch('customSeoDetail.description')
  const seoImage = watch('customSeoDetail.thumbnail')

  return (
    <div className="space-y-6 mt-3 text-content-subdued">
      <div>
        <h4 className="text-lg font-medium">
          <T
            defaultMessage="Search engine optimization"
            id="Admin.SeoSettings.Seo"
          />
        </h4>
        <p className="text-sm mt-2">
          <T
            id="Admin.SeoSettings.Description"
            defaultMessage="Enhance visibility by customizing SEO settings, including meta titles, descriptions, and Open Graph image for better search and social media presence."
          />
        </p>
      </div>
      <Form.Input
        name="slug"
        label={$t({
          id: 'Generics.Slug',
          defaultMessage: 'Slug',
        })}
        helperText={$t({
          id: 'Post.PostSeoOptions.SlugHelperText',
          defaultMessage:
            'SEO-friendly web address. The slug includes a unique post ID. Changing it creates a redirect.',
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.SlugPlaceholder',
          defaultMessage:
            'If empty, we will autogenerate this based on post title',
        })}
      />
      <Form.Input
        name="customSeoDetail.title"
        label={$t({
          id: 'Admin.SeoSettings.MetaTitle',
          defaultMessage: 'Meta title',
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.MetaTitlePlaceholder',
          defaultMessage:
            'Add a meta title for this post. If blank, the post title will be used by default',
        })}
        helperText={
          <div className="grid grid-cols-6">
            <span className="col-span-5">
              <T
                defaultMessage="Keep your title concise and under {length, number} characters for best search engine visibility."
                id="Generics.RecommendedLength"
                values={{
                  length: MAX_SEO_TITLE_LENGTH,
                }}
              />
            </span>
            <span
              className={clsx(
                'text-bold col-span-1 flex justify-end',
                // eslint-disable-next-line no-nested-ternary
                title
                  ? title.length > MAX_SEO_TITLE_LENGTH
                    ? 'text-content-on-attention'
                    : 'text-content-on-positive'
                  : '',
              )}
            >
              <T
                id="Post.PostSeoOptions.LengthIndicator"
                defaultMessage="Length: {length}"
                values={{ length: title?.length || 0 }}
              />
            </span>
          </div>
        }
      />
      <Form.Textarea
        name="customSeoDetail.description"
        label={$t({
          id: 'Admin.SeoSettings.MetaDescription',
          defaultMessage: 'Meta description',
        })}
        placeholder={$t(
          {
            id: 'Post.PostSeoOptions.MetaDescriptionPlaceholder',
            defaultMessage:
              'Add a meta description for this post. If left blank, the first {chars, number} characters will be used by default',
          },
          {
            chars: MAX_SEO_DESCRIPTION_LENGTH,
          },
        )}
        helperText={
          <div className="grid grid-cols-6">
            <span className="col-span-5">
              <T
                defaultMessage="Write a description of {min_length, number} - {max_length, number} characters to give search engines a clear summary of your content."
                id="Generics.RecommendedLengthRange"
                values={{
                  min_length: MIN_SEO_DESCRIPTION_LENGTH_SUGGESTION,
                  max_length: MAX_SEO_DESCRIPTION_LENGTH_SUGGESTION,
                }}
              />
            </span>
            <span
              className={clsx(
                'text-bold col-span-1 flex justify-end',
                // eslint-disable-next-line no-nested-ternary
                description
                  ? description.length >
                      MAX_SEO_DESCRIPTION_LENGTH_SUGGESTION ||
                    description.length < MIN_SEO_DESCRIPTION_LENGTH_SUGGESTION
                    ? 'text-content-on-attention'
                    : 'text-content-on-positive'
                  : '',
              )}
            >
              <T
                id="Post.PostSeoOptions.LengthIndicator"
                defaultMessage="Length: {length}"
                values={{ length: description?.length || 0 }}
              />
            </span>
          </div>
        }
      />
      <Form.Input
        name="customSeoDetail.canonicalUrl"
        type="url"
        label={$t({
          id: 'Post.PostSeoOptions.CanonicalUrl',
          defaultMessage: 'Canonical URL',
        })}
        helperText={$t({
          id: 'Post.PostSeoOptions.CanonicalUrlHelperText',
          defaultMessage:
            'Specify the canonical URL for this post. Enter the original post’s URL to avoid duplicate content issues.',
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.CanonicalUrlPlaceholder',
          defaultMessage: 'Defaults to the post’s URL if left blank',
        })}
      />
      <div>
        <SeoImagePicker
          image={seoImage}
          onChange={image => setValue('customSeoDetail.thumbnail', image)}
          label={
            <h4 className="text-lg font-medium">
              <T
                id="Admin.Space.SeoSettings.OpenGraphImage"
                defaultMessage="Open Graph Image"
              />
            </h4>
          }
        />
        <p className="text-sm mt-2">
          <T
            id="Admin.SeoSettings.OpenGraphImage.Description"
            defaultMessage="Choose a banner image for SEO cards and social media previews ({width} x {height} px recommended)."
            values={{
              width: RECOMMENDED_WIDTH,
              height: RECOMMENDED_HEIGHT,
            }}
          />
        </p>
      </div>
      <div className="flex flex-col space-y-3">
        <Form.Toggle
          label={$t({
            id: 'Post.PostSeoOptions.NoIndexToggleLabel',
            defaultMessage: 'Hide from search results',
          })}
          helperText={$t({
            defaultMessage:
              'Enable to prevent search engines from indexing this post and exclude it from the sitemap.',
            id: 'Admin.Post.SeoSettings.HideSeo.Description',
          })}
          disabled={
            isPrivateNetwork ||
            isPrivateSpace ||
            isNetworkNoIndex ||
            isSpaceNoindex
          }
          name="customSeoDetail.noIndex"
        />
        <SitemapWarnings
          isPrivateNetwork={isPrivateNetwork}
          isPrivateSpace={isPrivateSpace}
          isNetworkNoIndex={isNetworkNoIndex}
          isSpaceNoindex={isSpaceNoindex}
        />
      </div>
    </div>
  )
}
