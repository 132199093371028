export type EmbedFields = 'basic' | 'all'

export function embedGQLFields(): string {
  return `
    author
    author_url
    description
    html
    id
    provider_name
    thumbnail_height
    thumbnail_url
    thumbnail_width
    title
    type
    url
  `
}
