import { lazy, Suspense } from 'react'

import { Member } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { FallbackLoading } from '../../common/components/FallbackLoading.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { TimeFrame } from '../types/index.js'

const MemberAnalytics = lazy(() =>
  import('./MemberAnalytics.js').then(m => ({ default: m.MemberAnalytics })),
)

export interface MemberAnalyticsModalProps {
  open: boolean
  onClose: () => void
  member: Member
  timeFrame?: TimeFrame
  linkToContent?: boolean
}

export const MemberAnalyticsModal = ({
  open,
  onClose,
  member,
  timeFrame,
  linkToContent = true,
}: MemberAnalyticsModalProps) => {
  const { Link } = useRouter()
  const { $t } = useI18n()

  return (
    <Modal open={open} onClose={onClose} size="2xl">
      <div className="bg-background">
        <Modal.Header
          title={$t({
            id: 'Analytics.MemberAnalyticsModal.HeaderTitle',
            defaultMessage: 'Member analytics',
            description: 'Header title for member analytics modal',
          })}
          description={
            <>
              {linkToContent ? (
                <Link
                  href={member?.relativeUrl}
                  variant="accent"
                  target="_blank"
                  translate="no"
                >
                  {member?.name ?? ''}
                </Link>
              ) : (
                <div translate="no">{member?.name ?? ''}</div>
              )}
            </>
          }
        />
        <Modal.Content>
          <Suspense fallback={<FallbackLoading />}>
            <MemberAnalytics member={member} timeFrame={timeFrame} />
          </Suspense>
        </Modal.Content>
      </div>
    </Modal>
  )
}
