import { createContext, useEffect } from 'react'

import { useHandleMessageReceived } from '@tribeplatform/react-sdk/hooks'

import { MsgReceivedEvent, ReconnectEvent } from '../Socket/constants.js'
import { useSocket } from '../Socket/SocketProvider.js'

const ChatMessageContext = createContext(null)

export const ChatMessagesProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { socket } = useSocket()
  const { handleMsgReceived, handleReconnect } = useHandleMessageReceived()

  useEffect(() => {
    socket.on(MsgReceivedEvent, handleMsgReceived)
    socket.io.on(ReconnectEvent, handleReconnect)
    return () => {
      socket.off(MsgReceivedEvent, handleMsgReceived)
      socket.off(ReconnectEvent, handleReconnect)
    }
  }, [handleMsgReceived, handleReconnect, socket])

  return (
    <ChatMessageContext.Provider value={null}>
      {children}
    </ChatMessageContext.Provider>
  )
}
