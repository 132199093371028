import { Link as RouterLink } from 'react-router-dom'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  NetworkVisibility,
  Space,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import { useRouterPath } from '@tribeplatform/react-sdk'
import {
  useAuthMember,
  useAuthToken,
  useLeaveSpace,
} from '@tribeplatform/react-sdk/hooks'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useActionItems } from '../ActionItems/ActionItemsContext.js'
import { T } from '../i18n/components/T.js'

interface Props {
  space: Space
  hideHotKeys?: boolean
}

export const SpaceActionItems = ({ space, hideHotKeys = false }: Props) => {
  const { openModal } = useActionItems()

  const { mutate: leave } = useLeaveSpace()

  const joined =
    space?.authMemberProps?.membershipStatus === SpaceMembershipStatus.joined

  const {
    data: { network },
  } = useAuthToken()
  const [canUpdateNetwork] = hasScopesPermission(network, [
    'updateNetwork',
    'createTemplate',
  ])
  const [canUpdateSpace, canAddMembers] = hasScopesPermission(space, [
    'updateSpace',
    'addSpaceMembers',
  ])
  const { isAdmin } = useAuthMember()

  const pathname = useRouterPath()
  const spacePath = space?.relativeUrl
  const isSpacePage = pathname === spacePath

  return (
    <>
      <Dropdown.Items>
        {!isSpacePage && (
          <Dropdown.Item
            as={RouterLink}
            to={spacePath}
            leadingIcon={<SvgIcon name="grid" />}
          >
            <T defaultMessage="View {SPACE}" id="Space.Actions.View" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="settings" />}
            onClick={() => openModal('space', 'spaceSettingsModal', { space })}
          >
            <T defaultMessage="Settings" id="Generics.Settings" />
          </Dropdown.Item>
        )}
        {canAddMembers && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="member-add" />}
            onClick={() => openModal('space', 'addMembersModal', { space })}
          >
            <T defaultMessage="Add {MEMBERS}" id="Space.Actions.AddMembers" />
          </Dropdown.Item>
        )}
        {canAddMembers && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="members" />}
            onClick={() => openModal('space', 'spaceMembersModal', { space })}
          >
            <T defaultMessage="View {MEMBERS}" id="Space.Actions.ViewMembers" />
          </Dropdown.Item>
        )}
        <Dropdown.Item
          leadingIcon={<SvgIcon name="share" />}
          onClick={() => {
            openModal('space', 'shareModal', {
              isPrivate:
                space?.hidden ||
                network.visibility === NetworkVisibility.private,
              spaceId: space?.id,
              title: space.name,
              url: space?.url,
            })
          }}
          hotkey={!hideHotKeys ? 'option+s,alt+s' : undefined}
        >
          <T defaultMessage="Share" id="Generics.Share" />
        </Dropdown.Item>
        {canUpdateSpace && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="moderation" />}
            as={RouterLink}
            to={`/settings/moderation/${space.id}/pending-posts`}
            hotkey={!hideHotKeys ? 'm' : undefined}
          >
            <T defaultMessage="Moderation" id="Generics.Moderation" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && isAdmin && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="analytics" />}
            onClick={() =>
              openModal('space', 'spaceAnalyticsModal', {
                space,
                linkToContent: false,
              })
            }
          >
            <T defaultMessage="Analytics" id="Generics.Analytics" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && canUpdateNetwork && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="audit-log" />}
            onClick={() =>
              openModal('space', 'auditLogsModal', { spaceId: space?.id })
            }
          >
            <T defaultMessage="Audit logs" id="Generics.AuditLogs" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && canUpdateNetwork && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="customizer" />}
            as={RouterLink}
            to={`/manage/customizer/spaces/${space.id}/blocks`}
            hotkey={!hideHotKeys ? 'c' : undefined}
          >
            <T defaultMessage="Customize" id="Generics.Customize" />
          </Dropdown.Item>
        )}
        {joined && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="logout" />}
            onClick={() => leave({ spaceId: space?.id })}
            hotkey={!hideHotKeys ? 'j' : undefined}
          >
            <T defaultMessage="Leave" id="Generics.Leave" />
          </Dropdown.Item>
        )}
      </Dropdown.Items>
    </>
  )
}
