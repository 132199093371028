import {
  IsTypingEvent,
  STALE_IS_TYPING_THRESHOLD,
  StoppedTypingEvent,
} from '../Socket/constants.js'
import {
  IsTypingReducerAction,
  RevalidateIsTypingStates,
  TIsTypingEntry,
  TIsTypingState,
} from './types.js'

export const initialIsTypingState: TIsTypingState = {}

export const isTypingStatusReducer = (
  state: typeof initialIsTypingState,
  action: IsTypingReducerAction,
): typeof initialIsTypingState => {
  switch (action.type) {
    case IsTypingEvent: {
      const {
        chatId,
        memberId: memberIdParam,
        memberName: memberNameParam,
      } = action.payload
      const currentList = state[chatId] ?? []
      const index = currentList.findIndex(
        ({ memberId }) => memberId === memberIdParam,
      )

      if (index > -1) {
        return {
          ...state,
          [chatId]: [
            ...currentList.slice(0, index),
            {
              memberId: memberIdParam,
              timestamp: Date.now(),
              memberName: memberNameParam,
            },
            ...currentList.slice(index + 1),
          ],
        }
      }

      return {
        ...state,
        [chatId]: [
          ...currentList,
          {
            memberId: memberIdParam,
            timestamp: Date.now(),
            memberName: memberNameParam,
          },
        ],
      }
    }

    case StoppedTypingEvent: {
      const { chatId, memberId: memberIdParam } = action.payload
      const currentList = state[chatId] ?? []
      const filteredList = currentList.filter(
        item => item.memberId !== memberIdParam,
      )

      return {
        ...state,
        [chatId]: filteredList,
      }
    }
    case RevalidateIsTypingStates: {
      const now = Date.now()

      const revalidatedStateTuples = Object.entries(state).map(
        ([key, value]) => {
          const filteredList = value.filter(({ timestamp }) => {
            const sinceLastIndicator = now - timestamp
            return sinceLastIndicator < STALE_IS_TYPING_THRESHOLD
          })
          const entry: [string, TIsTypingEntry[]] = [key, filteredList]
          return entry
        },
      )

      const cleanedState = Object.fromEntries(revalidatedStateTuples)
      return cleanedState
    }

    default:
      return state
  }
}
