import { Media } from '@tribeplatform/gql-client/types'
import {
  CommandBarMode,
  useCommandBar,
} from '@tribeplatform/react-components/CommandBar'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'

import { ActionButtonRenderer } from './ActionButtonRenderer.js'

interface Props {
  showText: boolean
  showIcon: boolean
  icon?: Media
  className?: string
}

export const SearchButton = ({
  showText,
  showIcon,
  icon,
  className,
}: Props) => {
  const { open } = useCommandBar()
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    open({ searchOptions: { mode: CommandBarMode.SEARCH } })
  }

  return (
    <ActionButtonRenderer
      showText={showText}
      showIcon={showIcon}
      variant="secondaryNeutral"
      className={className}
      icon={<EntityImage entity={{ image: icon }} size="xl" />}
      label={<T id="Generics.Search" defaultMessage="Search" />}
      tooltip={
        <T
          id="Blocks.SpaceHeader.SearchButton.Tooltip"
          defaultMessage="Search for posts, members, etc."
        />
      }
      onClick={handleClick}
    />
  )
}
