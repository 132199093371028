import { clsx } from 'clsx'

import {
  AddBlockPosition,
  SlateMode,
} from '@tribeplatform/react-slate-kit/types'

import {
  BlockActions,
  BlockActionsProps,
} from '../SlateEditor/BlockActions/index.js'
import { AddBlockButton } from './AddBlockButton.js'

interface Props extends BlockActionsProps {
  selected: boolean
  focused: boolean
  mode: SlateMode
  showAddBlockButtons?: boolean
  onAddBlock: (position: AddBlockPosition) => void
}

export const BlockOverlay = ({
  selected,
  focused,
  mode,
  onAddBlock,
  showAddBlockButtons = true,
  ...blockActionProps
}: Props) => {
  if (mode === 'read-only') {
    return <div className={clsx('absolute inset-0 z-10')} />
  }

  if (!focused && !selected) {
    return null
  }

  return (
    <>
      <div className="absolute rounded-base inset-0 ring-inset ring-offset-0 ring ring-customizer-blue z-10 h-full w-full">
        {showAddBlockButtons && (
          <div className="relative w-full h-full">
            <AddBlockButton
              position={AddBlockPosition.Before}
              onClick={() => {
                onAddBlock(AddBlockPosition.Before)
              }}
            />
            <AddBlockButton
              position={AddBlockPosition.After}
              onClick={() => {
                onAddBlock(AddBlockPosition.After)
              }}
            />
          </div>
        )}
      </div>

      <div className="absolute top-2 right-2 z-10">
        <BlockActions
          {...blockActionProps}
          size="md"
          className="p-2 rounded-base bg-background-selected shadow-md border border-line"
        />
      </div>
    </>
  )
}
