import { clsx } from 'clsx'

import {
  CommandBarMode,
  useCommandBar,
} from '@tribeplatform/react-components/CommandBar'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

import { useSearchBar } from '../providers/SearchBarProvider.js'

export const SearchButton = () => {
  const { $t } = useI18n()
  const { hasSearchSection } = useSearchBar()
  const { open } = useCommandBar()

  return (
    <div
      className={clsx(
        'h-full hidden justify-center items-center shrink-0',
        !hasSearchSection && 'lg:flex',
      )}
    >
      <TopBarButton
        onClick={() => open({ searchOptions: { mode: CommandBarMode.SEARCH } })}
        aria-label={$t({
          id: 'Generics.Search',
          defaultMessage: 'Search',
        })}
        icon={<SvgIcon name="search" />}
      />
    </div>
  )
}
