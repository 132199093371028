import type {
  Fields,
  MemberForSpaceInvitation,
  QuerySearchMembersForSpaceInvitationArgs,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSearchMembersForSpaceInvitation } from '../../utils/keys/member.key.js'

export const useSearchMembersForSpaceInvitation = (options: {
  variables: QuerySearchMembersForSpaceInvitationArgs
  fields?: Fields.MemberForSpaceInvitationFields
  useQueryOptions?: UseQueryOptions<MemberForSpaceInvitation[]>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const memberKey = getSearchMembersForSpaceInvitation({ variables, fields })

  return useQuery<MemberForSpaceInvitation[]>(
    memberKey,
    () =>
      client.query({
        name: 'searchMembersForSpaceInvitation',
        args: {
          fields,
          variables,
        },
      }),
    useQueryOptions,
  )
}
