import { useMemo } from 'react'

import { getExtraProperty } from '@tribeplatform/gql-client/lib'
import { NetworkStatus } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

export const ONBOARDING_SKIPPED_EXTRA_PROPERTY_KEY =
  'internal:onboarding:skipped'

export const useNavigateToAdministrationLink = () => {
  const { data: network } = useNetwork()

  const navigateToAdministrationLink = useMemo(() => {
    const isSkipped = !!getExtraProperty(
      network?.extraProperties,
      ONBOARDING_SKIPPED_EXTRA_PROPERTY_KEY,
    )
    const isPublished = network.status === NetworkStatus.published

    if (isSkipped || isPublished) {
      return '/manage/people/members'
    }

    return '/manage/onboarding'
  }, [network?.extraProperties, network.status])

  return { navigateToAdministrationLink }
}
