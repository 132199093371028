import type { ActionFields } from '@tribeplatform/gql-client/graphql'
import type {
  Action,
  MutationDeleteSpaceArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getCollectionsKey } from '../../utils/keys/collection.key.js'
import { getSpacesKey } from '../../utils/keys/space.key.js'

export const useDeleteSpace = (options?: {
  fields?: ActionFields
  useMutationOptions?: UseMutationOptions<
    Action,
    Error,
    MutationDeleteSpaceArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  const collectionsKey = getCollectionsKey()
  const spacesKey = getSpacesKey()

  return useMutation<Action, Error, MutationDeleteSpaceArgs>(
    input => client.spaces.delete(input, fields),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(spacesKey)
        queryClient.invalidateQueries(collectionsKey)
      },
      ...useMutationOptions,
    },
  )
}
