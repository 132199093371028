import { Suspense } from 'react'

import {
  SpaceAnalyticsModal,
  SpaceAnalyticsModalProps,
} from '../../Analytics/SpaceAnalytics/index.js'
import {
  AuditLogsModal,
  AuditLogsModalProps,
} from '../../AuditLog/AuditLogsModal.js'
import {
  SpaceMembersModal,
  SpaceMembersModalProps,
} from '../../MemberList/SpaceMembersModal.js'
import { ShareModal, ShareModalProps } from '../../ShareModal/ShareModal.js'
import {
  AddMembersModal,
  AddMembersModalProps,
} from '../../Space/AddMembersModal.js'
import { SpaceSettingsModal } from '../../Space/index.js'
import { SpaceSettingsModalProps } from '../../Space/SpaceSettingsModal/index.js'
import { useActionItems } from '../ActionItemsContext.js'

export const SpaceActionItemsModals = () => {
  const { modalKey, entityProps, closeModal } = useActionItems()

  return (
    <Suspense>
      {modalKey === 'shareModal' && (
        <ShareModal
          {...(entityProps as ShareModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'auditLogsModal' && (
        <AuditLogsModal
          {...(entityProps as AuditLogsModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'spaceAnalyticsModal' && (
        <SpaceAnalyticsModal
          {...(entityProps as SpaceAnalyticsModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'spaceMembersModal' && (
        <SpaceMembersModal
          {...(entityProps as SpaceMembersModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'spaceSettingsModal' && (
        <SpaceSettingsModal
          {...(entityProps as SpaceSettingsModalProps)}
          open
          onClose={closeModal}
        />
      )}
      {modalKey === 'addMembersModal' && (
        <AddMembersModal
          {...(entityProps as AddMembersModalProps)}
          onClose={closeModal}
        />
      )}
    </Suspense>
  )
}
