import { useCallback } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { useQuery } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getUnreadMessagesCountKey } from '../../utils/keys/messaging.key.js'
import { useOptimisticallyUpdateUnreadChatCount } from './useOptimisticallyUpdateUnreadChatCount.js'

type UnreadMessagesCount = Record<string, number>

export const useUnreadMessagesCount = () => {
  const unreadMessagesCountKey = getUnreadMessagesCountKey()
  const { incrementUnreadChatsCount, decrementUnreadChatsCount } =
    useOptimisticallyUpdateUnreadChatCount()
  const queryClient = useQueryClient()
  const { client } = useTribeClient()

  const { data: unreadMessagesCount } = useQuery<UnreadMessagesCount>(
    unreadMessagesCountKey,
    () => queryClient.getQueryData<UnreadMessagesCount>(unreadMessagesCountKey),
    {
      initialData: {},
    },
  )

  const updateUnreadChatsCount = useCallback(
    (chatId: string, isArchived = false) => {
      const chatUnreadMessages =
        queryClient.getQueryData<UnreadMessagesCount>(unreadMessagesCountKey)[
          chatId
        ] ?? 0

      if (chatUnreadMessages > 0) {
        incrementUnreadChatsCount(chatId, isArchived)
      } else {
        decrementUnreadChatsCount(chatId, isArchived)
      }
    },
    [
      queryClient,
      unreadMessagesCountKey,
      incrementUnreadChatsCount,
      decrementUnreadChatsCount,
    ],
  )

  const setUnreadMessagesCount = useCallback(
    (chatId: string, unreadMessagesCount: number, isArchived = false) => {
      const cachedUnreadMessagesCount =
        queryClient.getQueryData<UnreadMessagesCount>(unreadMessagesCountKey)

      queryClient.setQueryData<UnreadMessagesCount>(unreadMessagesCountKey, {
        ...cachedUnreadMessagesCount,
        [chatId]: unreadMessagesCount,
      })
      queryClient.invalidateQueries(unreadMessagesCountKey)
      updateUnreadChatsCount(chatId, isArchived)
    },
    [queryClient, unreadMessagesCountKey, updateUnreadChatsCount],
  )

  const fetchUnreadMessagesCount = useCallback(
    async (chatIds: string[], archived: boolean) => {
      if (chatIds.length === 0) {
        return
      }

      const result = await client.messaging.getUnreadMessageCounts({
        chatIds,
      })

      result.forEach(item => {
        setUnreadMessagesCount(item.chatId, item.count, archived)
      })
    },
    [client.messaging, setUnreadMessagesCount],
  )

  const maybeFetchUnreadMessagesCount = useCallback(
    async (chatIds: string[], archived: boolean) => {
      const currentData = queryClient.getQueryData<UnreadMessagesCount>(
        unreadMessagesCountKey,
      )
      const nonCachedChatIds = chatIds.filter(
        id => currentData[id] === undefined,
      )

      if (nonCachedChatIds.length === 0) {
        return
      }

      fetchUnreadMessagesCount(nonCachedChatIds, archived)
    },
    [queryClient, unreadMessagesCountKey, fetchUnreadMessagesCount],
  )

  const incrementUnreadMessagesCount = (chatId: string, archived = false) => {
    const cachedUnreadMessagesCount =
      queryClient.getQueryData<UnreadMessagesCount>(unreadMessagesCountKey)
    const currentUnreadMessagesCount = cachedUnreadMessagesCount[chatId] ?? 0

    queryClient.setQueryData<UnreadMessagesCount>(unreadMessagesCountKey, {
      ...cachedUnreadMessagesCount,
      [chatId]: currentUnreadMessagesCount + 1,
    })
    queryClient.invalidateQueries(unreadMessagesCountKey)
    updateUnreadChatsCount(chatId, archived)
  }

  return {
    unreadMessagesCount,
    setUnreadMessagesCount,
    incrementUnreadMessagesCount,
    maybeFetchUnreadMessagesCount,
    fetchUnreadMessagesCount,
  }
}
