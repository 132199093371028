import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InputCopy } from '@tribeplatform/react-ui-kit/Input'

import { T } from '../i18n/components/T.js'
import { SocialShareButtons } from './SocialShareButtons.js'

export type ShareLinkType = {
  url: string
  title: string
  isPrivate: boolean
}

export const ShareLink = ({ url, title, isPrivate }) => {
  return (
    <div className="flex flex-col space-y-5">
      <InputCopy value={url} readOnly />
      {isPrivate && (
        <div className="bg-surface-subdued rounded-base p-3 flex text-sm">
          <SvgIcon
            className="flex-shrink-0 w-5 h-5 me-2 inline-block"
            name="lock"
          />
          <span>
            <T
              defaultMessage="<strong>This page is private</strong>, so only {MEMBERS} with enough permission will be able to access it."
              id="ShareLink.PrivatePageNotice"
            />
          </span>
        </div>
      )}
      {!isPrivate && <SocialShareButtons url={url} title={title} />}
    </div>
  )
}
