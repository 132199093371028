import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  ComponentPropsWithoutRef,
  ReactElement,
  ComponentPropsWithRef,
} from 'react'

import { Tab as HeadlessTab } from '@headlessui/react'
import { clsx } from 'clsx'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import { Badge } from '../Badge/index.js'
import { As } from '../types/index.js'
import { tabItemStyles, tabListStyles } from './Tabs.styles.js'
import { TabsProvider, useTabs } from './TabsContext.js'
import { TabListStyleProps, TabPanelsSpacing, TabVariant } from './types.js'

/**
 * An accessible tabs component that provides keyboard interactions and ARIA attributes described in the WAI-ARIA Tabs Design Pattern.
 */
export const Tabs = () => null

export type TabGroupProps = {
  /**
   * The component that you want to use
   * @default Tab.Group
   */
  as?: As
  /**
   * The default selected index
   * @default 0
   */
  defaultIndex?: number
  /**
   * The selected index, by default it is determined based on user selection
   * @default 0
   */
  selectedIndex?: number
  /**
   * A function called whenever the active tab changes.
   */
  onChange?: (index: number) => void
  /**
   * When true, the user can only display a panel via the keyboard by first navigating to it using the arrow keys, and then by pressing Enter or Space.
   * By default, panels are automatically displayed when navigated to via the arrow keys. Note that this prop has no affect on mouse behavior.
   * @default false
   */
  manual?: boolean
  children?: ReactNode
}

const TabGroup = ({ children, ...rest }: TabGroupProps) => {
  return <HeadlessTab.Group {...rest}>{children}</HeadlessTab.Group>
}

export type TabListProps = Omit<TabListStyleProps, 'variant'> & {
  variant: TabVariant

  /**
   * 'none' - no border radius on mobile and desktop
   * 'desktop' - no border radius on mobile, rounded on desktop
   * 'all' - rounded on both mobile and desktop
   * @default all
   * @deprecated applies for variant="legacy-bar" only
   */
  rounded?: 'none' | 'desktop' | 'all'
  /** @deprecated applies for variant="legacy-bar" only */
  attached?: 'bottom' | 'top' | 'none'
  className?: string
  children?: ReactNode
}

export const TabList = ({
  children,
  className,
  variant,
  fullWidth = false,
  rounded = 'desktop',
  attached = 'none',
  ...rest
}: TabListProps) => {
  const { backgroundType } = useBackgroundContext()

  const barClsx = [
    'z-0',
    'divide-x divide-line-subdued',
    'shadow-card border-base',
    rounded === 'none' && 'rounded-t-none rounded-b-none',
    rounded === 'desktop' &&
      'rounded-t-none rounded-b-none sm:rounded-t-card sm:rounded-b-card',
    rounded === 'all' && 'rounded-t-card rounded-b-card',
  ]

  let attachedClsx = ''
  if (attached === 'top') {
    attachedClsx =
      'rounded-t-none sm:rounded-t-none border-t border-t-line-subdued'
  }
  if (attached === 'bottom') {
    attachedClsx =
      'rounded-b-none sm:rounded-b-none border-b border-b-line-subdued'
  }

  const legacyClsx = [
    'overflow-x-auto isolate relative',
    barClsx,
    attachedClsx,
    'flex  flex-nowrap',
  ]

  return (
    <TabsProvider variant={variant} fullWidth={fullWidth}>
      <div className="relative">
        <HeadlessTab.List
          className={clsx(
            variant === 'legacyBar'
              ? legacyClsx
              : tabListStyles({
                  variant,
                  fullWidth,
                  backgroundType:
                    backgroundType === 'main' ? 'onBackground' : 'onSurface',
                }),
            className,
          )}
          aria-label="Tabs"
          {...rest}
        >
          {({ selectedIndex }) => {
            // workaround for https://github.com/tailwindlabs/headlessui/issues/1488
            return (
              <>
                {Children.toArray(children).map((child, index) => {
                  if (isValidElement(child)) {
                    return cloneElement<{ selected?: boolean }>(child, {
                      selected: index === selectedIndex,
                    })
                  }
                  return child
                })}
              </>
            )
          }}
        </HeadlessTab.List>
        {variant === 'underlined' && (
          <hr
            aria-hidden="true"
            className="absolute inset-x-0 -bottom-[5px] h-[1px]"
          />
        )}
      </div>
    </TabsProvider>
  )
}

export type TabItemProps = ComponentPropsWithoutRef<'button'> & {
  selected?: boolean
  badge?: string
}

const TabItem = ({
  children,
  badge,
  className: customClassName,
  selected: customSelected,
  ...rest
}: TabItemProps) => {
  const { variant, fullWidth } = useTabs()
  const { backgroundType } = useBackgroundContext()

  const variantBar = (selected: boolean) => [
    selected
      ? 'text-content'
      : 'text-content-subdued hover:text-content-hovered',
    'group relative text-center',
    'bg-surface  hover:bg-surface-hovered',
    'py-4 px-4 m-0 text-sm',
    'focus:outline-none focus-visible:ring ring-inset ring-offset-0',
  ]

  let className: string | (({ selected }: { selected: boolean }) => string) = ({
    selected,
  }) =>
    clsx(
      variant === 'legacyBar'
        ? ['flex-1', variantBar(selected), 'focus:outline-none focus:z-10']
        : tabItemStyles({
            variant,
            fullWidth,
            backgroundType:
              backgroundType === 'main' ? 'onBackground' : 'onSurface',
          }),
      customClassName,
    )

  let content:
    | ReactElement
    | (({ selected }: { selected: boolean }) => ReactElement) = ({
    selected,
  }) => (
    <>
      {badge ? (
        <div className="inline-flex items-center gap-2">
          <span className="min-w-0 truncate">{children}</span>
          {badge && <Badge>{badge}</Badge>}
        </div>
      ) : (
        children
      )}
      {variant === 'underlined' && (
        <span
          aria-hidden="true"
          className={clsx(
            'transition duration-200',
            selected ? 'bg-interactive' : 'bg-transparent',
            'absolute inset-x-3 -bottom-1 h-0.5',
          )}
        />
      )}
      {variant === 'legacyBar' && (
        <span
          aria-hidden="true"
          className={clsx(
            selected ? 'bg-action-primary' : 'bg-transparent',
            'absolute inset-x-0 bottom-0 h-0.5',
          )}
        />
      )}
    </>
  )

  if (customSelected !== undefined) {
    className = className({ selected: customSelected })
    content = content({ selected: customSelected })
  }

  return (
    <HeadlessTab className={className} {...rest}>
      {content}
    </HeadlessTab>
  )
}

export type TabPanelsProps = ComponentPropsWithRef<'div'> & {
  spacing?: TabPanelsSpacing
}

const TabPanels = ({
  spacing = 'md',
  children,
  className,
  ...rest
}: TabPanelsProps) => {
  return (
    <div
      className={clsx(
        [
          spacing === 'lg' && 'mt-5 sm:mt-6 md:mt-7 lg:mt-8',
          spacing === 'md' && 'mt-3',
          spacing === 'sm' && 'mt-2 sm:mt-2.5 lg:mt-3',
          spacing === 'none' && 'mt-0',
        ],
        className,
      )}
      {...rest}
    >
      <HeadlessTab.Panels>
        {({ selectedIndex }) => {
          // workaround for https://github.com/tailwindlabs/headlessui/issues/1488
          return (
            <>
              {Children.toArray(children).map((child, index) => {
                if (index !== selectedIndex) {
                  return null
                }
                return child
              })}
            </>
          )
        }}
      </HeadlessTab.Panels>
    </div>
  )
}
const TabPanel = props => {
  const { children, className, ...rest } = props

  return (
    <HeadlessTab.Panel
      className={clsx(
        'focus:outline-none focus-visible:ring rounded-base',
        className,
      )}
      {...rest}
      static
    >
      {children}
    </HeadlessTab.Panel>
  )
}

Tabs.Group = TabGroup
Tabs.List = TabList
Tabs.Tab = TabItem
Tabs.Panels = TabPanels
Tabs.Panel = TabPanel
