import {
  createContext,
  useEffect,
  type ReactNode,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react'

import { io, type Socket } from 'socket.io-client'

import { useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { IdentifyRequest } from './constants.js'
import { IdentifyClientPayload } from './types.js'

interface Props {
  networkId: string
  config: {
    endPoint: string
    path: string
    namespacePrefix: string
    enabled: boolean
  }
  onSocketError?: (...args: unknown[]) => void
  onDebug?: (event: string, payload: unknown) => void
  onIdentify?: (error: unknown, response: unknown) => void
  children: ReactNode
}

export type SocketContextType = {
  socket: Socket
  connected: boolean
  identified: boolean
}

const SocketContext = createContext<SocketContextType>(null)

export const SocketProvider = ({
  networkId,
  children,
  config,
  onSocketError,
  onDebug,
  onIdentify,
}: Props) => {
  const { endPoint, path, namespacePrefix } = config
  const namespace = `${namespacePrefix}/${networkId}`

  const socketUrl = `${endPoint}${namespace}`

  const socketRef = useRef<Socket>(
    io(socketUrl, {
      autoConnect: false,
      path,
      transports: ['websocket'],
    }),
  )

  const [isConnected, setIsConnected] = useState(false)
  const [isIdentified, setIsIdentified] = useState(false)

  const {
    data: { accessToken },
  } = useAuthToken()

  const socket = socketRef.current

  /**
   * Effects are structured and decoupled in a way that prevents their re-execution on unrelated dependency changes
   */
  useEffect(() => {
    socket.io.on('error', onSocketError)
    const handleSocketConnection = () => {
      setIsConnected(true)
    }
    const handleSocketDisconnection = () => {
      setIsConnected(false)
      setIsIdentified(false)
    }

    socket.on('connect', handleSocketConnection)
    socket.on('disconnect', handleSocketDisconnection)
    if (onDebug) {
      socket.onAny(onDebug)
    }

    return () => {
      socket.off('connect', handleSocketConnection)
      socket.off('disconnect', handleSocketDisconnection)
      if (onDebug) {
        socket.onAny(onDebug)
      }
    }
  }, [onDebug, onSocketError, socket])

  const identify = useCallback(async () => {
    try {
      const payload: IdentifyClientPayload = {
        token: accessToken,
      }
      const response = await socket.emitWithAck(IdentifyRequest, payload)
      if (onIdentify) {
        onIdentify(null, response)
      }
      if (response.status === 'ok') {
        setIsIdentified(true)
      } else {
        setIsIdentified(false)
      }
    } catch (error) {
      setIsIdentified(false)
      if (onIdentify) {
        onIdentify(error, null)
      }
    }
  }, [socket, accessToken, onIdentify])

  useEffect(() => {
    if (!config.enabled) {
      return
    }

    if (isConnected) {
      identify()
    }
  }, [config.enabled, isConnected, identify])

  useEffect(() => {
    if (!config.enabled) {
      return
    }

    socket.connect()
  })

  return (
    <SocketContext.Provider
      value={{
        socket,
        connected: isConnected,
        identified: isIdentified,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export const useSocket = () => {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider')
  }

  return context
}
