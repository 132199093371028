import { ReactNode } from 'react'

import {
  Button,
  ButtonProps,
  IconButton,
} from '@tribeplatform/react-ui-kit/Button'
import { As } from '@tribeplatform/react-ui-kit/types'

import { Tooltip } from './Tooltip.js'

interface Props {
  className?: string
  fullWidth?: boolean
  disabled?: boolean
  loading?: boolean
  showText?: boolean
  showIcon?: boolean
  pressed?: boolean
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
  icon?: ReactNode
  label?: ReactNode
  tooltip?: ReactNode
  as?: As
  tabIndex?: number
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export const ActionButtonRenderer = ({
  showText,
  showIcon,
  className,
  fullWidth,
  disabled,
  pressed,
  variant,
  size,
  icon,
  label,
  tooltip,
  onClick,
  loading,
  as,
  tabIndex,
}: Props) => {
  if (showIcon && !showText) {
    return (
      <Tooltip tooltip={tooltip}>
        <IconButton
          as={as}
          className={className}
          disabled={disabled || loading}
          loading={loading}
          pressed={pressed}
          variant={variant}
          size={size}
          icon={icon}
          onClick={onClick}
          tabIndex={tabIndex}
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip tooltip={tooltip}>
      <Button
        as={as}
        pressed={pressed}
        className={className}
        fullWidth={fullWidth}
        disabled={disabled || loading}
        variant={variant}
        size={size}
        leadingIcon={showIcon && icon}
        onClick={onClick}
        tabIndex={tabIndex}
      >
        {showText && label}
      </Button>
    </Tooltip>
  )
}
