import { useMemo, useEffect, useRef, useState } from 'react'

import { clsx } from 'clsx'
import { useLocation } from 'react-router-dom'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import { useReplies } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import { scrollToElement } from '@tribeplatform/react-ui-kit/utils'

import { T } from '../i18n/components/T.js'
import { useStaticIntl } from '../i18n/hooks/useStaticIntl.js'
import { ReplyBar } from '../Post/ReplyBar.js'
import { ReplySort } from './components/ReplySort.js'
import {
  REACTIONS_OF_REPLY_LIMIT,
  REPLIES_OF_REPLY_LIMIT,
  REPLIES_SECTION_HASH,
} from './constants.js'
import { HighlightedReplyParent } from './HighlightedReplyParent.js'
import { useHighlightedReply } from './hooks/useHighlightedReply.js'
import { Reply } from './Reply.js'
import { SortOption, type SortOptionId } from './types.js'
import { sortOptionsFactory } from './utils/sortOptions.js'

interface Props {
  post: Post
  showReplySortOptions?: boolean
  defaultReplySortId?: SortOptionId
  readonly?: boolean
  className?: string
}
export const ReplyList = ({
  post,
  showReplySortOptions = true,
  defaultReplySortId,
  readonly,
  className,
}: Props) => {
  const { hash } = useLocation()
  const repliesRef = useRef<HTMLDivElement>(null)
  const sortOptions = useStaticIntl(sortOptionsFactory)
  const selectedSortOption = sortOptions.find(
    option => option.id === defaultReplySortId,
  )
  const [sortOption, setSortOption] = useState<SortOption>(
    selectedSortOption ?? sortOptions[0],
  )
  const { data, fetchNextPage, hasNextPage, isFetching, isInitialLoading } =
    useReplies({
      variables: {
        limit: 10,
        postId: post?.id,
        reverse: sortOption?.reverse ?? true,
        orderBy: sortOption?.value,
      },
      fields: {
        owner: {
          member: { profilePicture: 'basic', badges: 'all' },
        },
        embeds: 'default',
        mentions: 'basic',
        attachments: 'default',
        authMemberProps: 'all',
        reactions: { fields: 'all', variables: { limit: 10 } },
        replies: {
          fields: {
            owner: {
              member: { profilePicture: 'basic', badges: 'all' },
            },
            embeds: 'default',
            mentions: 'basic',
            attachments: 'default',
            authMemberProps: 'all',
            reactions: {
              fields: 'all',
              variables: { limit: REACTIONS_OF_REPLY_LIMIT },
            },
          },
          variables: {
            limit: REPLIES_OF_REPLY_LIMIT,
            reverse: true,
          },
        },
      },
      useInfiniteQueryOptions: {
        refetchOnMount: 'always',
        enabled: !!post?.id,
      },
    })

  const { highlightedRootReply, isLoading: isLoadingHighlightedReply } =
    useHighlightedReply(post?.id)

  const [canCreateReply] = hasScopesPermission(post, ['createReply'])
  const canReply =
    !readonly && post && post?.status === PostStatus.PUBLISHED && canCreateReply

  const { nodes: replies } = simplifyPaginatedResult<Post>(data)
  const replyCount = replies?.length ?? 0
  const nestedReplyCount =
    replies?.reduce((count, reply) => {
      return count + (reply?.totalRepliesCount ?? 0)
    }, 0) ?? 0
  const loadedRepliesCount = replyCount + nestedReplyCount

  const repliesWithoutHighlightedReply = useMemo(() => {
    if (!highlightedRootReply) {
      return replies
    }

    // Remove the highlighted reply to prevent duplication.
    return replies.filter(({ id }) => id !== highlightedRootReply.id)
  }, [replies, highlightedRootReply])

  const shouldScroll =
    hash === REPLIES_SECTION_HASH && replies?.length > 0 && !isInitialLoading

  useEffect(() => {
    if (!shouldScroll || !repliesRef?.current) {
      return
    }

    scrollToElement({ elementRef: repliesRef })
  }, [shouldScroll])

  if (isInitialLoading || isLoadingHighlightedReply) {
    return (
      <Card className={clsx('mt-5', className)}>
        <Card.Content className="pb-3 sm:pb-3 px-5 flex flex-col space-y-6">
          {[...Array(3)].map((_e, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="animate-pulse flex space-s-3" key={i}>
              <div className="rounded-full h-10 w-10 bg-skeleton " />
              <div className="flex-1 space-y-1">
                <div className="flex items-center justify-between mb-3">
                  <div className="h-4 bg-skeleton rounded-full w-4/6"></div>
                </div>
                <div className="flex items-center justify-between mb-3">
                  <div className="h-4 bg-skeleton rounded-full w-full"></div>
                </div>
              </div>
            </div>
          ))}
        </Card.Content>
      </Card>
    )
  }

  if (!(replies?.length > 0 || canReply)) {
    return null
  }

  return (
    <div className="flex flex-col gap-y-2" ref={repliesRef}>
      {!!showReplySortOptions && replies.length > 1 && (
        <ReplySort sortOption={sortOption} setSortOption={setSortOption} />
      )}
      <Card className={clsx(className)}>
        <Card.Content className="flex flex-col space-y-6 px-5 empty:hidden">
          {highlightedRootReply && (
            <HighlightedReplyParent
              reply={highlightedRootReply}
              post={post}
              canReply
              readonly={readonly}
              context="post"
            />
          )}
          {repliesWithoutHighlightedReply.map(reply => (
            <Reply
              key={reply.id}
              reply={reply}
              post={post}
              canReply
              readonly={readonly}
              context="post"
              nestLevel={1}
            />
          ))}
          {replies?.length > 0 && (
            <div className="flex justify-between">
              <div className="flex items-center space-s-3">
                {hasNextPage && (
                  <Link
                    variant="neutral"
                    className="text-sm leading-5 font-medium text-content-subdued"
                    onClick={() => {
                      fetchNextPage()
                    }}
                  >
                    <T
                      id="Reply.ReplyList.MoreRepliesButtonText"
                      defaultMessage="View more replies"
                    />
                  </Link>
                )}
                {isFetching && (
                  <SvgIcon className="animate-spin" name="spinner" />
                )}
              </div>
              <div className="text-content-subdued text-sm leading-5 font-normal">
                <T
                  id="Reply.ReplyList.LoadedOfTotalRepliesText"
                  defaultMessage="{loaded_replies} of {total_replies}"
                  values={{
                    loaded_replies: loadedRepliesCount,
                    total_replies: post?.totalRepliesCount,
                  }}
                />
              </div>
            </div>
          )}
          {canReply && <ReplyBar post={post} />}
        </Card.Content>
      </Card>
    </div>
  )
}
