import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationAddSpaceMembersArgs,
  SpaceMember,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceMembersKey } from '../../utils/keys/member.key.js'

export const useAddSpaceMember = (options?: {
  useMutationOptions?: UseMutationOptions<
    Array<SpaceMember>,
    ClientError,
    MutationAddSpaceMembersArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<
    Array<SpaceMember>,
    ClientError,
    MutationAddSpaceMembersArgs
  >(input => client.spaceMembers.add(input), {
    onSettled: (data, error, variables) => {
      const spaceMembersKey = getSpaceMembersKey({
        variables: {
          spaceId: variables.spaceId,
        },
      })
      queryClient.invalidateQueries(spaceMembersKey)
    },
    ...useMutationOptions,
  })
}
