import { useState } from 'react'

import { useMatch } from 'react-router'

import { useAskAIAccess } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Input } from '@tribeplatform/react-ui-kit/Input'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { CommandBarMode } from '../../CommandBar/CommandBar.types.js'
import { useCommandBar } from '../../CommandBar/CommandBarContext.js'
import { T } from '../../i18n/index.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import {
  AiStarIcon,
  LEGACY_SEARCH_ENTITY_TYPE_MAP,
} from '../../SmartSearch/index.js'
import { SearchBarProps } from './SearchBar.types.js'

export const NewSearchBar = ({
  autoFocus = false,
  placeholder: maybePlaceholder,
  entityType,
  spaceIds,
}: SearchBarProps) => {
  const { canAskAi } = useAskAIAccess()
  const { $t } = useI18n()
  const [query, setQuery] = useState('')

  const { open } = useCommandBar()

  const isSearchPage = useMatch('/search')

  const isContextSpecificSearch = entityType || !!spaceIds?.length
  const shouldShowAskAi = canAskAi && !isContextSpecificSearch
  const placeholder =
    maybePlaceholder ||
    $t({
      id: 'Generics.SearchDotDotDot',
      defaultMessage: 'Search...',
    })

  const handleInteraction = () => {
    if (isSearchPage) {
      return null
    }

    return open({
      searchOptions: {
        mode: CommandBarMode.SEARCH,
        entityType: LEGACY_SEARCH_ENTITY_TYPE_MAP[entityType],
        spaceIds,
        placeholder: maybePlaceholder,
      },
    })
  }

  return (
    <div className="flex items-center flex-1">
      <Input
        size="compact"
        type="search"
        inputMode="search"
        autoFocus={autoFocus}
        leadingAddon={
          <SvgIcon name="search" className="text-content-disabled" />
        }
        trailingAddon={
          shouldShowAskAi && (
            <Tooltip offsetValue={12}>
              <Tooltip.Trigger
                className="group/ai-star-icon flex items-center text-content-disabled cursor-pointer hover:text-content-hovered transition-colors"
                onClick={handleInteraction}
              >
                <AiStarIcon
                  animateOnHover
                  iconClassName="w-4"
                  className="h-4"
                />
                <span>
                  <T id="Generics.AI.Abbreviation" defaultMessage="AI" />
                </span>
              </Tooltip.Trigger>
              <Tooltip.Panel>
                <T
                  id="SmartSearch.SearchInput.Tooltip"
                  defaultMessage="Ask AI – Get smarter, faster search results!"
                />
              </Tooltip.Panel>
            </Tooltip>
          )
        }
        placeholder={placeholder}
        value={query}
        onChange={e => setQuery(e.target.value)}
        onClick={handleInteraction}
        onKeyDown={handleInteraction}
        wrapperClassName="[&>input]:cursor-pointer"
      />
    </div>
  )
}
