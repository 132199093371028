import { createContext, ReactNode, useContext } from 'react'

import type { SearchAction } from '../../HeroBanner/types.js'

export type SearchBarContextProps = {
  settings: SearchAction
  hasSearchSection: boolean
}

export const SearchBarContext = createContext<SearchBarContextProps>(null)

export type SearchBarProviderProps = {
  settings: SearchAction
  hasSearchSection: boolean
  children: ReactNode
}

export const SearchBarProvider = ({
  settings,
  hasSearchSection,
  children,
}: SearchBarProviderProps) => {
  return (
    <SearchBarContext.Provider
      value={{
        settings,
        hasSearchSection,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  )
}

export const useSearchBar = (): SearchBarContextProps => {
  const context = useContext(SearchBarContext)
  return context
}
