import React, { useId } from 'react'

import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
  iconClassName?: string
  animationDuration?: string
  animateOnHover?: boolean
  enableHoverGroup?: boolean
  isStatic?: boolean
}

const COLORS = {
  primary: {
    start: '#A345EB', // blueViolet
    end: '#4FDFF6', // mayaBlue
  },
  inactive: {
    start: '#333',
    end: '#CCC',
  },
} as const

/**
 * AiStarIcon - An animated star icon component with various animation states
 *
 * Animation States:
 * 1. Normal (default): Continuously animates between violet and blue colors
 * 2. Hover Animation: Starts gray and animates on hover
 * 3. Static: Always gray, no animations
 *
 * @props
 * - animationDuration: Duration of the gradient animation (default: '3s')
 * - animateOnHover: If true, starts gray and only animates on hover (default: false)
 * - isStatic: If true, stays gray and disables all animations (default: false)
 * - enableHoverGroup: Controls the hover group behavior (default: true)
 *   NOTE: If set to false, you must add 'group/ai-star-icon' class to the parent element
 *   that should trigger the hover animation. Example:
 *   ```
 *   <div className="group/ai-star-icon">
 *     <AiStarIcon enableHoverGroup={false} />
 *   </div>
 *   ```
 * - className: Additional classes for the wrapper div
 * - iconClassName: Additional classes for the SVG element
 *
 * Usage Examples:
 * ```
 * // Always animated
 * <AiStarIcon />
 *
 * // Animate only on hover
 * <AiStarIcon animateOnHover />
 *
 * // Static gray, no animations
 * <AiStarIcon isStatic />
 *
 * // Custom animation duration
 * <AiStarIcon animationDuration="5s" />
 *
 * // Custom hover trigger
 * <div className="group/ai-star-icon">
 *   <AiStarIcon enableHoverGroup={false} animateOnHover />
 * </div>
 * ```
 */

export const AiStarIcon = ({
  className,
  iconClassName,
  animationDuration = '3s',
  animateOnHover = false,
  enableHoverGroup = true,
  isStatic = false,
}: Props) => {
  const id = useId()
  const gradientId = `gradient-${id}`

  const shouldAnimateOnHover = !isStatic && animateOnHover
  const shouldAlwaysAnimate = !isStatic && !animateOnHover

  const colors =
    isStatic || animateOnHover
      ? [COLORS.inactive.start, COLORS.inactive.end]
      : [COLORS.primary.start, COLORS.primary.end]

  const getAnimationClass = (index: 1 | 2) => {
    if (isStatic) {
      return undefined
    }
    // in the following lines, cannot use index directly because of tailwind, so we have to define classnames manually
    const animationClass =
      index === 1 ? 'animate-gradient-flow-1' : 'animate-gradient-flow-2'
    const hoverAnimationClass =
      index === 1
        ? 'group-hover/ai-star-icon:animate-gradient-flow-1'
        : 'group-hover/ai-star-icon:animate-gradient-flow-2'

    return animateOnHover ? hoverAnimationClass : animationClass
  }

  return (
    <div
      className={twMerge(
        'h-5 aspect-square',
        enableHoverGroup && 'group/ai-star-icon',
        className,
      )}
    >
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={twMerge('max-h-full', iconClassName)}
      >
        <path
          d="M3.30754 1.27659C3.30754 0.956118 3.04775 0 2.72727 0C2.40679 0 2.147 0.956118 2.147 1.27659V2.147H1.2766C0.956121 2.147 0 2.4068 0 2.72727C0 3.04775 0.956121 3.30755 1.2766 3.30755H2.147V4.17795C2.147 4.49842 2.40679 5.45454 2.72727 5.45454C3.04775 5.45454 3.30754 4.49842 3.30754 4.17795V3.30755H4.17795C4.49842 3.30755 5.45454 3.04775 5.45454 2.72727C5.45454 2.4068 4.49842 2.147 4.17795 2.147H3.30754V1.27659Z"
          fill={isStatic || animateOnHover ? COLORS.inactive.end : colors[1]}
          className={
            shouldAnimateOnHover
              ? 'group-hover/ai-star-icon:fill-[#4FDFF6]'
              : undefined
          }
        />
        <path
          className="animated-path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C1.31322 10 2.61358 10.2587 3.82683 10.7612C5.04009 11.2638 6.14248 12.0003 7.07107 12.9289C7.99965 13.8575 8.73625 14.9599 9.23879 16.1732C9.74134 17.3864 10 18.6868 10 20C10 18.6868 10.2587 17.3864 10.7612 16.1732C11.2637 14.9599 12.0003 13.8575 12.9289 12.9289C13.8575 12.0003 14.9599 11.2638 16.1732 10.7612C17.3864 10.2587 18.6868 10 20 10C18.6868 10 17.3864 9.74134 16.1732 9.2388C14.9599 8.73625 13.8575 7.99965 12.9289 7.07107C12.0003 6.14248 11.2638 5.04009 10.7612 3.82683C10.2587 2.61358 10 1.31322 10 0C10 1.31322 9.74134 2.61358 9.2388 3.82683C9.20739 3.90266 9.17506 3.97806 9.14184 4.053C8.64342 5.17721 7.94162 6.20052 7.07107 7.07107C6.955 7.18714 6.83621 7.30021 6.71482 7.4102C5.86515 8.18014 4.88843 8.79907 3.82683 9.23879C3.67518 9.30161 3.52216 9.36062 3.36793 9.41579C2.28829 9.80196 1.14907 10 0 10Z"
          fill={`url(#${gradientId})`}
        />

        <defs>
          <linearGradient
            gradientTransform="rotate(45)"
            id={gradientId}
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0"
              stopColor={colors[0]}
              className={getAnimationClass(1)}
            >
              {shouldAlwaysAnimate && (
                <animate
                  attributeName="stop-color"
                  dur={animationDuration}
                  values={`${COLORS.inactive.start};${COLORS.primary.start};${COLORS.primary.start}`}
                  begin="indefinite"
                />
              )}
            </stop>
            <stop
              offset="1"
              stopColor={colors[1]}
              className={getAnimationClass(2)}
            >
              {shouldAlwaysAnimate && (
                <animate
                  attributeName="stop-color"
                  dur={animationDuration}
                  values={`${COLORS.inactive.end};${COLORS.primary.end};${COLORS.primary.end}`}
                  begin="indefinite"
                />
              )}
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
