import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useRef,
  useReducer,
  useEffect,
} from 'react'

import {
  IsTypingEvent,
  StoppedTypingEvent,
  SubscribeToChatRequest,
  UnsubscribeFromChatRequest,
} from '../Socket/constants.js'
import { useSocket } from '../Socket/SocketProvider.js'
import {
  TIsTypingServerPayload,
  TStoppedTypingServerPayload,
  TSubscribeToChatClientPayload,
  TUnsubscribeFromChatClientPayload,
} from '../Socket/types.js'
import {
  initialIsTypingState,
  isTypingStatusReducer,
} from './is-typing-reducer.js'
import { RevalidateIsTypingStates, TIsTypingState } from './types.js'

interface Props {
  children: ReactNode
  enabled: boolean
}

export type ChatRoomSubscriptionsContextType = {
  state: TIsTypingState
  subscribeToChat: (chatId: string, subscriberId: string) => Promise<void>
  unsubscribeFromChat: (chatId: string, subscriberId: string) => Promise<void>
}

const ChatRoomSubscriptionsContext =
  createContext<ChatRoomSubscriptionsContextType>(null)

export const ChatRoomSubscriptionsProvider = ({ children, enabled }: Props) => {
  const { socket, identified } = useSocket()
  const [isTypingState, dispatchTypingStatusAction] = useReducer(
    isTypingStatusReducer,
    initialIsTypingState,
  )

  const revalidationInterval = useRef(null)

  const roomSubscriptionsSemaphore = useRef<Map<string, Set<string>>>(new Map())

  const subscribeToChat = useCallback(
    async (chatId: string, subscriberId: string) => {
      if (!roomSubscriptionsSemaphore.current.has(chatId)) {
        roomSubscriptionsSemaphore.current.set(chatId, new Set([subscriberId]))
      } else {
        roomSubscriptionsSemaphore.current.get(chatId).add(subscriberId)
      }

      try {
        const payload: TSubscribeToChatClientPayload = {
          chatId,
        }
        const response = await socket.emitWithAck(
          SubscribeToChatRequest,
          payload,
        )

        if (response.status !== 'ok') {
          roomSubscriptionsSemaphore.current.get(chatId).delete(subscriberId)
        }
      } catch (error) {
        roomSubscriptionsSemaphore.current.get(chatId).delete(subscriberId)
      }
    },
    [socket],
  )

  const unsubscribeFromChat = useCallback(
    async (chatId: string, subscriberId: string) => {
      try {
        const currentSubscribers =
          roomSubscriptionsSemaphore.current.get(chatId)

        if (!currentSubscribers?.has(subscriberId)) {
          return
        }

        currentSubscribers.delete(subscriberId)

        if (currentSubscribers.size === 0) {
          const payload: TUnsubscribeFromChatClientPayload = {
            chatId,
          }
          socket.emit(UnsubscribeFromChatRequest, payload)
        }
      } catch (error) {
        // no op
      }
    },
    [socket],
  )

  /**
   * Reconnect to previously connected rooms
   */
  useEffect(() => {
    if (!identified || !enabled) {
      return
    }

    if (!roomSubscriptionsSemaphore.current) {
      return
    }

    const chatIds = Array.from(roomSubscriptionsSemaphore.current.keys())
    chatIds.forEach(chatId => {
      const payload: TSubscribeToChatClientPayload = {
        chatId,
      }
      socket.emit(SubscribeToChatRequest, payload)
    })
  }, [enabled, identified, socket])

  useEffect(() => {
    const handleIsTyping = (event: TIsTypingServerPayload) => {
      dispatchTypingStatusAction({
        type: IsTypingEvent,
        payload: {
          chatId: event.chatId,
          memberId: event.memberId,
          memberName: event.memberName,
        },
      })
    }
    const handleStoppedTyping = (event: TStoppedTypingServerPayload) => {
      dispatchTypingStatusAction({
        type: StoppedTypingEvent,
        payload: {
          chatId: event.chatId,
          memberId: event.memberId,
        },
      })
    }

    socket.on(IsTypingEvent, handleIsTyping)
    socket.on(StoppedTypingEvent, handleStoppedTyping)

    return () => {
      socket.off(IsTypingEvent, handleIsTyping)
      socket.off(StoppedTypingEvent, handleStoppedTyping)
    }
  }, [socket])

  useEffect(() => {
    revalidationInterval.current = setInterval(() => {
      dispatchTypingStatusAction({ type: RevalidateIsTypingStates })
    }, 10_000)

    return () => {
      clearInterval(revalidationInterval.current)
    }
  }, [])

  return (
    <ChatRoomSubscriptionsContext.Provider
      value={{
        state: isTypingState,
        subscribeToChat,
        unsubscribeFromChat,
      }}
    >
      {children}
    </ChatRoomSubscriptionsContext.Provider>
  )
}

export const useChatRoomSubscriptions = () => {
  const context = useContext(ChatRoomSubscriptionsContext)
  if (!context) {
    throw new Error(
      'useChatRoomSubscriptions must be used within a ChatRoomSubscriptionsProvider',
    )
  }

  return context
}
