import {
  Member,
  ModerationEntityType,
  Post,
  ReportCategory,
} from '@tribeplatform/gql-client/types'
import { useCreateModerationReport } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { RadioGroup } from '@tribeplatform/react-ui-kit/RadioGroup'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { Form } from '../Form/index.js'
import { useI18n, useStaticIntl, T } from '../i18n/index.js'
import { I18nShape } from '../i18n/types.js'

const titleFactory = (
  { $t }: I18nShape,
  entityType: ModerationEntityType,
): string => {
  switch (entityType) {
    case ModerationEntityType.MEMBER:
      return $t({
        defaultMessage: 'Report {MEMBER}',
        id: 'Member.Report',
      })
    case ModerationEntityType.POST:
      return $t({
        defaultMessage: 'Report post',
        id: 'Post.Report',
      })
    default: {
      const exhaustiveCheck: never = entityType
      return exhaustiveCheck
    }
  }
}

export type ReportModalProps = {
  entityType: ModerationEntityType
  entity: Post | Member
  open: boolean
  onClose: () => void
}

export const ReportModal = ({
  entityType,
  entity,
  open,
  onClose,
}: ReportModalProps) => {
  const { $t } = useI18n()
  const title = useStaticIntl(titleFactory, entityType)
  const { mutateAsync: createModerationReport, isLoading } =
    useCreateModerationReport()

  if (!entity?.id || !entityType) {
    return null
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header title={title} />
      <Modal.Content>
        <Form<{ reportCategory: ReportCategory; description?: string }>
          className="flex flex-col space-y-5"
          onSubmit={async ({ reportCategory, description }) => {
            await createModerationReport({
              input: {
                entityType,
                entityId: entity?.id,
                reportCategory,
                description,
                spaceId: (entity as Post)?.spaceId,
              },
            })
            toast({
              title: $t({
                defaultMessage: 'Report submitted',
                id: 'Toast.Title',
              }),
              description: $t({
                defaultMessage:
                  'Thanks for making the {COMMUNITY} a better place.',
                id: 'Toast.Description',
              }),
            })
            onClose()
          }}
        >
          {({ watch }) => {
            const [reportCategory] = watch(['reportCategory'])
            return (
              <>
                <Form.RadioGroup
                  name="reportCategory"
                  validation={{
                    required: $t({
                      defaultMessage: 'Please pick a category',
                      id: 'ReportCategory.Title',
                    }),
                  }}
                >
                  <RadioGroup.Items>
                    <RadioGroup.Item
                      value={ReportCategory.HARASSMENT}
                      title={$t({
                        defaultMessage: 'Harassment or hate speech',
                        id: 'ReportCategory.Harassment.Title',
                      })}
                      description={$t({
                        defaultMessage:
                          'Harassing or threatening a person or group',
                        id: 'ReportCategory.Harassment.Description',
                      })}
                    />
                    <RadioGroup.Item
                      value={ReportCategory.SPAM}
                      title={$t({
                        defaultMessage: 'Spam',
                        id: 'ReportCategory.Spam.Title',
                      })}
                      description={$t({
                        defaultMessage:
                          'Undisclosed promotion for a link or selling illegal goods, money scams, etc.',
                        id: 'ReportCategory.Spam.Description',
                      })}
                    />
                    <RadioGroup.Item
                      value={ReportCategory.MISINFORMATION}
                      title={$t({
                        defaultMessage: 'Misinformation',
                        id: 'ReportCategory.Misinformation.Title',
                      })}
                      description={$t({
                        defaultMessage:
                          'Substantially incorrect and/or incorrect primary conclusions',
                        id: 'ReportCategory.Misinformation.Description',
                      })}
                    />
                    <RadioGroup.Item
                      value={ReportCategory.VIOLENCE}
                      title={$t({
                        defaultMessage: 'Harmful activities',
                        id: 'ReportCategory.Harmful.Title',
                      })}
                      description={$t({
                        defaultMessage:
                          'Glorifying violence including self-harm or intent to seriously harm others',
                        id: 'ReportCategory.Harmful.Description',
                      })}
                    />
                    <RadioGroup.Item
                      value={ReportCategory.NUDITY}
                      title={$t({
                        defaultMessage: 'Adult content',
                        id: 'ReportCategory.Adult.Title',
                      })}
                      description={$t({
                        defaultMessage: 'Nudity or sexual content',
                        id: 'ReportCategory.Adult.Description',
                      })}
                    />
                  </RadioGroup.Items>
                </Form.RadioGroup>
                {reportCategory && (
                  <Form.Textarea
                    name="description"
                    label={$t({
                      defaultMessage: 'Explain this report (optional)',
                      id: 'ReportCategory.Description',
                    })}
                  />
                )}
                <Form.Actions>
                  <Button
                    type="submit"
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    <T defaultMessage="Report" id="Generics.Report.Verb" />
                  </Button>
                  <Button variant="secondaryNeutral" onClick={onClose}>
                    <T defaultMessage="Cancel" id="Generics.Cancel" />
                  </Button>
                </Form.Actions>
              </>
            )
          }}
        </Form>
      </Modal.Content>
    </Modal>
  )
}
