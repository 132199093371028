export const IdentifyRequest = 'identify'

export const MsgReceivedEvent = 'msg_received'

export const ReconnectEvent = 'reconnect'

export const SubscribeToChatRequest = 'subscribe'

export const UnsubscribeFromChatRequest = 'unsubscribe'

export const IsTypingRequest = 'is_typing'

export const IsTypingEvent = 'is_typing'

export const StoppedTypingRequest = 'stopped_typing'

export const StoppedTypingEvent = 'stopped_typing'

export const STALE_IS_TYPING_THRESHOLD = 5000
