import EmailShareButton from 'react-share/EmailShareButton'
import FacebookShareButton from 'react-share/FacebookShareButton'
import LinkedinShareButton from 'react-share/LinkedinShareButton'
import TwitterShareButton from 'react-share/TwitterShareButton'
import WhatsappShareButton from 'react-share/WhatsappShareButton'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgSocialIcon } from '@tribeplatform/react-ui-kit/Icon'

export const SocialShareButtons = ({ url, title }) => {
  return (
    <div className="flex space-s-3">
      <FacebookShareButton className="flex-1" url={url} title={title}>
        <Button as="div" variant="secondaryNeutral" fullWidth>
          <SvgSocialIcon
            name="facebook-monochrome"
            size="xl"
            className="text-content-subdued"
          />
        </Button>
      </FacebookShareButton>
      <TwitterShareButton className="flex-1" url={url} title={title}>
        <Button as="div" variant="secondaryNeutral" fullWidth>
          <SvgSocialIcon
            name="twitter-monochrome"
            size="xl"
            className="text-content-subdued"
          />
        </Button>
      </TwitterShareButton>
      <LinkedinShareButton className="flex-1" url={url} title={title}>
        <Button as="div" variant="secondaryNeutral" fullWidth>
          <SvgSocialIcon
            name="linked-in-monochrome"
            size="xl"
            className="text-content-subdued"
          />
        </Button>
      </LinkedinShareButton>
      <WhatsappShareButton className="flex-1" url={url} title={title}>
        <Button as="div" variant="secondaryNeutral" fullWidth>
          <SvgSocialIcon
            name="whatsapp-monochrome"
            size="xl"
            className="text-content-subdued"
          />
        </Button>
      </WhatsappShareButton>
      <EmailShareButton className="flex-1" url={url} title={title}>
        <Button as="div" variant="secondaryNeutral" fullWidth>
          <SvgSocialIcon
            name="mail-monochrome"
            size="xl"
            className="text-content-subdued"
          />
        </Button>
      </EmailShareButton>
    </div>
  )
}
