import {
  PaginatedPost,
  PaginatedSpace,
  PaginatedMember,
  Post,
  Space,
  Member,
  ExternalSearchResult,
} from '@tribeplatform/gql-client/types'

import { HistoryItem } from './components/index.js'

export const SmartSearchEntityType = {
  POSTS: 'posts',
  MEMBERS: 'members',
  SPACES: 'spaces',
  EXTERNALS: 'externals',
} as const

export const ExtendedSmartSearchEntityType = {
  ...SmartSearchEntityType,
  HISTORY: 'history',
} as const

export type ExtendedSmartSearchEntityType =
  (typeof ExtendedSmartSearchEntityType)[keyof typeof ExtendedSmartSearchEntityType]

export type SmartSearchEntityType =
  (typeof SmartSearchEntityType)[keyof typeof SmartSearchEntityType]

export type SmartSearchEntity = Post | Member | Space | ExternalSearchResult

export type SmartSearchEntityWithUuid = SmartSearchEntity & {
  uuid: string
}

export type ExtendedSmartSearchEntity = SmartSearchEntity | HistoryItem

export type SmartSearchResult =
  | PaginatedPost
  | PaginatedMember
  | PaginatedSpace
  | ExternalSearchResult[]

export type SmartSearchPaginatedEntity =
  | PaginatedPost
  | PaginatedMember
  | PaginatedSpace

export const RESULTS_VIEWS = ['search-page', 'command-bar'] as const
export type ResultsView = (typeof RESULTS_VIEWS)[number]

export type SmartSearchFilters = {
  query: string
  type?: SmartSearchEntityType
  askAIQuery: string
  tagsId?: string[]
  date?: string | null
  spaceIds?: string[]
}

export const SmartSearchTabs = {
  SEARCH: 'search',
  ASK_AI: 'ask-ai',
} as const

export type SmartSearchTabsType =
  (typeof SmartSearchTabs)[keyof typeof SmartSearchTabs]
export type LegacySearchResultsType = {
  posts: Post[]
  spaces: Space[]
  members: Member[]
  externals: ExternalSearchResult[]
}

export type LegacySearchQueryResult<T extends LegacySearchEntity> = {
  data: T[]
  isLoading: boolean
  isError: boolean
  isFetching: false
}

export type LegacySearchEntity = Post | Space | Member | ExternalSearchResult
