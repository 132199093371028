import { ReactNode } from 'react'

import { clsx } from 'clsx'

import { BackgroundProvider } from '../BackgroundContext/index.js'
import { useFrameContext } from '../Frame/FrameContext.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { getContainerMaxSize, getContainerPadding } from '../Layout/utils.js'
import { TopBarButton } from './TopBarButton.js'
import { TopBarInput } from './TopBarInput.js'

type TopBarProps = {
  topLine?: ReactNode
  center?: ReactNode
  controls: ReactNode
  /** Toggles whether or not a navigation component has been provided. Controls the presence of the mobile nav toggle button */
  mobileSidebarEnable?: boolean
  /** A callback function that handles hiding and showing mobile navigation */
  toggleMobileSidebar?: () => void
  className?: string
}

/**
 * TopBar is a header component that allows to search, access menus, and navigate by clicking on the logo.
 * It’s always visible at the top of interfaces
 */
export const TopBar = ({
  topLine,
  center,
  controls,
  mobileSidebarEnable = true,
  toggleMobileSidebar,
  className,
  ...rest
}: TopBarProps) => {
  const { logo, fullWidth } = useFrameContext()

  const topLineMarkup = topLine ? (
    <div
      className={clsx(
        'hidden lg:flex bg-topbar-subdued',
        'transition duration-200',
        getContainerPadding({ horizontal: { size: 'md' } }),
      )}
    >
      <div
        className={clsx(
          'flex items-center h-10 w-full space-s-5 mx-auto',
          !fullWidth && 'max-w-8xl',
        )}
      >
        {topLine}
      </div>
    </div>
  ) : null

  const toggleMobileSidebarMarkup = (
    <>
      <button
        type="button"
        onClick={toggleMobileSidebar}
        className="lg:hidden me-3"
        aria-label="Navigation"
      >
        <SvgIcon size="2x" name="menu" />
      </button>
    </>
  )

  return (
    <div
      className={clsx(
        'bg-topbar text-content-on-topbar lg:overflow-y-visible main-navbar',
        'transition duration-200',
        className,
      )}
      {...rest}
    >
      <BackgroundProvider backgroundType="secondary">
        {topLineMarkup}
        <div
          className={clsx(getContainerPadding({ horizontal: { size: 'md' } }))}
        >
          <div
            className={clsx(
              'mx-auto',
              getContainerMaxSize(fullWidth ? 'full' : 'xl'),
            )}
          >
            <div className="grid grid-cols-12 h-16 gap-5">
              <div
                className={clsx(
                  'flex items-center',
                  center ? 'col-span-3' : 'col-span-9',
                )}
              >
                {mobileSidebarEnable && toggleMobileSidebarMarkup}
                <div className="block sm:hidden">{logo?.square}</div>
                <div className="hidden sm:block">{logo?.plain}</div>
              </div>

              {center && (
                <div className="col-span-6 flex items-center justify-center">
                  <div className="hidden lg:flex grow items-center justify-center space-s-3">
                    {center}
                  </div>
                </div>
              )}

              <div className="col-span-3 flex items-center gap-2 justify-end">
                <div className="flex items-center gap-2 flex-shrink-0">
                  {controls}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundProvider>
    </div>
  )
}

TopBar.Button = TopBarButton
TopBar.Input = TopBarInput
