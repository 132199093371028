import type { SubscriptionInformation } from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSubscriptionInformationKey } from '../../utils/keys/billing.js'

export const useSubscriptionInformation = (options?: {
  useQueryOptions?: UseQueryOptions<SubscriptionInformation>
}) => {
  const { useQueryOptions } = options || {}
  const { client } = useTribeClient()
  const subscriptionInformationKey = getSubscriptionInformationKey()

  return useQuery<SubscriptionInformation>(
    subscriptionInformationKey,
    () =>
      client.query({
        name: 'subscriptionInformation',
        args: {
          addons: 'all',
          currentProduct: 'all',
        },
      }),
    useQueryOptions,
  )
}
