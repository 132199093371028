import { ComponentProps } from 'react'

import { Link } from 'react-router-dom'

import { NotFound as NotFoundComponent } from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props extends Partial<ComponentProps<typeof NotFoundComponent>> {}

export const NotFound = ({
  title,
  description,
  icon,
  children,
  ...rest
}: Props) => {
  return (
    <NotFoundComponent
      {...rest}
      icon={icon || <SvgIcon name="post" />}
      title={
        title || (
          <T defaultMessage="Page not found!" id="Generics.PageNotFound" />
        )
      }
      description={
        description || (
          <T
            defaultMessage="We were not able to find the page you’re looking for."
            id="Generics.PageNotFound.Explained"
          />
        )
      }
    >
      {children ?? (
        <Button variant="secondaryNeutral" as={Link} to="/">
          <T defaultMessage="Home" id="Generics.Home" />
        </Button>
      )}
    </NotFoundComponent>
  )
}
