import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationDeleteSpacePostTypesArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'
import { getSpacesKey, getSpaceKey } from '../../utils/keys/space.key.js'
import { getSpacePostTypesKey } from '../../utils/keys/spacePostType.key.js'

export const useDeleteSpacePostTypes = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationDeleteSpacePostTypesArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationDeleteSpacePostTypesArgs>(
    input => client.spacePostType.delete(input),
    {
      onSettled: () => {
        const spacePostTypeKey = getSpacePostTypesKey()
        queryClient.invalidateQueries(spacePostTypeKey)

        queryClient.invalidateQueries(getSpacesKey())

        queryClient.invalidateQueries(getSpaceKey())

        queryClient.invalidateQueries(getNetworkKey())
      },
      ...useMutationOptions,
    },
  )
}
