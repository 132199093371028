import { gql } from 'graphql-tag'

export const PARTICIPANT_FIELDS = `
  _id
  memberId
  name
  handle
  avatarUrl
  status
  role
  archivedAt
  chatId
`

export const MESSAGE_META_FIELDS = `
  ... on AddedParticipantsMetadata {
    addedParticipants {
      ${PARTICIPANT_FIELDS}
    }
    type
  }
  ... on CreatedGroupMetadata {
    type
  }
  ... on EditedGroupMetadata {
    avatarUrl
    avatarId
    name
    type
  }
  ... on RemovedParticipantsMetadata {
    removedParticipants {
      ${PARTICIPANT_FIELDS}
    }
    type
  }
  ... on LeftGroupMetadata {
    type
  }
  ... on PromotedAdminsMetadata {
    promotedParticipants {
      ${PARTICIPANT_FIELDS}
    }
    type
  }
  ... on DemotedAdminsMetadata {
    demotedParticipants {
      ${PARTICIPANT_FIELDS}
    }
    type
  }
  ... on TransferredOwnershipMetadata {
    newOwner {
      ${PARTICIPANT_FIELDS}
    }
    type
  }
`

const CURRENT_PARTICIPANT_FIELDS = `
  ${PARTICIPANT_FIELDS}
  lastReadMessage {
    pageId
    messageId
    signature
  }

`

const CHAT_FIELDS = `
  networkId
  _id
  name
  type
  status
  createdAt
  createdById
  updatedAt
  avatarId
  avatarUrl
  lastMessage {
    pageId
    messageId
    sentAt
    addressSignature
    preview
    authorId
    authorName
    attachmentPreview {
      id
      name
      extension
      size
    }
    attachmentsCount
    imagePreview {
      id
      name
      iconUrl
    }
    imagesCount
    embedPreview {
      id
      title
      type
      url
      providerName
      iconUrl
      description
    }
    embedsCount
    meta {
      ${MESSAGE_META_FIELDS}
    }
  }
  currentParticipant {
    ${CURRENT_PARTICIPANT_FIELDS}
  }
  otherParticipant {
    ${PARTICIPANT_FIELDS}
  }
`

const CHATS_FIELDS = `
  pageInfo {
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
  data {
    ${CHAT_FIELDS}
  }
`

const PARTICIPANTS_FIELDS = `
  pageInfo {
    endCursor
    hasNextPage
  }
  data {
    ${PARTICIPANT_FIELDS}
  }
`

export const chatGQLQuery = () => gql`
  query chat(
    $id: String!
  ) {
    chat(id: $id) {
      ${CHAT_FIELDS}
    }
  }
`

export const findChatWithMemberGQLQuery = () => gql`
  query findChatWithMember(
    $recipientId: String!
  ) {
    findChatWithMember(recipientId: $recipientId) {
      ${CHAT_FIELDS}
    }
  }
`

export const chatIdFromSlugGQLQuery = () => gql`
  query chatIdFromSlug($slug: String!) {
    chatIdFromSlug(slug: $slug) {
      chatId
      slug
    }
  }
`

export const checkMembersContactabilityGQLQuery = () => gql`
  query checkMembersContactability($memberIds: [String!]!) {
    checkMembersContactability(memberIds: $memberIds) {
      data {
        memberId
        status
      }
    }
  }
`

export const chatsGQLQuery = () => gql`
  query chats(
    $direction: QueryDirection
    $limit: Int!
    $messageId: String
    $timestamp: Int
    $archived: Boolean!
  ) {
    chats(
      direction: $direction
      limit: $limit
      messageId: $messageId
      timestamp: $timestamp
      archived: $archived
    ) {
      ${CHATS_FIELDS}
    }
  }
`

export const archivedChatsGQLQuery = () => gql`
  query archivedchats(
    $limit: Int!
    $archived: Boolean!
  ) {
    chats(
      limit: $limit
      archived: $archived
    ) {
      ${CHATS_FIELDS}
    }
  }
`

export const chatsByCursorGQLQuery = () => gql`
  query chatsByCursor(
    $cursor: String!
    $limit: Int!
  ) {
    chatsByCursor(
      cursor: $cursor
      limit: $limit
    ) {
      ${CHATS_FIELDS}
    }
  }
`

export const unreadChatsCountGQLQuery = () => gql`
  query unreadChatsCount($archived: Boolean) {
    unreadChatsCount(archived: $archived) {
      memberId
      unreadChatsCount
      unreadChatIds
    }
  }
`

export const createGroupChatGQLMutation = () => gql`
  mutation createGroupChat(
    $input: CreateGroupChatInput!
  ) {
    createGroupChat(input: $input) {
      ${CHAT_FIELDS}
    }
  }
`

export const updateGroupChatGQLMutation = () => gql`
  mutation updateGroupChat($chatId: String!, $input: UpdateGroupChatInput!) {
    updateGroupChat(chatId: $chatId, input: $input) {
      name
      avatarId
      avatarUrl
    }
  }
`

export const addParticipantsToGroupChatGQLMutation = () => gql`
  mutation addMembersToGroupChat(
    $chatId: String!
    $input: AddMembersToGroupInput!
  ) {
    addMembersToGroup(chatId: $chatId, input: $input) {
      status
    }
  }
`

export const removeGroupChatParticipantsGQLMutation = () => gql`
  mutation removeGroupChatParticipants(
    $chatId: String!
    $input: RemoveMembersFromGroupInput!
  ) {
    removeMembersFromGroup(chatId: $chatId, input: $input) {
      status
    }
  }
`

export const promoteChatAdminsGQLMutation = () => gql`
  mutation promoteChatAdmins(
    $chatId: String!
    $input: PromoteChatAdminsInput!
  ) {
    promoteChatAdmins(chatId: $chatId, input: $input) {
      status
    }
  }
`

export const demoteChatAdminsGQLMutation = () => gql`
  mutation demoteChatAdmins($chatId: String!, $input: DemoteChatAdminsInput!) {
    demoteChatAdmins(chatId: $chatId, input: $input) {
      status
    }
  }
`

export const transferGroupChatOwnershipGQLMutation = () => gql`
  mutation transferGroupChatOwnership(
    $chatId: String!
    $input: TransferGroupChatOwnershipInput!
  ) {
    transferGroupChatOwnership(chatId: $chatId, input: $input) {
      status
    }
  }
`
export const chatParticipantsGQLQuery = () => gql`
  query chatParticipants($chatId: String!, $limit: Int!) {
    chatParticipants(chatId: $chatId, limit: $limit) {
      ${PARTICIPANTS_FIELDS}
    }
  }
`

export const chatParticipantsByCursorGQLQuery = () => gql`
  query chatParticipantsByCursor(
    $chatId: String!
    $cursor: String!
    $limit: Int!
  ) {
    chatParticipantsByCursor(
      chatId: $chatId
      cursor: $cursor
      limit: $limit
    ) {
      ${PARTICIPANTS_FIELDS}
    }
  }
`

export const chatParticipantsByIdsGQLQuery = () => gql`
  query chatParticipantsByIds($chatId: String!, $participantIds: [String!]!) {
    chatParticipantsByIds(chatId: $chatId, participantIds: $participantIds) {
      ${PARTICIPANT_FIELDS}
    }
  }
`

export const archiveChatGQLMutation = () => gql`
  mutation archiveChat($chatId: String!) {
    archiveChat(chatId: $chatId) {
      status
    }
  }
`

export const unarchiveChatGQLMutation = () => gql`
  mutation unarchiveChat($chatId: String!) {
    unarchiveChat(chatId: $chatId) {
      status
    }
  }
`

export const leaveGroupChatGQLMutation = () => gql`
  mutation leaveGroupChat($chatId: String!) {
    leaveGroupChat(chatId: $chatId) {
      status
    }
  }
`
