import { useTribeClient } from '@tribeplatform/react-sdk'

import { AskAISettingsCard } from './AskAISettingsCard.js'
import { FederatedSearchCard } from './FederatedSearchCard.js'

export const SearchSettings = () => {
  const {
    config: { enableAiSearch: isAiSearchEnabled },
  } = useTribeClient()
  return (
    <div className="flex flex-col space-y-5 max-w-3xl">
      {isAiSearchEnabled && <AskAISettingsCard />}
      <FederatedSearchCard />
    </div>
  )
}
