import { createClient, type Client } from 'graphql-ws'

import { RuntimeConfigs } from '@tribeplatform/web/configs/runtime.js'

import { GraphqlClient } from '../graphql.client.js'

export class WebSocketClient {
  private client: GraphqlClient

  private wsClient: Client

  constructor(client: GraphqlClient) {
    if (typeof window === 'undefined') {
      return
    }

    this.client = client
    // Use WS_URL from environment variables
    const wsUrl =
      RuntimeConfigs.WS_ENDPOINT ??
      (RuntimeConfigs.GQL_ENDPOINT ?? '').replace('https://', 'wss://')
    if (!wsUrl) {
      throw new Error(
        'WS_ENDPOINT & GQL_ENDPOINT environment variable is not defined',
      )
    }

    this.wsClient = createClient({
      url: wsUrl,
      connectionParams: {
        Authorization: `Bearer ${this.client.accessToken}`,
      },
    })
  }

  subscribe<T = unknown>({
    query,
    variables,
    onNext,
    onError,
    onComplete,
  }: {
    query: string
    variables?: Record<string, unknown>
    onNext: (data: T) => void
    onError?: (error: Error) => void
    onComplete?: () => void
  }): () => void {
    const unsubscribe = this.wsClient.subscribe(
      {
        query,
        variables,
      },
      {
        next: result => {
          if (result.data) {
            onNext(result.data as T)
          }
          if (result.errors) {
            onError?.(new Error(result.errors.map(e => e.message).join(', ')))
          }
        },
        error: error => {
          if (error instanceof Error) {
            onError?.(error)
          } else if (typeof error === 'string') {
            onError?.(new Error(error))
          } else {
            onError?.(new Error('Unknown websocket error occurred'))
          }
        },
        complete: () => {
          onComplete?.()
        },
      },
    )

    return unsubscribe
  }

  async *iterate<T = unknown>({
    query,
    variables,
  }: {
    query: string
    variables?: Record<string, unknown>
  }): AsyncIterableIterator<T> {
    const iterator = this.wsClient.iterate<{
      data?: T
      errors?: Array<{ message: string }>
    }>({
      query,
      variables,
    })

    // eslint-disable-next-line no-restricted-syntax
    for await (const result of iterator) {
      if (result.data) {
        yield result.data as T
      }
      if (result.errors) {
        throw new Error(result.errors.map(e => e.message).join(', '))
      }
    }
  }

  // Method to cleanup/close the websocket connection
  disconnect(): void {
    this.wsClient.dispose()
  }
}
