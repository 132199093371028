import { SvgIcon } from '../Icon/SvgIcon.js'
import { Input, InputProps } from './Input.js'

export type SearchInputProps = InputProps & {
  loading?: boolean
}

export const SearchInput = ({
  placeholder,
  loading,
  disabled,
  ...rest
}: SearchInputProps) => {
  const leadingIcon = loading ? (
    <SvgIcon className="animate-spin" name="spinner" />
  ) : (
    <SvgIcon name="search" />
  )

  return (
    <Input
      type="search"
      placeholder={placeholder || 'Search...'}
      disabled={disabled}
      leadingAddon={leadingIcon}
      {...rest}
    />
  )
}
