import { ReactNode, useEffect, useMemo } from 'react'

import { Link, matchPath } from 'react-router-dom'

import { SlateLayout } from '@tribeplatform/react-components/LayoutFrame'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Container } from '@tribeplatform/react-ui-kit/Layout'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'
import { convertNetworkToAppearance } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { useLogin } from '../../hooks/useLogin.js'
import { PageLayout } from '../Layout/PageLayout.js'
import { getSettingsSidebarItems } from './utils.js'

export const SettingsLayout = ({ children }: { children: ReactNode }) => {
  const pathname = useRouterPath()
  const decisionConnectedApps = false
  const { data: network } = useNetwork()
  const appearance = convertNetworkToAppearance({
    oldColors: network?.themes?.active?.tokens?.colors,
    networkThemeLightColors: network?.activeTheme?.colors?.light,
    networkThemeDarkColors: network?.activeTheme?.colors?.dark,
    networkThemeTypography: network?.activeTheme?.typography,
  })
  const isMessagingEnabled =
    network?.settings?.privateMessaging?.privateMessagingEnabled

  const { isLoggedIn, showLogin } = useLogin()

  useEffect(() => {
    if (!isLoggedIn) {
      showLogin()
    }
  }, [isLoggedIn, showLogin])

  const items = useMemo(
    () =>
      getSettingsSidebarItems({
        isMessagingEnabled,
        isConnectedAppsEnabled: decisionConnectedApps,
        isAppearanceEnabled:
          appearance.networkColorScheme.mode === 'user-preference',
      }),
    [isMessagingEnabled, decisionConnectedApps, appearance],
  )

  return (
    <PageLayout layout={SlateLayout.headerNoSidebar}>
      <Container
        size="full"
        padding={{
          vertical: 'md',
          horizontal: { size: 'md', disableOnMobile: true },
        }}
      >
        <Container size="xl" direction="grid">
          <Container size="sm" padding={{ size: 'md', onlyOnMobile: true }}>
            <NavVertical>
              <NavVertical.Group>
                {items.map(item => {
                  const active = matchPath(
                    {
                      path: item.link,
                      end: true,
                      caseSensitive: true,
                    },
                    pathname,
                  )

                  return (
                    <NavVertical.Item
                      key={item.link}
                      active={!!active}
                      as={Link}
                      leadingIcon={item.icon}
                      to={item.link}
                    >
                      {item.text}
                    </NavVertical.Item>
                  )
                })}
              </NavVertical.Group>
            </NavVertical>
          </Container>
          <Container size="md">{children}</Container>
        </Container>
      </Container>
    </PageLayout>
  )
}
