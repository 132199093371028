import { useMemo } from 'react'

import { TableOfContents } from '../Composer/extensions/table-of-content/components/TableOfContent.js'

interface Props {
  content: string
}

const HEADER_REGEX = /<h([1-6])[^>]*>([\s\S]*?)<\/h[1-6]>/g

/**
 * We avoid using DOMParser for HTML entity decoding because:
 * 1. DOMParser is only available in browser environments, not in Node.js
 * 2. This causes SSR compatibility issues as the code needs to run on the server
 *
 * Instead, we use a simple mapping of common HTML entities that:
 * - Works consistently across all environments (browser and Node.js)
 * - Is lightweight and performant
 * - Doesn't require additional dependencies
 */
const HTML_ENTITIES = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#039;': "'",
  '&#x27;': "'",
  '&nbsp;': ' ',
} as const

// Pre-compile RegExp objects
const ENTITY_PATTERNS = Object.fromEntries(
  Object.keys(HTML_ENTITIES).map(entity => [entity, new RegExp(entity, 'g')]),
)

const decodeHtmlEntities = (text: string): string => {
  return Object.entries(HTML_ENTITIES).reduce(
    (str, [entity, char]) => str.replace(ENTITY_PATTERNS[entity], char),
    text,
  )
}

export function TableOfContentRenderer({ content }: Props) {
  const headings = useMemo(() => {
    const matches = Array.from(content.matchAll(HEADER_REGEX))

    return matches.map((match, index) => {
      const [fullMatch, level, text] = match
      return {
        id: fullMatch.match(/id="([^"]*)"/)?.[1] || `heading-${index}`,
        itemIndex: index + 1,
        textContent: decodeHtmlEntities(text.replace(/<[^>]*>/g, '')),
        level: parseInt(level, 10),
      }
    })
  }, [content])

  if (headings.length < 1) {
    return null
  }

  return <TableOfContents content={headings} />
}
