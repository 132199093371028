import { useState } from 'react'

import { Post, Space } from '@tribeplatform/gql-client/types'
import { useMovePost, useSpace } from '@tribeplatform/react-sdk/hooks'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { logger } from '../common/lib/logger.js'
import { Form } from '../Form/index.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'

export type MovePostModalProps = {
  post: Post
  open: boolean
  onClose: () => void
}

export const MovePostModal = ({ post, open, onClose }: MovePostModalProps) => {
  const { $t } = useI18n()
  const [space, setSpace] = useState<Space>()

  const isSameSpace = space?.id && post?.space?.id === space?.id
  const isPrivate = space?.private

  const { data: fullSpace } = useSpace({
    variables: {
      id: space?.id,
    },
    fields: {
      authMemberProps: {
        availablePostTypes: 'all',
      },
    },
    useQueryOptions: {
      enabled: !!space?.id,
    },
  })
  const { mutateAsync: movePost, isLoading } = useMovePost()

  const isFullSpaceLoaded =
    typeof fullSpace?.authMemberProps?.availablePostTypes !== 'undefined'
  const isPostTypeSupported =
    !!fullSpace?.authMemberProps?.availablePostTypes?.find(
      postType => postType?.id === post?.postTypeId,
    )

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        title={$t({
          id: 'Post.MovePostModal.HeaderTitle',
          defaultMessage: 'Move post to another {SPACE}',
        })}
      />
      <Modal.Content>
        <Form<{ target?: Space; sendNotification: boolean }>
          onSubmit={async data => {
            if (!post?.id || !space?.id) {
              return
            }
            try {
              await movePost({
                id: post?.id,
                spaceId: space?.id,
                sendNotification: data.sendNotification,
              })
              toast({
                title: $t({
                  id: 'Post.MovePostModal.PostMovedSuccessTitle',
                  defaultMessage: 'The post was moved successfully.',
                }),
                status: 'success',
              })
              onClose()
            } catch (err) {
              toast({
                title: $t({
                  id: 'Post.MovePostModal.PostMoveFailedTitle',
                  defaultMessage: 'Unable to move the post at this time.',
                }),
                status: 'error',
              })
              logger.error('Move post failed', err)
            }
          }}
          className="flex flex-col space-y-5"
          defaultValues={{
            target: space,
          }}
        >
          <Form.SpacePicker
            label={$t({
              id: 'Generics.MoveTo',
              defaultMessage: 'Move to',
            })}
            onChange={space => setSpace(space)}
            name="target"
            placeholder={$t({
              id: 'Generics.SelectSpace',
              defaultMessage: 'Select a {SPACE}...',
            })}
          />
          {isSameSpace && (
            <Alert status="info">
              <T
                id="Post.MovePostModal.PostAlreadySameSpaceHint"
                defaultMessage="This post is already in this {SPACE}. Please select another {SPACE}."
              />
            </Alert>
          )}
          {!isSameSpace && isPrivate && (
            <Alert status="info">
              <T
                id="Post.MovePostModal.MovingPostToPrivateSpaceHint"
                defaultMessage="You are moving this post to a private {SPACE}. This means the author or participants of this post may not be able to access the post."
              />
            </Alert>
          )}
          {!isPostTypeSupported && isFullSpaceLoaded && (
            <Alert status="warning">
              <T
                id="Post.MovePostModal.PostTypeNotSupportedHint"
                defaultMessage="You cannot move the post since the post type is not supported in this {SPACE}."
              />
            </Alert>
          )}
          <Form.Checkbox
            label={$t({
              id: 'Post.MovePostModal.SendNotifToDestinationMembersCheckBoxLabel',
              defaultMessage:
                'Send notification to the destination {SPACE} {MEMBERS}',
            })}
            name="sendNotification"
          />
          <Form.Actions>
            <Button
              type="submit"
              disabled={
                !space?.id ||
                isSameSpace ||
                !isFullSpaceLoaded ||
                !isPostTypeSupported
              }
              loading={isLoading || (space?.id && !isFullSpaceLoaded)}
            >
              <T id="Generics.Move" defaultMessage="Move" />
            </Button>
            <Button variant="secondaryNeutral" onClick={() => onClose()}>
              <T id="Generics.Cancel" defaultMessage="Cancel" />
            </Button>
          </Form.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
