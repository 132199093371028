import type { PageFields } from '@tribeplatform/gql-client/graphql'
import type { Page, QueryPageArgs } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPageKey, getSpaceKey } from '../../utils/keys/space.key.js'

export const usePage = (options: {
  variables: QueryPageArgs
  fields?: PageFields
  useQueryOptions?: UseQueryOptions<Page>
}) => {
  const { variables, fields = 'default', useQueryOptions } = options
  const { client } = useTribeClient()
  const pageKey = getPageKey({ variables, fields })
  const queryClient = useQueryClient()

  return useQuery<Page>(
    pageKey,
    () => client.spaces.getByPath(variables, fields),
    {
      onSuccess(data) {
        if (data?.space?.id) {
          const spaceKey = getSpaceKey({
            fields: 'default',
            variables: { id: data?.space?.id },
          })
          queryClient.setQueryData(spaceKey, data?.space)
        }
      },
      ...useQueryOptions,
    },
  )
}
