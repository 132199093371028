import React, { useMemo, useRef, useState, useEffect, Fragment } from 'react'

import { nanoid } from 'nanoid'

import {
  CustomFieldType,
  CustomFieldPrivacyOptions,
  DateTypeOptions,
} from '@tribeplatform/gql-client/types'
import type {
  Member,
  CustomFieldSchema,
  Space,
  Tag,
  CustomField,
} from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useAuthMember,
  useAuthToken,
  useNetwork,
  useUpdateMember,
} from '@tribeplatform/react-sdk/hooks'
import { Accordion } from '@tribeplatform/react-ui-kit/Accordion'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { ConfirmDiscard } from '../common/components/ConfirmDiscard.js'
import { logger } from '../common/lib/logger.js'
import {
  convertTzToUtc,
  convertUtcToTz,
  formatUtcDateTime,
} from '../common/utils/date.js'
import {
  CustomFieldSubtype,
  FieldInput,
  getFieldSetting,
} from '../CustomField/index.js'
import { FormImage } from '../Form/FormImage/FormImage.js'
import { Form } from '../Form/index.js'
import type { FormRef } from '../Form/index.js'
import { extractFieldsErrors } from '../Form/utils.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'

const parseCustomFieldValue = (
  field: string,
): string | string[] | Member | Space | Tag | boolean | number => {
  try {
    return JSON.parse(field)
  } catch (error) {
    logger.error('could not parse custom field.')
    return null
  }
}

export type ProfileSettingsModalProps = {
  member: Member
  open: boolean
  onClose: () => void
}

export const ProfileSettingsModal = ({
  member,
  open,
  onClose,
}: ProfileSettingsModalProps) => {
  const { Link } = useRouter()
  const { $t } = useI18n()
  const formRef = useRef<FormRef>()
  const [fieldErrors, setFieldErrors] =
    useState<Record<string, { message: string; itemIndex?: number }>>()
  const { mutateAsync: updateMember, isLoading } = useUpdateMember()
  const {
    data: { network },
  } = useAuthToken()
  const { isAdmin } = useAuthMember()
  const {
    data: {
      memberFields: { fields: networkMemberFields },
    },
  } = useNetwork()
  const settingsUrl = network?.activeSso?.settingsUrl

  const memberFields = member?.fields?.reduce<CustomField>(
    (memberFields, currentField) => {
      const parsedValue = parseCustomFieldValue(currentField.value)
      if (!parsedValue) {
        return { ...memberFields, [currentField.key]: parsedValue }
      }

      if (Array.isArray(parsedValue)) {
        return {
          ...memberFields,
          [currentField.key]: parsedValue.map(singleValue => ({
            value: singleValue,
            id: nanoid(10),
            invalid: false,
          })),
        }
      }

      const memberField = networkMemberFields.find(
        ({ key }) => key === currentField.key,
      )
      if (!memberField) {
        return memberFields
      }

      const { type } = memberField
      if (type === CustomFieldType.date) {
        const subType = getFieldSetting(
          memberField,
          'subtype',
        ) as CustomFieldSubtype
        if (subType === CustomFieldSubtype.DATETIME) {
          const value = convertUtcToTz(parsedValue as string)
          return { ...memberFields, [currentField.key]: value }
        }

        const value = formatUtcDateTime(parsedValue as string, 'YYYY-MM-DD')
        return { ...memberFields, [currentField.key]: value }
      }

      return { ...memberFields, [currentField.key]: parsedValue }
    },
    {} as CustomField,
  )

  const defaultValues = useMemo(() => {
    return {
      ...member,
      ...memberFields,
    }
  }, [member, memberFields])

  useEffect(() => {
    if (!fieldErrors) {
      return
    }

    const errorKeys = Object.keys(fieldErrors)

    if (errorKeys.length === 0) {
      return
    }

    const hasMultiSelectErrors = errorKeys.find(fieldKey => {
      const error = fieldErrors[fieldKey]
      const currentValue = formRef?.current?.methods.getValues()[fieldKey]

      const itemIndex = error?.itemIndex
      return (
        typeof itemIndex === 'number' && !currentValue?.[itemIndex]?.invalid
      )
    })

    if (formRef.current?.methods && hasMultiSelectErrors) {
      const updatedFields = errorKeys.reduce(
        (customFields, fieldKey) => {
          const updatedField = defaultValues[fieldKey]?.map((value, index) => {
            return {
              ...value,
              invalid: index === fieldErrors[fieldKey]?.itemIndex,
            }
          })

          return {
            ...customFields,
            [fieldKey]: updatedField,
          }
        },
        { ...defaultValues },
      )

      formRef?.current?.methods?.reset(updatedFields)
    }
  }, [defaultValues, fieldErrors])

  const getValue = (field: CustomFieldSchema, fields: typeof defaultValues) => {
    const { key, type } = field
    const value = fields[key]
    if (!value) {
      return value
    }

    if (type === CustomFieldType.array) {
      return value?.map(field => field?.value ?? field)
    }

    if (type === CustomFieldType.date) {
      const subType = getFieldSetting(field, 'subtype') as CustomFieldSubtype
      if (subType === CustomFieldSubtype.DATETIME) {
        return convertTzToUtc(value)
      }

      const dateType = field?.typeOptions?.dateType
      if (dateType === DateTypeOptions.datetime) {
        return formatUtcDateTime(value)
      }

      return formatUtcDateTime(fields[key], 'YYYY-MM-DD')
    }

    return value
  }

  const onSubmit = async (
    { name, tagline, profilePicture, ...fields }: typeof defaultValues,
    { setError },
  ) => {
    const mappingFields = [
      ...network?.memberFields?.fields
        ?.filter(
          ({ key }) =>
            fields[key] !== null && typeof fields[key] !== 'undefined',
        )
        .map(field => {
          const { key } = field
          const value = getValue(field, fields)

          return {
            key,
            value: JSON.stringify(value === '' ? null : value),
          }
        }),
    ]

    const input = {
      name,
      tagline,
      profilePictureId: profilePicture?.id,
      fields: mappingFields,
    }

    return updateMember({
      id: member?.id,
      input,
    })
      .then(() => {
        onClose()
        toast({ title: 'Profile updated successfully', status: 'success' })
      })
      .catch(error => {
        const fieldsErrors = extractFieldsErrors(error)
        if (Object.keys(fieldsErrors).length > 0) {
          setFieldErrors({ ...fieldsErrors })
          return
        }

        const errors =
          error?.response?.errors.filter(error => error?.field) || []
        if (errors.length > 0) {
          errors.forEach(error => {
            // If it's a field error, add `fields.` prefix to the field name
            const findErrorFieldName = (errorField: string) => {
              const field = mappingFields.find(
                (field, index) => errorField === `fields.${index}.value`,
              )
              if (field) {
                return field.key
              }

              return errorField
            }

            const errorField = findErrorFieldName(error.field)

            setError(errorField, {
              message: error?.message,
              type: 'validate',
            })
          })
        } else {
          const message = error?.response?.errors
            ?.map(e => e.message)
            ?.join('\n')
          toast({
            title: $t({
              id: 'Generics.Error',
              defaultMessage: 'Error',
            }),
            description:
              message ||
              $t({
                id: 'Generics.SomethingWentWrongTryAgain',
                defaultMessage: 'Something went wrong, please try again.',
              }),
            status: 'error',
          })
        }
      })
  }

  const groupedFields =
    network?.memberFields?.fields?.reduce((groups, cur) => {
      const section = getFieldSetting(cur, 'section') || ''
      if (!groups[section]) {
        groups[section] = []
      }
      groups[section].push(cur)
      return groups
    }, {}) || {}

  return (
    <ConfirmDiscard onConfirm={onClose}>
      {({ discard }) => (
        <Modal open={open} onClose={discard} size="xl">
          <Form
            ref={formRef}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="flex flex-col min-h-0 w-full"
          >
            <Modal.Header
              title={$t({
                defaultMessage: 'Edit profile',
                id: 'Generics.EditProfile',
              })}
            />
            <Modal.Content>
              <ConfirmDiscard.FormDirty />
              <Form.Layout>
                <div className="flex flex-col space-y-3">
                  <div className="mx-auto mb-5">
                    <FormImage
                      name="profilePicture"
                      variant="avatar"
                      cropable
                      multiple={false}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-5">
                    <Form.Input
                      name="name"
                      autoFocus
                      label={
                        settingsUrl ? (
                          <div className="flex justify-between items-center">
                            <span>
                              <T
                                id="Generics.Name.Noun"
                                defaultMessage="Name"
                              />
                            </span>
                            <Link
                              target="_blank"
                              href={settingsUrl}
                              className="text-sm"
                            >
                              <T
                                id="Generics.EditName"
                                defaultMessage="Edit Name"
                              />
                            </Link>
                          </div>
                        ) : (
                          $t({
                            id: 'Generics.Name.Noun',
                            defaultMessage: 'Name',
                          })
                        )
                      }
                      placeholder={$t({
                        defaultMessage: 'John Smith',
                        id: 'Generics.SampleName.Male',
                      })}
                      disabled={!!settingsUrl}
                    />
                    <Form.Input
                      name="tagline"
                      label={$t({
                        id: 'Generics.Tagline',
                        defaultMessage: 'Tagline',
                      })}
                      placeholder={$t({
                        defaultMessage: 'CEO at Acme',
                        id: 'Generics.SamplePosition',
                      })}
                    />
                    {Object.keys(groupedFields)
                      ?.sort((a, b) => {
                        return a > b ? 1 : -1
                      })
                      .map((sectionName, sectionIndex) => {
                        const fields: CustomFieldSchema[] =
                          groupedFields[sectionName]

                        const inputs = fields?.map(field => {
                          if (field?.archived) {
                            return null
                          }
                          if (
                            !isAdmin &&
                            field?.writePrivacy?.allow &&
                            field?.writePrivacy?.allow?.indexOf(
                              CustomFieldPrivacyOptions.OWN,
                            ) === -1
                          ) {
                            return null
                          }

                          const placeholder = getFieldSetting(
                            field,
                            'placeholder',
                          )
                          const helperText = getFieldSetting(
                            field,
                            'helperText',
                          )

                          return (
                            <FieldInput
                              key={field.key}
                              field={field}
                              label={field.name}
                              error={fieldErrors?.[field.key]?.message}
                              helperText={helperText}
                              placeholder={placeholder}
                            />
                          )
                        })

                        if (sectionName) {
                          return (
                            <Accordion key={sectionName}>
                              <Accordion.Button>{sectionName}</Accordion.Button>
                              <Accordion.Panel className="grid grid-cols-1 gap-5">
                                {inputs}
                              </Accordion.Panel>
                            </Accordion>
                          )
                        }
                        // The only key would be sectionName which is empty so we have to use index
                        // eslint-disable-next-line react/no-array-index-key
                        return <Fragment key={sectionIndex}>{inputs}</Fragment>
                      })}
                  </div>
                </div>
              </Form.Layout>
            </Modal.Content>
            <Modal.Footer>
              <Form.Actions className="pt-3">
                <Button type="submit" loading={isLoading} size="lg">
                  <T id="Generics.Update" defaultMessage="Update" />
                </Button>
                <Button variant="secondaryNeutral" onClick={discard} size="lg">
                  <T id="Generics.Cancel" defaultMessage="Cancel" />
                </Button>
              </Form.Actions>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </ConfirmDiscard>
  )
}
