import { HamburgerMenu } from '../mobileMenu/HamburgerMenu.js'
import { type NavbarProps } from '../types.js'
import { checkIfTrueCenter, extractSections } from '../utils/utils.js'
import { SectionRenderer } from './SectionRenderer.js'

interface Props {
  sections: NavbarProps['sections']
  mobileMenuSections: NavbarProps['mobileMenuSections']
  subNavigation: NavbarProps['subNavigation']
}
export const NavbarWrapper = ({
  sections,
  mobileMenuSections,
  subNavigation,
}: Props) => {
  const { menu: menuSection, actions: actionsSection } =
    extractSections(sections)
  const isLogoTrueCenter = checkIfTrueCenter(sections)

  return (
    <div className="flex h-16 items-center gap-x-2 sm:gap-x-8">
      <HamburgerMenu
        mobileMenuSections={mobileMenuSections}
        headerMenu={menuSection}
        actionsSection={actionsSection}
        headerSubmenu={subNavigation}
        fixedWidth={isLogoTrueCenter}
      />
      {sections
        .filter(({ enabled }) => !!enabled)
        .map(section => (
          <SectionRenderer
            key={section.id}
            section={section}
            logoTrueCenter={isLogoTrueCenter}
          />
        ))}
    </div>
  )
}
