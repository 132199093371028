import type {
  PaginatedSpaceJoinRequest,
  QuerySpaceJoinRequestsArgs,
} from '@tribeplatform/gql-client/types'
import { PaginatedSpaceJoinRequestFields } from '@tribeplatform/gql-client/types/generated'

import { useInfiniteQuery } from '../../index.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceJoinRequestKey } from '../../utils/keys/spaceMembership.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'
import { SpaceJoinRequestsInfiniteQueryOptions } from './types.js'

export const useSpaceJoinRequests = (options: {
  variables: QuerySpaceJoinRequestsArgs
  fields?: PaginatedSpaceJoinRequestFields
  useInfiniteQueryOptions?: SpaceJoinRequestsInfiniteQueryOptions
}) => {
  const { data: authMember } = useAuthMember()

  const { variables, fields = 'basic', useInfiniteQueryOptions } = options
  const { client } = useTribeClient()

  const { enabledForStaff = true } = useInfiniteQueryOptions ?? {}
  const key = getSpaceJoinRequestKey({ variables, fields })

  return useInfiniteQuery<PaginatedSpaceJoinRequest>(
    key,
    ({ pageParam: after }) =>
      client.query({
        name: 'spaceJoinRequests',
        args: {
          variables: {
            after,
            ...variables,
          },
          fields,
        },
      }),
    {
      ...useInfiniteQueryOptions,
      enabled: enabledForStaff && !!authMember?.staffReasons?.length,
    },
  )
}
