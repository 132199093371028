import { Action } from '@tribeplatform/gql-client/global-types'
import { type ClientError } from '@tribeplatform/gql-client/lib'
import { RoleType } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/index.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPingSessionKey } from '../../utils/keys/ping-session.keys.js'
import { useAuthMember } from './useAuthMember.js'

/**
 * The advertised limit is 1req/5m
 * The hard limit is 3req/5m
 * Setting the interval to 4 minutes should gives a good balance
 */
const THREE_MINUTES_IN_MS = 4 * 60 * 1000

export const usePingSession = (options?: {
  useMutationOptions?: UseQueryOptions<Action, ClientError>
}) => {
  const { useMutationOptions } = options || {}
  const { data: authMember } = useAuthMember()
  const { client } = useTribeClient()

  return useQuery<Action, ClientError>(
    getPingSessionKey(),
    () => client.auth.pingSession(),
    {
      ...useMutationOptions,
      refetchInterval: THREE_MINUTES_IN_MS,
      enabled: authMember && authMember?.role?.type !== RoleType.guest,
    },
  )
}
