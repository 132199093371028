import { useCallback } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { getUnreadChatsCountKey } from '../../utils/keys/messaging.key.js'
import { TUnreadChatsCount } from './useUnreadChatsCount.js'

export const useOptimisticallyUpdateUnreadChatCount = () => {
  const queryClient = useQueryClient()
  const key = getUnreadChatsCountKey()

  const incrementArchivedUnreadChatsCount = useCallback(
    (chatId: string) => {
      const unreadCounts = queryClient.getQueryData<TUnreadChatsCount>(key)
      const archivedUnreadChatsCount = unreadCounts?.archived

      if (archivedUnreadChatsCount?.unreadChatIds.includes(chatId)) {
        return
      }

      queryClient.setQueryData<TUnreadChatsCount>(key, {
        ...unreadCounts,
        archived: {
          ...archivedUnreadChatsCount,
          unreadChatIds: [...archivedUnreadChatsCount.unreadChatIds, chatId],
          unreadChatsCount: archivedUnreadChatsCount.unreadChatsCount + 1,
        },
      })
      queryClient.invalidateQueries(key)
    },
    [queryClient, key],
  )

  const decrementArchivedUnreadChatsCount = useCallback(
    (chatId: string) => {
      const unreadCounts = queryClient.getQueryData<TUnreadChatsCount>(key)
      const archivedUnreadChatsCount = unreadCounts?.archived

      if (!archivedUnreadChatsCount?.unreadChatIds.includes(chatId)) {
        return
      }

      queryClient.setQueryData<TUnreadChatsCount>(key, {
        ...unreadCounts,
        archived: {
          ...archivedUnreadChatsCount,
          unreadChatIds: archivedUnreadChatsCount.unreadChatIds.filter(
            id => id !== chatId,
          ),
          unreadChatsCount: archivedUnreadChatsCount.unreadChatsCount - 1,
        },
      })
      queryClient.invalidateQueries(key)
    },
    [queryClient, key],
  )

  const incrementActiveUnreadChatsCount = useCallback(
    (chatId: string) => {
      const unreadCounts = queryClient.getQueryData<TUnreadChatsCount>(key)
      const activeUnreadChatsCount = unreadCounts?.active

      if (activeUnreadChatsCount?.unreadChatIds.includes(chatId)) {
        return
      }

      queryClient.setQueryData<TUnreadChatsCount>(key, {
        ...unreadCounts,
        active: {
          ...activeUnreadChatsCount,
          unreadChatIds: [...activeUnreadChatsCount.unreadChatIds, chatId],
          unreadChatsCount: activeUnreadChatsCount.unreadChatsCount + 1,
        },
      })
      queryClient.invalidateQueries(key)
    },
    [key, queryClient],
  )

  const decrementActiveUnreadChatsCount = useCallback(
    (chatId: string) => {
      const unreadCounts = queryClient.getQueryData<TUnreadChatsCount>(key)
      const activeUnreadChatsCount = unreadCounts?.active

      if (!activeUnreadChatsCount?.unreadChatIds.includes(chatId)) {
        return
      }

      queryClient.setQueryData<TUnreadChatsCount>(key, {
        ...unreadCounts,
        active: {
          ...activeUnreadChatsCount,
          unreadChatIds: activeUnreadChatsCount.unreadChatIds.filter(
            id => id !== chatId,
          ),
          unreadChatsCount: activeUnreadChatsCount.unreadChatsCount - 1,
        },
      })
      queryClient.invalidateQueries(key)
    },
    [key, queryClient],
  )

  const incrementUnreadChatsCount = useCallback(
    (chatId: string, isArchived = false) => {
      if (isArchived) {
        incrementArchivedUnreadChatsCount(chatId)
      } else {
        incrementActiveUnreadChatsCount(chatId)
      }
    },
    [incrementArchivedUnreadChatsCount, incrementActiveUnreadChatsCount],
  )

  const decrementUnreadChatsCount = useCallback(
    (chatId: string, isArchived = false) => {
      if (isArchived) {
        decrementArchivedUnreadChatsCount(chatId)
      } else {
        decrementActiveUnreadChatsCount(chatId)
      }
    },
    [decrementArchivedUnreadChatsCount, decrementActiveUnreadChatsCount],
  )

  return {
    incrementUnreadChatsCount,
    decrementUnreadChatsCount,
  }
}
