import { useCallback, useMemo } from 'react'

import {
  AskAiAvailability,
  SearchNetworkSettings,
} from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import {
  useAskAIAccess,
  useModifySubscriptionAddon,
} from '@tribeplatform/react-sdk/hooks'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useCurrentProductInformation } from '../../Billing/hooks/useCurrentProductInformation.js'
import { getAddonPrice } from '../../Store/utils/getAddonPrice.js'
import { AskAISettingsContent } from './components/AskAISettingsContent.js'
import { AskAISettingsSkeleton } from './components/AskAISettingsSekeleton.js'

export const AskAISettingsCard = () => {
  const { $t } = useI18n()
  const {
    getAddonInformation,
    currentInterval,
    currentProductOrder,
    isLoading: isLoadingCurrentProduct,
  } = useCurrentProductInformation()
  const { mutateAsync: modifySubscriptionAddon, isLoading: isModifyingAddon } =
    useModifySubscriptionAddon()
  const {
    addon,
    isFetchingAddon,
    updateNetworkAskAISettings: {
      mutateAsync: updateAskAiSettings,
      isLoading: isUpdatingAskAiSettings,
    },
    askAiAvailability,
  } = useAskAIAccess()

  const addonInformation = useMemo(
    () => getAddonInformation(addon?.id),
    [addon?.id, getAddonInformation],
  )
  const price = useMemo(
    () => getAddonPrice({ addon, addonInformation, currentInterval }),
    [addon, addonInformation, currentInterval],
  )

  const toggleTrial = useCallback(
    async (enabled: boolean) => {
      try {
        const newValue = enabled
          ? AskAiAvailability.staffOnly
          : AskAiAvailability.disabled
        const input: SearchNetworkSettings = {
          askAiAvailability: newValue,
          askAiEnabled: undefined,
        }
        await updateAskAiSettings({
          input,
        })

        toast({
          title: $t({
            defaultMessage: 'Settings updated',
            id: 'Admin.GeneralSettings.Updated',
          }),
          status: 'success',
        })
      } catch (err) {
        toast({
          title: $t({
            defaultMessage: 'Unable to update settings',
            id: 'Admin.GeneralSettings.FailedToUpdate',
          }),
          status: 'error',
        })
      }
    },
    [$t, updateAskAiSettings],
  )
  const buyAddon = useCallback(async () => {
    try {
      await modifySubscriptionAddon({
        priceId: price?.id,
        quantity: 1,
      })
      toast({
        title: $t({
          defaultMessage: 'Add-on updated',
          id: 'Admin.Billing.AddonUpdated',
        }),
        description: $t({
          defaultMessage:
            'Your add-on is updated. Changes may take a few moments to appear in your account.',
          id: 'Admin.Billing.AddonUpdated.SuccessDescription',
        }),
        status: 'success',
      })
    } catch (e) {
      toast({
        title: $t({
          defaultMessage: 'Something went wrong',
          id: 'Admin.Billing.SomethingWentWrong',
        }),
        status: 'error',
      })
    }
  }, [$t, modifySubscriptionAddon, price?.id])

  const requiredPlanOrder = addon?.requiredPlanOrder ?? 0
  const hasRequiredPlan = currentProductOrder >= requiredPlanOrder
  const isInstalled = addonInformation?.quantity > 0

  return (
    <Card>
      <Card.Header
        title={
          <div className="flex items-center gap-x-1">
            <T
              defaultMessage="Ask AI access"
              id="Admin.AskAISettings.Title.PlanMet"
            />
            <Badge variant="positive">
              <T defaultMessage="New" id="Generics.New" />
            </Badge>
          </div>
        }
        description={
          <T
            defaultMessage="Give members access to <strong>Ask AI</strong> for smarter and more relevant search results. You can manage Ask AI access for your community by installing the add-on."
            id="Admin.AskAISettings.Description.PlanMet"
            values={{
              strong: chunks => <span className="font-semibold">{chunks}</span>,
            }}
          />
        }
      />
      {isFetchingAddon || isLoadingCurrentProduct || !addon ? (
        <AskAISettingsSkeleton />
      ) : (
        <AskAISettingsContent
          addon={addon}
          askAiAvailability={askAiAvailability}
          addonIsInstalled={isInstalled}
          isUpdating={isModifyingAddon || isUpdatingAskAiSettings}
          canInstallAddon={hasRequiredPlan}
          onTrialSettingChange={toggleTrial}
          onInstallAddon={buyAddon}
        />
      )}
    </Card>
  )
}
