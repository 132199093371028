import { Post } from '@tribeplatform/gql-client/types'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { CommandBarMode } from '../CommandBar/CommandBar.types.js'
import { useCommandBar } from '../CommandBar/index.js'
import { useHotkeysSettings } from '../common/hooks/useHotkeysSettings.js'
import { T } from '../i18n/components/T.js'
import { ActionItem } from './ActionItem.js'
import { usePostActionItems } from './hooks/usePostActionItems.js'
import type { PostContext } from './types.js'

type PostActionItemsProps = {
  post: Post
  context: PostContext
  showShortcuts?: boolean
  className?: string
}

const NUMBER_OF_SHORTCUTS_TO_SHOW = 3

export const PostActionItems = ({
  post,
  context,
  className,
  showShortcuts,
}: PostActionItemsProps) => {
  const { open } = useCommandBar()
  const { actions, shortcuts } = usePostActionItems({
    post,
    context,
    fetchShortcuts: showShortcuts,
  })
  const { disabled: hotkeysDisabled } = useHotkeysSettings()

  return (
    <Dropdown.Items className={className}>
      {actions.map((action, index) => (
        <ActionItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...action}
          isShortcut={false}
          context={context}
          hideHotkeys={hotkeysDisabled}
        />
      ))}
      {shortcuts?.length > 0 && <Dropdown.Divider />}
      {shortcuts
        ?.slice(0, NUMBER_OF_SHORTCUTS_TO_SHOW)
        .map((shortcut, index) => (
          <ActionItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...shortcut}
            isShortcut
            context={context}
            hideHotkeys={hotkeysDisabled}
          />
        ))}
      {shortcuts?.length > NUMBER_OF_SHORTCUTS_TO_SHOW && (
        <>
          <Dropdown.Item
            key="load-more"
            onClick={() =>
              open({
                searchOptions: {
                  mode: CommandBarMode.COMMANDS,
                },
                context: [{ entity: post, entityType: 'post' }],
                excludeActions: [
                  'posts',
                  'navigations',
                  'adminSettings',
                  'search',
                ],
              })
            }
          >
            <T
              defaultMessage="Load more..."
              id="PostActionItems.LoadMoreDotDotDot"
            />
          </Dropdown.Item>
        </>
      )}
    </Dropdown.Items>
  )
}
