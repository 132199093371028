import { Suspense, lazy } from 'react'

import type { Post } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { FallbackLoading } from '../../common/components/FallbackLoading.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { MemberSegment, TimeFrame } from '../types/index.js'

const PostAnalytics = lazy(() =>
  import('./PostAnalytics.js').then(m => ({ default: m.PostAnalytics })),
)

export type PostAnalyticsModalProps = {
  open: boolean
  onClose: () => void
  post: Post
  timeFrame?: TimeFrame
  segment?: MemberSegment
  linkToContent?: boolean
}

export const PostAnalyticsModal = ({
  open,
  onClose,
  post,
  timeFrame,
  segment,
  linkToContent = true,
}: PostAnalyticsModalProps) => {
  const { Link } = useRouter()
  const { $t } = useI18n()

  return (
    <Modal open={open} onClose={onClose} size="2xl">
      <div className="bg-background">
        <Modal.Header
          title={$t({
            id: 'Post.Analytics',
            defaultMessage: 'Post analytics',
          })}
          description={
            <>
              {linkToContent ? (
                <Link href={post?.relativeUrl} variant="accent" target="_blank">
                  {post?.title ?? ''}
                </Link>
              ) : (
                <>{post?.title ?? ''}</>
              )}
            </>
          }
        />
        <Modal.Content>
          <Suspense fallback={<FallbackLoading />}>
            <PostAnalytics
              post={post}
              timeFrame={timeFrame}
              segment={segment}
            />
          </Suspense>
        </Modal.Content>
      </div>
    </Modal>
  )
}
