import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import {
  CommandBarContextProps,
  CommandBarMode,
  OpenMethodOptions,
  CommandBarContextType,
  ActionOptions,
  CommandBarSearchOptions,
} from './CommandBar.types.js'

export const CommandBarContext = createContext<
  CommandBarContextProps | undefined
>(undefined)
export const CommandBarCtxSetterContext =
  createContext<React.Dispatch<React.SetStateAction<CommandBarContextType[]>>>(
    undefined,
  )

export const CommandBarProvider = ({ children }) => {
  const [context, setContext] = useState<CommandBarContextType[]>([
    {
      entity: null,
      entityType: 'network',
    },
  ])

  const [options, setOptions] = useState<ActionOptions>({})
  const [isOpen, setOpen] = useState(false)
  const [searchOptions, setSearchOptions] = useState<CommandBarSearchOptions>({
    mode: CommandBarMode.SEARCH,
  })

  const value = useMemo(() => {
    return {
      context,
      setContext,
      isOpen,
      open: (options: OpenMethodOptions) => {
        setOptions(options ?? {})
        setOpen(true)
        setSearchOptions(
          options?.searchOptions ?? { mode: CommandBarMode.SEARCH },
        )
      },
      close: () => {
        setOptions({})
        setOpen(false)
      },
      options,
      searchOptions,
    }
  }, [context, isOpen, options, searchOptions])

  return (
    <CommandBarContext.Provider value={value}>
      <CommandBarCtxSetterContext.Provider value={setContext}>
        {children}
      </CommandBarCtxSetterContext.Provider>
    </CommandBarContext.Provider>
  )
}

export function useCommandBar() {
  const context = useContext(CommandBarContext)
  if (context === undefined) {
    throw new Error(
      'useCommandBarContext must be used within a CommandBarProvider',
    )
  }

  return context
}

export function useCommandBarSetContext() {
  const setContext = useContext(CommandBarCtxSetterContext)

  const setCommandBarContext = useCallback(
    (newContext: CommandBarContextType) => {
      setContext(prevContext => {
        const excludedPrevContext = prevContext.filter(
          context => context?.entity?.id !== newContext?.entity?.id,
        )

        return [newContext, ...excludedPrevContext]
      })
    },
    [setContext],
  )

  const unsetCommandBarContext = useCallback(
    (newContext: CommandBarContextType) => {
      setContext(prevContext => {
        return prevContext.filter(
          context => context?.entity?.id !== newContext?.entity?.id,
        )
      })
    },
    [setContext],
  )

  return { setCommandBarContext, unsetCommandBarContext }
}
