import { useState } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import type {
  MemberForSpaceInvitation,
  Space,
} from '@tribeplatform/gql-client/types'
import {
  SpaceMemberAffiliation,
  SpaceRoleType,
} from '@tribeplatform/gql-client/types'
import {
  useAddSpaceMember,
  useAuthMember,
  useSearchMembersForSpaceInvitation,
  useSpaceRoles,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { MemberAvatar } from '../common/components/index.js'
import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import type { I18nShape } from '../i18n/types.js'
import { cleanSearchQuery } from '../utils/search.js'

const spaceAffiliationTextFactory = (
  intl: I18nShape,
): Record<MemberForSpaceInvitation['spaceAffiliation'], string> => {
  const { $t } = intl

  return {
    Joined: $t({
      id: 'SpaceAffiliation.Joined',
      defaultMessage: 'Already in space',
    }),
    NotJoined: '',
    RequestedToJoin: $t({
      id: 'SpaceAffiliation.RequestedToJoin',
      defaultMessage: 'Has requested to join',
    }),
    UnableToJoin: $t({
      id: 'SpaceAffiliation.UnableToJoin',
      defaultMessage: 'Unable to join',
    }),
  }
}

export interface AddMembersModalProps {
  space: Space
  onClose?: () => void
}

export const AddMembersModal = ({ space, onClose }: AddMembersModalProps) => {
  const spaceId = space.id
  const intl = useI18n()
  const { $t } = intl
  const spaceAffiliationTexts = spaceAffiliationTextFactory(intl)
  const [canSeletRole] = hasScopesPermission(space, ['updateMemberSpaceRole'])
  const [selectedItems, setSelectedItems] = useState<
    MemberForSpaceInvitation[]
  >([])
  const [role, setRole] = useState<SpaceRoleType>(SpaceRoleType.member)
  const [query, setQuery] = useState('')
  const { isAdmin, isModerator } = useAuthMember()
  const { mutateAsync: addSpaceMembers } = useAddSpaceMember()

  const debouncedQuery = useDebounce(query, 300)
  const { data, isInitialLoading: searchLoading } =
    useSearchMembersForSpaceInvitation({
      variables: {
        input: {
          query: debouncedQuery,
          spaceId,
        },
      },
      fields: {
        member: { profilePicture: { onImage: { urls: 'all' } } },
      },
      useQueryOptions: {
        enabled: !!debouncedQuery,
      },
    })

  // Exclude already selected items from the fround items list
  const foundItems =
    data?.filter(
      ({ memberId }) => !selectedItems.some(item => item.memberId === memberId),
    ) ?? []

  const { data: spaceRoles = [] } = useSpaceRoles({
    variables: {
      spaceId,
    },
  })

  const filteredSpaceRoles = spaceRoles?.filter(spaceRole => {
    if (isAdmin || isModerator) {
      return true
    }

    // Only admin and moderator can add a member as space admin
    return spaceRole.type !== SpaceRoleType.admin
  })

  const addNewMembers = async () => {
    const roleId = canSeletRole
      ? spaceRoles?.find(spaceRole => spaceRole.type === role)?.id
      : undefined

    if (!selectedItems.length) {
      return
    }

    try {
      await addSpaceMembers({
        spaceId,
        input: selectedItems.map(({ memberId }) => ({
          memberId,
          roleId,
        })),
      })

      toast({
        title: $t({
          defaultMessage: 'New {MEMBERS} added',
          id: 'Space.Members.Added',
        }),
        status: 'success',
      })

      onClose()
    } catch (error) {
      toast({
        title: $t({
          defaultMessage: "Couldn't add {MEMBERS}",
          id: 'Space.Members.FailedToAdd',
        }),
        status: 'error',
      })
    }
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={
          <T
            defaultMessage="Add to {space_name}"
            id="Space.Actions.AddMembersTo"
            values={{ space_name: space?.name }}
          />
        }
      />
      <Modal.Content>
        <Multiselect
          value={selectedItems}
          options={foundItems}
          onChange={setSelectedItems}
          searchable
          onInputChange={query => setQuery(cleanSearchQuery(query))}
        >
          <Multiselect.Button
            placeholder={$t({
              defaultMessage: 'Search by name',
              id: 'Members.SearchByName.Placeholder',
            })}
          >
            {selectedItems.map((item, index) => (
              <Multiselect.SelectedItem
                key={item.memberId}
                value={item}
                index={index}
              >
                <span className="flex items-center">
                  <MemberAvatar size="xl" member={item.member} />
                  <span className="ms-3 block truncate">
                    {item.member.name}
                  </span>
                </span>
              </Multiselect.SelectedItem>
            ))}
          </Multiselect.Button>
          <div className="h-[23rem] overflow-y-auto mt-2">
            <Multiselect.Items variant="unstyled">
              {searchLoading && (
                <Multiselect.ItemsEmpty>
                  <T
                    defaultMessage="Loading..."
                    id="Generics.LoadingDotDotDot"
                  />
                </Multiselect.ItemsEmpty>
              )}
              {!searchLoading &&
                foundItems.map((item, index) => (
                  <Multiselect.Item
                    key={item.memberId}
                    value={item}
                    index={index}
                    disabled={
                      item.spaceAffiliation === SpaceMemberAffiliation.Joined ||
                      item.spaceAffiliation ===
                        SpaceMemberAffiliation.UnableToJoin
                    }
                  >
                    <div className="flex items-center justify-between gap-x-2">
                      <div className="flex-1 flex items-center min-w-0">
                        <div className="shrink-0">
                          <MemberAvatar size="2.5x" member={item.member} />
                        </div>
                        <div className="flex-1 ms-3 truncate">
                          {item.member.name}
                        </div>
                      </div>
                      <div className="shrink-0 text-xs text-content-subdued">
                        {spaceAffiliationTexts?.[item.spaceAffiliation] ?? ''}
                      </div>
                    </div>
                  </Multiselect.Item>
                ))}
              {!searchLoading &&
                foundItems.length === 0 &&
                !!debouncedQuery && (
                  <Multiselect.ItemsEmpty>
                    <div>
                      <T
                        defaultMessage="Couldn't find any {MEMBERS} for this query."
                        id="Space.Members.Search.Empty"
                      />
                    </div>
                  </Multiselect.ItemsEmpty>
                )}
              {!searchLoading &&
                foundItems.length === 0 &&
                debouncedQuery === '' && (
                  <Multiselect.ItemsEmpty>
                    <div>
                      <T
                        defaultMessage="Search for the member you would like to add to this space. You can also select the space role from the dropdown below."
                        id="Space.Members.InitialMessage"
                      />
                    </div>
                  </Multiselect.ItemsEmpty>
                )}
            </Multiselect.Items>
          </div>
        </Multiselect>
      </Modal.Content>
      <Modal.Footer withBorder>
        <div className="flex items-center justify-between">
          {canSeletRole && filteredSpaceRoles.length > 1 && (
            <div>
              <span className="text-content-subdued">
                <T defaultMessage="Add as" id="Space.Members.AddAs" />
              </span>{' '}
              <Dropdown>
                <Dropdown.ButtonMinimal>
                  <span className="text-interactive hover:text-interactive-hovered flex space-s-1 items-center">
                    <T
                      defaultMessage="{SPACE} {role_type, select, admin {admins} member {{MEMBERS}} other {{role_type}}}"
                      id="Space.AddMember.RoleIndicator"
                      values={{
                        role_type: role.toLowerCase(),
                      }}
                    />
                    <SvgIcon className="w-4 h-4" name="chevron-down" />
                  </span>
                </Dropdown.ButtonMinimal>
                <Dropdown.Items>
                  {filteredSpaceRoles?.map(({ id, name, type }) => (
                    <Dropdown.Item
                      key={id}
                      onClick={() => {
                        setRole(type)
                      }}
                    >
                      <T
                        defaultMessage="{SPACE} {role_type, select, admin {admins} member {{MEMBERS}} other {{role_type}}}"
                        id="Space.AddMember.RoleIndicator"
                        values={{
                          role_type: name.toLowerCase(),
                        }}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Items>
              </Dropdown>
            </div>
          )}
          <Button
            variant="primary"
            data-testid="add-button"
            onClick={addNewMembers}
            disabled={selectedItems.length === 0}
            className="ms-auto"
          >
            <T defaultMessage="Add" id="Generics.Add" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
