import { MediaFields, mediaGQLFields } from '../media/media.fields.js'
import { MemberFields, memberGQLFields } from '../network/member.fields.js'
import { PostFields } from '../post/posts.fields.js'
import { SpaceFields, spaceGQLFields } from '../space/space.fields.js'

export type SearchEntityFields = 'basic' | 'all' | CustomSearchEntityFields

export interface CustomSearchEntityFields {
  in?: SpaceFields
  by?: MemberFields
  media?: MediaFields
  entity?: PostFields
}

const BASIC_SEARCH_ENTITY_FIELDS: CustomSearchEntityFields = {}
const ALL_SEARCH_ENTITY_FIELDS: CustomSearchEntityFields = {
  in: {
    image: 'basic',
  },
  by: 'basic',
  media: 'basic',
  entity: 'basic',
}

export function searchEntityGQLFields(fields: SearchEntityFields) {
  if (fields === 'basic') {
    fields = BASIC_SEARCH_ENTITY_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_SEARCH_ENTITY_FIELDS
  }

  return `
    content
    created
    entityId
    id
    url
    subtitle
    title
    entityType
    ${
      fields.in
        ? `
      in {
        ... on Space {
          ${spaceGQLFields(fields.in)}
        }
      }
    `
        : ``
    }
    ${
      fields.by
        ? `
      by {
        ... on Member {
          ${memberGQLFields(fields.by)}
        }
      }
    `
        : ``
    }
    ${
      fields.media
        ? `
      media {
        ${mediaGQLFields(fields.media)}
      }
    `
        : ``
    }
    ${
      fields.entity
        ? `
        entity{ ... on Post 
          {
          	publishedAt
            reactionsCount
            repliesCount
        	}
        }
      `
        : ``
    }
  `
}
