import { useCallback, useEffect, useRef, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { Image, Space } from '@tribeplatform/gql-client/types'
import {
  useCollections,
  useSpace,
  useUpdateSpace,
  useUploadFile,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import {
  FormControl,
  HelperText,
} from '@tribeplatform/react-ui-kit/FormControl'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { SectionHeader } from '@tribeplatform/react-ui-kit/SectionHeader'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useDomain } from '../../common/hooks/useDomain.js'
import { Form } from '../../Form/index.js'
import { T } from '../../i18n/components/T.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { Link } from '../../Link/Link.js'
import { SpaceImage } from '../SpaceImage.js'
import { ImagePicker } from './Components/ImagePicker.js'
import { SpaceSettingsProps } from './types.js'

export const FormDirtyUpdater = ({
  setDirty,
  isActive,
}: Pick<SpaceSettingsProps, 'setDirty' | 'isActive'>) => {
  const { formState } = useFormContext()

  useEffect(() => {
    if (isActive) {
      setDirty(formState.isDirty)
    }
  }, [formState.isDirty, isActive, setDirty])

  return null
}

export const SpaceSettingsGeneral = ({
  space,
  onClose,
  isChangingTab,
  confirmTabChange,
  rejectTabChange,
  setDirty,
  setSubmit,
  isActive,
}: SpaceSettingsProps) => {
  const { $t } = useI18n()
  const { getResourceUrl } = useDomain()
  const { data: collections } = useCollections()
  const { data } = useSpace({
    variables: {
      id: space.id,
    },
    fields: {
      authMemberProps: 'all',
      image: 'basic',
      collection: 'basic',
      banner: 'basic',
    },
  })
  const { mutateAsync: updateSpace, isLoading } = useUpdateSpace()
  const buttonRef = useRef<HTMLButtonElement>()
  const { loadingFile, uploadFile } = useUploadFile()

  if (data) {
    space = data
  }

  useEffect(() => {
    if (isActive) {
      setSubmit(() => () => buttonRef?.current?.click())
    }
  }, [isActive, setSubmit, buttonRef])

  const [isSettingHomeViaHack, setHomePageHackFlag] = useState(false)
  const defaultValues = (space: Space) => ({
    ...space,
    _private: space?.private,
    collectionId: space?.collection?.id || null,
  })

  const onUploadFile = useCallback(
    (files: Array<File> | undefined) => {
      const file = files?.[0]

      if (!file) {
        updateSpace({
          id: space.id,
          input: {
            bannerId: null,
          },
        })
        return
      }

      uploadFile(file, async uploadedImage => {
        if (uploadedImage.id) {
          updateSpace({
            id: space.id,
            input: {
              bannerId: uploadedImage?.id,
            },
          })
        }
      })
    },
    [space.id, updateSpace, uploadFile],
  )

  return (
    <Form
      defaultValues={defaultValues(space)}
      onSubmit={async (data, methods) => {
        const {
          name,
          description,
          address: { path },
          collectionId,
          _private,
          hidden,
          inviteOnly,
          nonAdminsCanInvite,
        } = data

        if (path === '' && space.address.path !== '') {
          /**
           * The user is trying to make a new space its home using the legacy hack.
           * Action -> Warn the user and prevent the update.
           * The API must also enforce the validation, upon update.
           * In order to fully resolve the home space hack situation, the network initialization
           * workflow and the concept of system spaces should be revised.
           */
          toast({
            title: $t({
              defaultMessage:
                'Path cannot be empty. Use the administration settings to change the homepage.',
              id: 'Admin.Space.Settings.UpdateFailedForEmptyPath',
            }),
            status: 'error',
          })

          return
        }
        try {
          const updatedSpace = await updateSpace({
            id: space?.id,
            input: {
              name,
              description,
              address: { path, exact: true },
              collectionId,
              private: _private,
              hidden,
              inviteOnly,
              nonAdminsCanInvite,
            },
          })
          methods.reset(defaultValues(updatedSpace))
          toast({
            title: $t({
              defaultMessage: '{SPACE_CC} settings updated',
              id: 'Admin.Space.Settings.Updated',
            }),
            status: 'success',
          })
          if (isChangingTab) {
            confirmTabChange()
          } else {
            onClose()
          }
        } catch (e) {
          const error = e?.response?.errors[0]
          if (error?.message) {
            toast({
              title: error.message,
              status: 'error',
            })
          } else {
            toast({
              title: $t({
                defaultMessage: 'Unable to update {SPACE} settings',
                id: 'Admin.Space.Settings.FailedToUpdate',
              }),
              status: 'error',
            })
          }
          if (isChangingTab) {
            rejectTabChange()
          }
        }
      }}
    >
      {({ watch, setValue }) => {
        const [isPrivate, hidden] = watch(['_private', 'hidden'])
        if (!isPrivate && hidden) {
          setValue('hidden', false)
        }
        return (
          <>
            <FormDirtyUpdater isActive={isActive} setDirty={setDirty} />
            <div className="sm:h-132 flex flex-col">
              <div className="flex flex-col flex-grow overflow-auto p-5">
                <SectionHeader
                  title={$t({
                    defaultMessage: 'Settings',
                    id: 'Generics.Settings',
                  })}
                  className="pb-5"
                />
                <div className="space-y-5">
                  <FormControl.Layout
                    name="icon"
                    label={$t({
                      defaultMessage: 'Icon',
                      id: 'Generics.Icon',
                    })}
                  >
                    <div className="inline-block">
                      <SpaceImage space={space} size="2x" readOnly={false} />
                    </div>
                  </FormControl.Layout>
                  <Form.Input
                    label={$t({
                      defaultMessage: 'Name',
                      id: 'Generics.Name.Noun',
                    })}
                    name="name"
                    validation={{
                      required: $t({
                        defaultMessage: '{SPACE_CC} name cannot be empty.',
                        id: 'Admin.Space.Settings.Name.Validation',
                      }),
                    }}
                  />
                  <Form.Textarea
                    label={$t({
                      defaultMessage: 'Description',
                      id: 'Admin.Space.Settings.Description',
                    })}
                    name="description"
                  />

                  <div className="space-y-1">
                    <FormControl.Label>
                      <T
                        defaultMessage="Banner"
                        id="Admin.Space.Settings.Banner"
                      />
                    </FormControl.Label>
                    <ImagePicker
                      onUpload={onUploadFile}
                      isLoading={!!loadingFile}
                      thumbnailUrl={(space?.banner as Image)?.url}
                    />
                  </div>

                  <Form.Input
                    label={$t({
                      defaultMessage: 'Web address',
                      id: 'Admin.Space.Settings.WebAddress',
                    })}
                    name="address.path"
                    className="overflow-x-auto"
                    leadingAddon={getResourceUrl()}
                    onChange={input => {
                      if (space?.address.path !== '') {
                        if (input.target.value === '') {
                          setHomePageHackFlag(true)
                        } else {
                          setHomePageHackFlag(false)
                        }
                      }
                    }}
                    helperText={
                      isSettingHomeViaHack ? (
                        <HelperText status="error" highlighted>
                          <T
                            defaultMessage="Space address cannot be empty. If you are trying to change the homepage, please use the <settings-link>homepage settings</settings-link> in administration section."
                            id="SpaceSettings.EmptyAddressError"
                            values={{
                              'settings-link': chunks => (
                                <Link
                                  href={getResourceUrl(
                                    '/manage/settings/network#homepage-settings',
                                  )}
                                  target="_blank"
                                  trusted
                                >
                                  <strong>
                                    {chunks}
                                    <sup>
                                      <SvgIcon
                                        className="inline"
                                        name="link-external"
                                      />
                                    </sup>
                                  </strong>
                                </Link>
                              ),
                            }}
                          />
                        </HelperText>
                      ) : null
                    }
                  />
                  <Form.Select
                    label={$t({
                      defaultMessage: '{COLLECTION_CC}',
                      id: 'Generics.Collection',
                    })}
                    name="collectionId"
                    items={[
                      {
                        text: $t({
                          defaultMessage: 'No collection',
                          id: 'Admin.Space.Settings.Collection.None',
                        }),
                        value: null,
                      },
                      ...(collections || [])?.map(collection => ({
                        text: collection?.name,
                        value: collection?.id,
                      })),
                    ]}
                  />
                  <Form.Toggle
                    helperText={
                      space.isHomepage && (
                        <HelperText status="warn">
                          <T
                            defaultMessage="This space is the homepage and cannot be made private. "
                            id="SpaceSettings.PrivateHomeWarning"
                          />
                        </HelperText>
                      )
                    }
                    disabled={space.isHomepage}
                    label={
                      <span
                        className={`flex ${
                          space.isHomepage
                            ? 'cursor-not-allowed text-content-disabled'
                            : ''
                        }`}
                      >
                        {$t({
                          defaultMessage: 'Make private',
                          id: 'Admin.Space.Settings.MakePrivate',
                        })}
                        {space.isHomepage && (
                          <SvgIcon className="ms-1" width="1rem" name="lock" />
                        )}
                      </span>
                    }
                    description={$t({
                      defaultMessage:
                        'Only members can see who’s in the space and what they post.',
                      id: 'Admin.Space.Settings.MakePrivate.Description',
                    })}
                    name="_private"
                  />
                  {isPrivate && (
                    <Form.Toggle
                      label={$t({
                        defaultMessage: 'Hide {SPACE}',
                        id: 'Admin.Space.Settings.Hide',
                      })}
                      description={$t({
                        defaultMessage:
                          '{SPACE_CC} will only be visible for its {MEMBERS}.',
                        id: 'Admin.Space.Settings.Hide.Description',
                      })}
                      name="hidden"
                    />
                  )}
                  <Form.Toggle
                    label={$t({
                      defaultMessage: 'Make invite-only',
                      id: 'Admin.Space.Settings.InviteOnly',
                    })}
                    description={$t({
                      defaultMessage:
                        'Allow only people with an invite to join as space members.',
                      id: 'Admin.Space.Settings.InviteOnly.Description',
                    })}
                    name="inviteOnly"
                  />
                  <Form.Toggle
                    label={$t({
                      defaultMessage: 'Anyone can invite',
                      id: 'Admin.Space.Settings.OpenInvite',
                    })}
                    description={$t({
                      defaultMessage:
                        'Allow non-admin members to invite others to the space.',
                      id: 'Admin.Space.Settings.OpenInvite.Description',
                    })}
                    name="nonAdminsCanInvite"
                  />
                </div>
              </div>
              <div className="flex justify-end px-5 py-5 border-t space-s-2">
                <Button
                  type="button"
                  variant="secondaryNeutral"
                  onClick={() => onClose()}
                >
                  <T defaultMessage="Cancel" id="Generics.Cancel" />
                </Button>
                <Button
                  type="submit"
                  loading={isLoading}
                  ref={buttonRef}
                  disabled={isSettingHomeViaHack}
                >
                  <T defaultMessage="Update" id="Generics.Update" />
                </Button>
              </div>
            </div>
          </>
        )
      }}
    </Form>
  )
}
