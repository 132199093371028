import { ActionStatus } from '../../global-types/tribe-global-graphql.generated.js'
import {
  addParticipantsToGroupChatGQLMutation,
  archiveChatGQLMutation,
  chatGQLQuery,
  chatIdFromSlugGQLQuery,
  chatParticipantsByCursorGQLQuery,
  chatParticipantsByIdsGQLQuery,
  chatParticipantsGQLQuery,
  chatsByCursorGQLQuery,
  chatsGQLQuery,
  checkMembersContactabilityGQLQuery,
  createGroupChatGQLMutation,
  demoteChatAdminsGQLMutation,
  findChatWithMemberGQLQuery,
  leaveGroupChatGQLMutation,
  promoteChatAdminsGQLMutation,
  removeGroupChatParticipantsGQLMutation,
  transferGroupChatOwnershipGQLMutation,
  unarchiveChatGQLMutation,
  unreadChatsCountGQLQuery,
  updateGroupChatGQLMutation,
} from '../../graphql/messaging/chats.gql.js'
import {
  advanceReadMessageOffsetGQLMutation,
  messagesByCursorGQLQuery,
  messagesGQLQuery,
  sendMessageToChatGQLMutation,
  sendMessageToMemberGQLMutation,
  unreadMessageCountsGQLQuery,
} from '../../graphql/messaging/messages.gql.js'
import { privateMessageGQLQuery } from '../../graphql/messaging/privateMessage.gql.js'
import { SpaceFields } from '../../graphql/space/space.fields.js'
import {
  AdvanceReadMessageOffsetResult,
  Chat,
  ChatIdFromSlug,
  ChatList,
  ChatParticipant,
  ChatParticipantList,
  MembersContactabilityStatus,
  MessageList,
  MutationAddMembersToGroupArgs,
  MutationAdvanceReadMessageOffsetArgs,
  MutationArchiveChatArgs,
  MutationCreateGroupChatArgs,
  MutationDemoteChatAdminsArgs,
  MutationLeaveGroupChatArgs,
  MutationPromoteChatAdminsArgs,
  MutationRemoveMembersFromGroupArgs,
  MutationSendMessageToChatArgs,
  MutationSendMessageToMemberArgs,
  MutationTransferGroupChatOwnershipArgs,
  MutationUnarchiveChatArgs,
  MutationUpdateGroupChatArgs,
  NewMessage,
  QueryChatArgs,
  QueryChatIdFromSlugArgs,
  QueryChatParticipantsArgs,
  QueryChatParticipantsByCursorArgs,
  QueryChatParticipantsByIdsArgs,
  QueryChatsArgs,
  QueryChatsByCursorArgs,
  QueryCheckMembersContactabilityArgs,
  QueryFindChatWithMemberArgs,
  QueryMessagesArgs,
  QueryMessagesByCursorArgs,
  QueryPrivateMessageArgs,
  QueryUnreadChatsCountArgs,
  QueryUnreadMessageCountsArgs,
  Space,
  UnreadChatsCount,
  UnreadMessageCountsPerChat,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class MessagingClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async sendMessageToMember(variables: MutationSendMessageToMemberArgs) {
    type MutationResult = {
      sendMessageToMember: NewMessage
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: sendMessageToMemberGQLMutation(),
      variables,
    })
    return result.sendMessageToMember
  }

  async sendMessageToChat(variables: MutationSendMessageToChatArgs) {
    type MutationResult = {
      sendMessageToChat: NewMessage
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: sendMessageToChatGQLMutation(),
      variables,
    })
    return result.sendMessageToChat
  }

  async createGroupChat(variables: MutationCreateGroupChatArgs) {
    type MutationResult = {
      createGroupChat: Chat
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: createGroupChatGQLMutation(),
      variables,
    })
    return result.createGroupChat
  }

  async updateGroupChat(variables: MutationUpdateGroupChatArgs) {
    type MutationResult = {
      updateGroupChat: Chat
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: updateGroupChatGQLMutation(),
      variables,
    })
    return result.updateGroupChat
  }

  async addParticipantsToGroupChat(variables: MutationAddMembersToGroupArgs) {
    type MutationResult = {
      addMembersToGroupChat: Chat
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: addParticipantsToGroupChatGQLMutation(),
      variables,
    })
    return result.addMembersToGroupChat
  }

  async removeGroupChatParticipants(
    variables: MutationRemoveMembersFromGroupArgs,
  ) {
    type MutationResult = {
      removeMembersFromGroup: Chat
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: removeGroupChatParticipantsGQLMutation(),
      variables,
    })
    return result.removeMembersFromGroup
  }

  async promoteChatAdmins(variables: MutationPromoteChatAdminsArgs) {
    type MutationResult = {
      status: ActionStatus
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: promoteChatAdminsGQLMutation(),
      variables,
    })
    return result.status
  }

  async demoteChatAdmins(variables: MutationDemoteChatAdminsArgs) {
    type MutationResult = {
      status: ActionStatus
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: demoteChatAdminsGQLMutation(),
      variables,
    })
    return result.status
  }

  async transferGroupChatOwnership(
    variables: MutationTransferGroupChatOwnershipArgs,
  ) {
    type MutationResult = {
      status: ActionStatus
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: transferGroupChatOwnershipGQLMutation(),
      variables,
    })
    return result.status
  }

  async chat(variables: QueryChatArgs) {
    type QueryResult = {
      chat: Chat
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatGQLQuery(),
      variables,
    })
    return result.chat
  }

  async archiveChat(variables: MutationArchiveChatArgs) {
    type QueryResult = {
      archiveChat: Chat
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: archiveChatGQLMutation(),
      variables,
    })
    return result.archiveChat
  }

  async unarchiveChat(variables: MutationUnarchiveChatArgs) {
    type QueryResult = {
      unarchiveChat: Chat
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: unarchiveChatGQLMutation(),
      variables,
    })
    return result.unarchiveChat
  }

  async leaveGroupChat(variables: MutationLeaveGroupChatArgs) {
    type QueryResult = {
      leaveGroupChat: Chat
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: leaveGroupChatGQLMutation(),
      variables,
    })
    return result.leaveGroupChat
  }

  async findChatWithMember(variables: QueryFindChatWithMemberArgs) {
    type QueryResult = {
      findChatWithMember: Chat
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: findChatWithMemberGQLQuery(),
      variables,
    })
    return result.findChatWithMember
  }

  async chatIdFromSlug(variables: QueryChatIdFromSlugArgs) {
    type QueryResult = {
      chatIdFromSlug: ChatIdFromSlug
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatIdFromSlugGQLQuery(),
      variables,
    })
    return result.chatIdFromSlug
  }

  async checkMembersContactability(
    variables: QueryCheckMembersContactabilityArgs,
  ) {
    type QueryResult = {
      checkMembersContactability: MembersContactabilityStatus
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: checkMembersContactabilityGQLQuery(),
      variables,
    })
    return result.checkMembersContactability
  }

  async chats(variables: QueryChatsArgs) {
    type QueryResult = {
      chats: ChatList
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatsGQLQuery(),
      variables,
    })
    return result.chats
  }

  async chatsByCursor(variables: QueryChatsByCursorArgs) {
    type QueryResult = {
      chatsByCursor: ChatList
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatsByCursorGQLQuery(),
      variables,
    })
    return result.chatsByCursor
  }

  async messages(variables: QueryMessagesArgs) {
    type QueryResult = {
      messages: MessageList
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: messagesGQLQuery(),
      variables,
    })

    return result.messages
  }

  async messagesByCursor(variables: QueryMessagesByCursorArgs) {
    type QueryResult = {
      messagesByCursor: MessageList
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: messagesByCursorGQLQuery(),
      variables,
    })

    return result.messagesByCursor
  }

  async getUnreadChatsCount(variables?: QueryUnreadChatsCountArgs) {
    type QueryResult = {
      unreadChatsCount: UnreadChatsCount
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: unreadChatsCountGQLQuery(),
      variables,
    })

    return result.unreadChatsCount
  }

  async getUnreadMessageCounts(variables: QueryUnreadMessageCountsArgs) {
    type QueryResult = {
      unreadMessageCounts: UnreadMessageCountsPerChat
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: unreadMessageCountsGQLQuery(),
      variables,
    })

    return result.unreadMessageCounts.counts
  }

  async advanceReadMessageOffset(
    variables: MutationAdvanceReadMessageOffsetArgs,
  ) {
    type MutationResult = {
      advanceReadMessageOffset: AdvanceReadMessageOffsetResult
    }

    const result = await this.client.authorizedRequest<MutationResult>({
      query: advanceReadMessageOffsetGQLMutation(),
      variables,
    })
    return result.advanceReadMessageOffset
  }

  async chatParticipants(variables: QueryChatParticipantsArgs) {
    type QueryResult = {
      chatParticipants: ChatParticipantList
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatParticipantsGQLQuery(),
      variables,
    })

    return result.chatParticipants
  }

  async chatParticipantsByCursor(variables: QueryChatParticipantsByCursorArgs) {
    type QueryResult = {
      chatParticipantsByCursor: ChatParticipantList
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatParticipantsByCursorGQLQuery(),
      variables,
    })
    return result.chatParticipantsByCursor
  }

  async chatParticipantsByIds(variables: QueryChatParticipantsByIdsArgs) {
    type QueryResult = {
      chatParticipantsByIds: Array<ChatParticipant>
    }

    const result = await this.client.authorizedRequest<QueryResult>({
      query: chatParticipantsByIdsGQLQuery(),
      variables,
    })

    return result.chatParticipantsByIds
  }

  /**
   * @deprecated
   */
  async privateMessage(
    variables: QueryPrivateMessageArgs,
    fields: SpaceFields = 'default',
    accessToken?: string,
  ): Promise<Space> {
    type QueryResult = {
      privateMessage: Space
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: privateMessageGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.privateMessage
  }
}
