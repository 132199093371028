import {
  ReactNode,
  useCallback,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'

import { useFormContext } from 'react-hook-form'

import { runIfFn } from '@tribeplatform/react-ui-kit/utils'

import { useI18n } from '../../i18n/providers/I18nProvider.js'

export interface ConfirmDiscardContextType {
  setDirty: (boolean) => void
}

const ConfirmDiscardContext = createContext<
  ConfirmDiscardContextType | undefined
>(undefined)

function useConfirmDiscardContext() {
  const context = useContext(ConfirmDiscardContext)
  if (context === undefined) {
    throw new Error(
      'useConfirmDiscardContext must be used within a ConfirmDiscardProvider',
    )
  }

  return context
}

type ConfirmRenderFunction = { discard: () => void }

export const ConfirmDiscard = ({
  children,
  onConfirm,
  active = true,
  message,
}: {
  children: (renderProps: ConfirmRenderFunction) => ReactNode
  onConfirm: () => void
  active?: boolean
  message?: string
}) => {
  const intl = useI18n()
  const { $t } = intl
  const [dirty, setDirty] = useState(false)

  const discard = useCallback(() => {
    if (!active || !dirty) {
      onConfirm?.()
      return
    }

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      message ||
        $t({
          id: 'Alert.UnsavedChanges',
          defaultMessage:
            'You have unsaved changes. Are you sure you want to discard them?',
        }),
    )

    if (confirmed) {
      onConfirm?.()
      setDirty(false)
    }
  }, [active, dirty, message, $t, onConfirm])

  return (
    <ConfirmDiscardContext.Provider value={{ setDirty }}>
      {runIfFn(children, {
        discard,
      })}
    </ConfirmDiscardContext.Provider>
  )
}

const FormDirtyUpdater = () => {
  const {
    formState: { isDirty },
  } = useFormContext()
  const { setDirty } = useConfirmDiscardContext()

  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty, setDirty])

  return null
}

ConfirmDiscard.FormDirty = FormDirtyUpdater
