import { FC, useState } from 'react'

import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { SpaceSeoSettings } from './SpaceSeoSettings.js'
import { SpaceSettingsDelete } from './SpaceSettingsDelete.js'
import { SpaceSettingsGeneral } from './SpaceSettingsGeneral.js'
import { SpaceSettingsNotifications } from './SpaceSettingsNotifications.js'
import { SpaceSettingsPostTypes } from './SpaceSettingsPostTypes.js'
import { SpaceSettingsProps } from './types.js'

export type SpaceSettingsModalProps = {
  open: boolean
  onClose: () => void
  space: Space
}

export const SpaceSettingsModal = ({
  open,
  onClose,
  space,
}: SpaceSettingsModalProps) => {
  const { $t } = useI18n()
  const [currentTab, setCurrentTab] = useState(0)
  const [targetTab, setTargetTab] = useState(0)

  const [dirty, setDirty] = useState(false)
  const [submit, setSubmit] = useState<() => void>()

  const [dirtySm, setDirtySm] = useState(false)
  const [submitSm, setSubmitSm] = useState<() => void>()

  const confirmTabChange = () => {
    setCurrentTab(targetTab)
  }

  const rejectTabChange = () => {
    setTargetTab(currentTab)
  }

  const setTab = (index: number) => {
    setTargetTab(index)

    if (dirty || dirtySm) {
      confirm({
        title: $t({
          defaultMessage: 'Your changes have not been saved. Save changes?',
          id: 'Generics.DirtyState.Save',
        }),
        proceedLabel: $t({ defaultMessage: 'Save', id: 'Generics.Save' }),
        cancelLabel: $t({ defaultMessage: 'Discard', id: 'Generics.Discard' }),
      })
        .then(value => {
          if (value) {
            if (dirty) {
              submit()
            } else {
              submitSm()
            }
          } else {
            setCurrentTab(index)
          }
        })
        .catch(() => rejectTabChange())
    } else {
      setCurrentTab(index)
    }
  }

  const spaceSettingPages = [
    {
      title: $t({ defaultMessage: 'Settings', id: 'Generics.Settings' }),
      component: SpaceSettingsGeneral,
      active: currentTab === 0,
    },
    {
      title: $t({
        defaultMessage: 'CMS models',
        id: 'Generics.CMSModels',
      }),
      component: SpaceSettingsPostTypes,
      active: currentTab === 1,
    },
    {
      title: $t({
        defaultMessage: 'Notifications',
        id: 'Generics.Notifications',
      }),
      component: SpaceSettingsNotifications,
      active: currentTab === 2,
    },
    {
      title: $t({ defaultMessage: 'SEO', id: 'Generics.Seo' }),
      component: SpaceSeoSettings,
      active: currentTab === 3,
    },
    {
      title: $t({ defaultMessage: 'Danger zone', id: 'Generics.DangerZone' }),
      component: SpaceSettingsDelete,
      active: currentTab === 4,
    },
  ]

  const tabClassName = ({ selected }) =>
    clsx(
      selected
        ? 'text-content bg-surface-pressed'
        : 'text-content-subdued hover:text-content bg-surface-subdued  hover:bg-surface-hovered',
      'group relative text-start font-normal cursor-pointer',
      'py-2 px-5 m-0 text-sm',
      'focus:outline-none focus-visible:ring focus-visible:ring-inset focus:z-10',
    )

  const createComponent = (
    Component: FC<SpaceSettingsProps>,
    props: SpaceSettingsProps,
  ) => {
    return <Component {...props} />
  }

  return (
    <Modal
      open={open}
      onClose={() => (targetTab === currentTab ? onClose() : undefined)}
      size="3xl"
    >
      <div className="hidden sm:flex h-full" data-testid="content-desktop">
        <div className="flex flex-col bg-surface-subdued py-5 min-w-48">
          {spaceSettingPages?.map((page, i) => (
            <div
              className={tabClassName({ selected: currentTab === i })}
              onClick={() => setTab(i)}
              key={page?.title}
            >
              {page?.title}
            </div>
          ))}
        </div>
        <div className="flex-grow h-132 overflow-hidden">
          {createComponent(spaceSettingPages?.[currentTab]?.component, {
            space,
            onClose,
            isChangingTab: targetTab !== currentTab,
            isActive: spaceSettingPages?.[currentTab].active,
            rejectTabChange,
            confirmTabChange,
            setSubmit,
            setDirty,
          })}
        </div>
      </div>

      <div className="sm:hidden" data-testid="content-mobile">
        <div className="overflow-x-auto isolate relative z-0 divide-x divide-line-subdued shadow rounded-t-none rounded-b-none flex flex-nowrap">
          {spaceSettingPages?.map((page, i) => (
            <button
              type="button"
              key={page.title}
              onClick={() => setTab(i)}
              className="flex-1 text-content group relative text-center bg-surface hover:bg-surface-hovered py-3 px-2 m-0 text-sm leading-4 focus:outline-none focus-visible:ring focus-visible:ring-inset focus:z-10"
            >
              {page.title}
              <span
                className={`${
                  page?.active ? 'bg-action-primary' : 'bg-transparent'
                } absolute inset-x-0 bottom-0 h-0.5`}
              />
            </button>
          ))}
        </div>
        <div className="mt-5">
          {createComponent(spaceSettingPages?.[currentTab]?.component, {
            space,
            onClose,
            isChangingTab: targetTab !== currentTab,
            isActive: spaceSettingPages?.[currentTab].active,
            rejectTabChange,
            confirmTabChange,
            setSubmit: setSubmitSm,
            setDirty: setDirtySm,
          })}
        </div>
      </div>
    </Modal>
  )
}
