import InfiniteScroll from 'react-infinite-scroller'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  Space,
  SpaceMember,
  SpaceRoleType,
} from '@tribeplatform/gql-client/types'
import {
  useRemoveSpaceMember,
  useSpaceMembers,
  useSpaceRoles,
  useUpdateMemberSpaceRole,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Container } from '@tribeplatform/react-ui-kit/Layout'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { MemberAvatar } from '../common/components/index.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'

export interface SpaceMembersModalProps {
  space: Space
  open: boolean
  onClose: () => void
}

export const SpaceMembersModal = ({
  space,
  open,
  onClose,
}: SpaceMembersModalProps) => {
  const { $t } = useI18n()
  const spaceId = space?.id

  const { data, fetchNextPage, hasNextPage, isFetched, isLoading } =
    useSpaceMembers({
      fields: {
        member: {
          profilePicture: 'basic',
          banner: 'basic',
          role: 'basic',
          badges: 'default',
        },
        role: 'basic',
      },
      variables: { limit: 30, spaceId },
      useInfiniteQueryOptions: {
        refetchOnMount: 'always',
      },
    })
  const { data: roles } = useSpaceRoles({
    variables: {
      spaceId,
    },
  })

  const { mutateAsync: removeSpaceMember } = useRemoveSpaceMember()
  const { mutateAsync: updateMemberSpaceRole } = useUpdateMemberSpaceRole()

  const [canUpdateMemberSpaceRole, canRemoveSpaceMembers] = hasScopesPermission(
    space,
    ['updateMemberSpaceRole', 'removeSpaceMembers'],
  )

  const adminRole = roles?.find(role => role.type === SpaceRoleType.admin)
  const memberRole = roles?.find(role => role.type === SpaceRoleType.member)
  const getOtherMemberRole = (spaceMember: SpaceMember) =>
    spaceMember?.role?.id === adminRole?.id ? memberRole : adminRole

  const getMemberCardActions = (spaceMember: SpaceMember) => {
    if (!(canUpdateMemberSpaceRole || canRemoveSpaceMembers)) {
      return null
    }
    const { member } = spaceMember
    const otherRole = getOtherMemberRole(spaceMember)
    return (
      <>
        {canRemoveSpaceMembers && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="member-remove" />}
            onClick={async () => {
              const confirmed = await confirm({
                title: $t(
                  {
                    id: 'Space.Members.Remove.Confirmation',
                    defaultMessage:
                      'Are you sure you want to remove {member_name} ({member_username}) from this {SPACE}?',
                  },
                  {
                    member_name: member?.name,
                    member_username: member?.username,
                  },
                ),
                proceedLabel: $t({
                  defaultMessage: 'Remove',
                  id: 'Generics.Remove',
                }),
                danger: true,
              })
              if (!confirmed) {
                return
              }
              await removeSpaceMember({
                spaceId,
                memberIds: [member?.id],
              })
            }}
          >
            <T id="Generics.RemoveMember" defaultMessage="Remove {MEMBER}" />
          </Dropdown.Item>
        )}
        {canUpdateMemberSpaceRole && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="shield" />}
            onClick={async () => {
              const confirmed = await confirm({
                title: $t(
                  {
                    id: 'Space.Members.ChangeRole.Confirmation',
                    defaultMessage:
                      'Are you sure you want to change role of {member_name} ({member_username}) to {role_name, select, admin {space admin} member {space member} other {space {role_name}}}?',
                  },
                  {
                    member_name: member?.name,
                    member_username: member?.username,
                    role_name: otherRole?.name?.toLowerCase(),
                  },
                ),
                proceedLabel: $t({
                  id: 'Space.Members.ChangeRole.Proceed',
                  defaultMessage: 'Change role',
                }),
              })
              if (!confirmed) {
                return
              }
              await updateMemberSpaceRole({
                memberId: member?.id,
                spaceId,
                input: {
                  roleId: otherRole?.id,
                },
              })
            }}
          >
            <T
              defaultMessage="Change role to {role_name, select, admin {space admin} member {space member} other {space {role_name}}}"
              id="Space.Members.ChangeRole"
              values={{
                role_name: getOtherMemberRole(spaceMember)?.name?.toLowerCase(),
              }}
            />
          </Dropdown.Item>
        )}
      </>
    )
  }

  const { nodes: members = [] } = simplifyPaginatedResult<SpaceMember>(data)
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        title={$t({
          defaultMessage: 'Space members',
          id: 'Space.Members',
        })}
      />
      <Modal.Content>
        {isFetched && !members?.length && !isLoading ? (
          <div>
            <T
              defaultMessage="No members in this space."
              id="Space.MemberSettings.Empty"
            />
          </div>
        ) : (
          <Container
            size="full"
            spacing="none"
            containerProps={{
              className: 'max-h-96 overflow-auto',
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchNextPage}
              hasMore={hasNextPage || false}
              useWindow={false}
            >
              {members
                .filter(spaceMember => spaceMember?.member)
                .map(spaceMember => {
                  const { member, role } = spaceMember
                  return (
                    <Link
                      target="_blank"
                      href={member?.relativeUrl}
                      key={spaceMember?.member?.id}
                      className="flex space-s-3 items-center p-2 rounded-base hover:bg-surface-hovered"
                      variant="inherit"
                    >
                      <MemberAvatar size="2x" member={member} />
                      <div className="flex-1 flex space-s-2 min-w-0">
                        <div className="truncate">{member?.name}</div>
                        {role?.type === SpaceRoleType.admin && (
                          <Badge
                            size="sm"
                            className="shrink-0 inline-flex items-center"
                          >
                            <T
                              defaultMessage="Space admin"
                              id="Generics.SpaceAdmin"
                            />
                          </Badge>
                        )}
                      </div>
                      <div
                        className="shrink-0"
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                      >
                        <Dropdown placement="bottom-end">
                          <Dropdown.ButtonMinimal as="div">
                            <IconButton
                              variant="tertiaryNeutral"
                              icon={<SvgIcon name="dots-vertical" />}
                              size="sm"
                              rounded
                              className="bg-transparent"
                            ></IconButton>
                          </Dropdown.ButtonMinimal>
                          <Dropdown.Items>
                            {getMemberCardActions(spaceMember)}
                          </Dropdown.Items>
                        </Dropdown>
                      </div>
                    </Link>
                  )
                })}
            </InfiniteScroll>
          </Container>
        )}
      </Modal.Content>
    </Modal>
  )
}
