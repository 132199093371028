import { ClientError } from '@tribeplatform/gql-client/lib'

export const extractFieldsErrors = (
  error: ClientError,
): Record<string, { message: string; itemIndex: number }> =>
  error?.response?.errors?.reduce((errors, currentError) => {
    const [fieldKey, failingItemIndex] = currentError?.field?.split('.')
    if (fieldKey === 'fields') {
      return {}
    }

    return {
      ...errors,
      [fieldKey]: {
        message: currentError.message,
        ...(failingItemIndex
          ? { itemIndex: parseInt(failingItemIndex, 10) }
          : {}),
      },
    }
  }, {})
