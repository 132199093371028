import {
  Publisher,
  QueryPublisherSettingsArgs,
} from '@tribeplatform/gql-client/types'
import { PublisherFields } from '@tribeplatform/gql-client/types/generated'

import { UseQueryOptions, useQuery } from '../../lib/index.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPublisherSettingsKey } from '../../utils/keys/notification.key.js'

export const usePublisherSettings = (options?: {
  variables: QueryPublisherSettingsArgs
  fields?: PublisherFields
  useQueryOptions?: UseQueryOptions<Publisher>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const publisherKey = getPublisherSettingsKey({ variables, fields })

  return useQuery<Publisher>(
    publisherKey,
    () =>
      client.query({
        name: 'publisherSettings',
        args: { fields: 'all', variables },
      }),
    useQueryOptions,
  )
}
