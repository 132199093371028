import { getKeyLabel } from '@bettermode/common/keyboard'

export const COMMAND_BAR_HOTKEYS = {
  SEARCH: {
    value: 'mod+/',
    label: `${getKeyLabel('cmd')} + /`,
  },
  COMMANDS: {
    value: 'mod+k',
    label: `${getKeyLabel('cmd')} + k`,
  },
}
