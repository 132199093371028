export const HOTKEY_MAPPING = {
  shift: '⇧',
  control: 'Ctrl',
  command: '⌘',
  cmd: '⌘',
  option: '⌥',
  alt: 'Alt',
  esc: 'esc',
  backspace: '⌫',
  enter: '↵',
  arrowUp: '↑',
  arrowDown: '↓',
} as const
