import type { QueryChatsArgs } from '@tribeplatform/gql-client/types'

export const CHATS_QUERY: QueryChatsArgs = {
  limit: 20,
  archived: false,
}

export const ARCHIVED_CHATS_QUERY: QueryChatsArgs = {
  limit: 20,
  archived: true,
}
