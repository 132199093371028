import { Link as RouterLink } from 'react-router-dom'

import { T } from '@tribeplatform/react-components/i18n'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { Link } from '@tribeplatform/react-ui-kit/Link'

export const FederatedSearchCard = () => {
  const guideURL =
    'https://developers.bettermode.com/docs/guide/apps/federated-search'

  return (
    <Card>
      <Card.Header
        title={
          <T
            defaultMessage="Federated Search"
            id="Admin.FederatedSearch.Title"
          />
        }
        description={
          <span>
            <T
              defaultMessage="Enhance community search by integrating both community content and external data sources. <link>Federated search</link> allows members to find relevant information across platforms by connecting a federated search URL in the developer portal whenever needed."
              id="Admin.FederatedSearch.Description"
              values={{
                link: chunks => (
                  <Link href={guideURL} target="_blank">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </span>
        }
      />
      <Card.Content>
        <div className="flex justify-end">
          <Button
            variant="secondaryNeutral"
            as={RouterLink}
            to={guideURL}
            target="_blank"
          >
            <T
              defaultMessage="Find out how"
              id="Admin.FederatedSearch.FindOutHow"
            />
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}
