import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface SettingsSidebarItem {
  icon: JSX.Element
  text: JSX.Element
  link: string
}

interface GetSettingsSidebarItemsParams {
  isMessagingEnabled: boolean
  isConnectedAppsEnabled: boolean
  isAppearanceEnabled: boolean
}

export const getSettingsSidebarItems = ({
  isMessagingEnabled,
  isConnectedAppsEnabled,
  isAppearanceEnabled,
}: GetSettingsSidebarItemsParams): SettingsSidebarItem[] => [
  {
    icon: <SvgIcon className="rtl:-scale-x-100" name="arrow-left" />,
    text: (
      <T defaultMessage="Back to {COMMUNITY}" id="Generics.BackToCommunity" />
    ),
    link: '/',
  },
  {
    icon: <SvgIcon name="key" />,
    text: <T defaultMessage="Account" id="Generics.Account" />,
    link: '/settings/account',
  },
  {
    icon: <SvgIcon name="bell" />,
    text: <T defaultMessage="Notifications" id="Generics.Notifications" />,
    link: '/settings/notifications',
  },
  ...(isMessagingEnabled
    ? [
        {
          icon: <SvgIcon name="message" />,
          text: (
            <div className="flex items-center gap-2">
              <T defaultMessage="Messaging" id="Generics.Messaging" />
              <SvgIcon name="link-external" />
            </div>
          ),
          link: '/messaging/settings',
        },
      ]
    : []),
  ...(isConnectedAppsEnabled
    ? [
        {
          text: (
            <T
              defaultMessage="Connected apps"
              id="Settings.ConnectedApps.ConnectedApps"
            />
          ),
          icon: <SvgIcon name="apps" />,
          link: '/settings/connected-apps',
        },
      ]
    : []),
  ...(isAppearanceEnabled
    ? [
        {
          icon: <SvgIcon name="moon" />,
          text: <T defaultMessage="Appearance" id="Generics.Appearance" />,
          link: '/settings/appearance',
        },
      ]
    : []),
]
